import React, {FC} from "react";
import {Table} from "react-bootstrap";
import {ExistingCustomer} from "core/RDS";
import {ModelObjectRCSV} from "core/Utils/types";

export const Kyogo: FC<{ customer: ModelObjectRCSV<ExistingCustomer> }> = ({customer}) => {
  return (
      <Table striped bordered>
        <thead>
        <tr>
          <th>名称</th>
          <th>単価</th>
        </tr>
        </thead>
        <tbody>
        {customer.kyogoList.length === 0 && (
            <tr>
              <td colSpan={2}>なし</td>
            </tr>
        )}
        {customer.kyogoList?.map(kyogo => (
            <tr>
              <td>{kyogo.name}</td>
              <td>{kyogo.tanka}</td>
            </tr>
        ))}
        </tbody>
      </Table>
  )
}
