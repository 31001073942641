import "core/Utils/browser";

import React, {Context, createContext, FC, useContext, useEffect, useState} from 'react';
import {Amplify} from "aws-amplify";
import {fetchAuthSession, getCurrentUser} from "aws-amplify/auth";
import {AwsExports} from "Backoffice/AwsExports";
import {SignatureV4} from "@aws-sdk/signature-v4";
import {Sha256 as sha256} from '@aws-crypto/sha256-browser';
import {HttpRequest} from "@aws-sdk/protocol-http";
import {parseQueryString} from "@aws-sdk/querystring-parser";
import {CreateTRPCProxyClient, createTRPCProxyClient, httpBatchLink} from "@trpc/client";
import {useLoader} from "core/UI/Components";
import {AuthPage} from "Backoffice/UI/Pages/Auth";
import {Backoffice} from "Backoffice/UI/Pages";
import {AppRouter} from "Backoffice/API/AppRouter";
import {UserType} from "Backoffice/API/AppRouter/User";
import {config} from 'Backoffice/config';
import superjson from "superjson";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';


// Amplify初期化
Amplify.configure(AwsExports);

type TRPCClient = CreateTRPCProxyClient<AppRouter>;

// Cognitoユーザー共有
const UserContext: Context<UserType> = createContext({} as UserType);
export const useUser                 = () => useContext(UserContext);

// TRPCクライアント共有
const TRPCContext: Context<TRPCClient> = createContext<TRPCClient>({} as TRPCClient);
export const useTRPC                   = () => useContext(TRPCContext);


export const App: FC = () => {

  const Loader = useLoader();

  // ステート (trpcを単体で useState で利用しようとするとundefinedになる)
  const [{init, trpc, user}, setState] = useState({
    init: false,
    trpc: {} as TRPCClient,
    user: null as (UserType | null)
  });

  useEffect(() => {
    document.title = 'backoffice';

    Loader.task('初期化中です', async () => {
      try {
        // cognitoユーザー
        const cognitoUser = await getCurrentUser();

        // TPRCクライアント生成
        const trpc = createTRPCProxyClient<AppRouter>({
          links: [
            httpBatchLink({
              url: config.apiUrl,

              // フェッチのオーバーライド
              async fetch(aurl, options) {

                // リクエストの作成
                const url                      = new URL(aurl.toString());
                const httpRequest: HttpRequest = new HttpRequest({
                  headers : {
                    host: url.hostname,
                  },
                  method  : options?.method,
                  hostname: url.hostname,
                  path    : url.pathname,
                  query   : parseQueryString(url.search),
                  body    : options?.body
                });

                // 署名v4
                const signer        = await createSignerV4('ap-northeast-1', 'execute-api');
                const signedRequest = await signer.sign(httpRequest, {
                  unsignableHeaders: new Set(['x-amz-content-sha256'])
                });

                // 開発用ユーザーID
                const xDevUserId = localStorage.getItem('debugUserId') ?? cognitoUser.username;

                // 署名済みリクエストの実行
                return fetch(url.toString(), {
                  headers: {
                    'authorization'       : signedRequest.headers['authorization'],
                    'x-amz-security-token': signedRequest.headers['x-amz-security-token'],
                    'x-amz-date'          : signedRequest.headers['x-amz-date'],
                    ...(config.env === 'development' ? {'x-dev-user-id': xDevUserId} : {})
                  },
                  method : signedRequest.method,
                  body   : signedRequest.body
                });
              },
            })
          ],

          transformer: superjson,
        });

        // ユーザー取得
        const user = await trpc.user.currentUser.query();

        // 初期化完了
        setState({init: true, trpc, user});

      } catch (err) {
        debugger;
        console.error(err);

        setState(prev => ({...prev, init: true}));
      }
    }).then();
  }, []);

  // 初期化前
  if (!init) {
    return <>&nbsp;</>
  }

  if (user) {
    return (
        <UserContext.Provider value={user}>
          <TRPCContext.Provider value={trpc}>
            <Backoffice/>
          </TRPCContext.Provider>
        </UserContext.Provider>
    );
  }
  return <AuthPage/>;
}


const createSignerV4: (region: string, service: string) => Promise<SignatureV4> = async (region, service) => {
  const {credentials} = await fetchAuthSession();

  return new SignatureV4({
    region,
    service,
    credentials: {
      accessKeyId    : credentials!.accessKeyId,
      secretAccessKey: credentials!.secretAccessKey,
      sessionToken   : credentials!.sessionToken
    },
    sha256
  });
}
