import React, {FC} from "react";
import {Info105Type} from "Backoffice/API/AppRouter/Schedule";

export const Info105: FC<{ title: string, list: Info105Type[] }> = ({title, list}) => {
  return (
      <div className={'border border-1 p-1 my-1 small'}>
        <h6>{title}</h6>
        {list.map(info => (
            <span key={info.id}
                  className={'d-block border border-1 border-dark p-1 m-1'}
                  style={info.flag ? {float: 'right', background: 'lightgray'}
                                   : {float: 'left'}}>
              {info.value}
            </span>
        ))}
      </div>
  );
}
