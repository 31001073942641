import React, {FC, useEffect, useState} from "react";
import {FormSelect, FormText, useLoader, useMessage} from "core/UI/Components";
import {FormCol} from "core/UI/Components/FormCol";
import {Helmet} from "react-helmet-async";
import {Breadcrumb, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {TradeType} from "core/Codes";
import {DetailTable} from "Backoffice/UI/Pages/Trade/Form/DetailTable";
import {ModelObjectRCSV} from "core/Utils/types";
import {Trade} from "core/RDS";
import {Navigate, useNavigate} from "react-router";
import {useTRPC} from "Backoffice/UI/App";


export const HanbaiFormPage: FC<{ officeId: number, id: number }> = ({officeId, id}) => {

  const Loader  = useLoader(),
        Message = useMessage(),
        Navi    = useNavigate(),
        tRPC    = useTRPC();

  const [record, setRecord] = useState<ModelObjectRCSV<Trade.Record> | null | false>(null);

  useEffect(() => {

    Loader.task(async () => {
      const record = await tRPC.trade.get.query({shType: 1, officeId, id});
      setRecord(record ?? false);

    }, 500).then();

  }, [officeId, id]);


  if (record === false) {
    return <Navigate to=".." replace/>;
  }

  return (
      <>
        <Helmet>
          <title>販売伝票 {String(officeId)}-{String(id)} | backoffice</title>
        </Helmet>

        <div className={'d-flex flex-wrap small mb-1 mx-1 mx-md-5'}>
          <Breadcrumb>
            <Breadcrumb.Item active>Veronica</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{to: ".."}}>取引一覧</Breadcrumb.Item>
            <Breadcrumb.Item active
                             style={{
                               color     : "#006400",
                               fontWeight: "bolder",
                             }}>
              販売伝票 {officeId}-{id}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Container style={{fontSize: 'small'}}>
          <Row>
            <FormCol inputId={'transaction'}
                     className={'mb-1'}
                     labelColClassname={'text-end'}
                     title={'取引形態'}
                     size={{xs: [3, 3], lg: [1, 2]}}>

              <FormSelect value={record?.transaction ?? ''}
                          className={'me-1 mb-1'}
                          size={'sm'}
                          disabled>
                <option value="">-</option>
                {TradeType.all.map(type => <option value={type.id} key={type.id}>{type.value}</option>)}
              </FormSelect>
            </FormCol>

            <FormCol inputId={'prType'}
                     className={'mb-1'}
                     labelColClassname={'text-end'}
                     title={'支払形態'}
                     size={{xs: [3, 3], lg: [1, 2]}}>

              <FormSelect value={record?.pr_type ?? ''}
                          className={'me-1 mb-1'}
                          size={'sm'}
                          disabled>

                <option value="">-</option>
                <option value="0">現金</option>
                <option value="2">送金</option>
                <option value="1">売掛</option>
              </FormSelect>
            </FormCol>
          </Row>


          <Row>
            <FormCol inputId={'customer'}
                     className={'mb-1'}
                     labelColClassname={'text-end'}
                     title={'得意先'}
                     size={{xs: [3, 9], lg: [1, 5]}}>


              <FormText value={record?.customer_name ?? ''}
                        className={'me-1 mb-1'}
                        size={'sm'}
                        placeholder={'顧客名'}
                        disabled/>
            </FormCol>

            <FormCol inputId={'vehicle'}
                     className={'mb-1'}
                     labelColClassname={'text-end'}
                     title={'車両番号'}
                     size={{xs: [3, 9], lg: [1, 5]}}>


              <FormText value={record?.vehicle ?? ''}
                        className={'me-1 mb-1'}
                        size={'sm'}
                        style={{}}
                        placeholder={'車両番号'}
                        disabled/>
            </FormCol>
          </Row>

          <Row>
            <FormCol inputId={'driver'}
                     className={'mb-1'}
                     labelColClassname={'text-end'}
                     title={'引取担当'}
                     size={{xs: [3, 9], lg: [1, 5]}}>


              <FormText value={record?.driverUser?.name ?? ''}
                        className={'me-1 mb-1'}
                        size={'sm'}
                        disabled/>
            </FormCol>

            <FormCol inputId={'tran_date'}
                     className={'mb-1'}
                     labelColClassname={'text-end'}
                     title={'取引日'}
                     size={{xs: [3, 9], lg: [1, 5]}}>


              <FormText value={record?.tran_date?.toYmd() ?? ''}
                        className={'me-1 mb-1'}
                        type={'date'}
                        size={'sm'}
                        disabled/>
            </FormCol>
          </Row>

          <Row>
            <FormCol inputId={'crt_date'}
                     className={'mb-1'}
                     labelColClassname={'text-end'}
                     title={'登録日'}
                     size={{xs: [3, 9], lg: [1, 5]}}>


              <FormText value={record?.crt_date?.toYmdThms() ?? ''}
                        type={'datetime-local'}
                        className={'me-1 mb-1'}
                        size={'sm'}
                        disabled/>
            </FormCol>

            <FormCol inputId={'upd_date'}
                     className={'mb-1'}
                     labelColClassname={'text-end'}
                     title={'更新日'}
                     size={{xs: [3, 9], lg: [1, 5]}}>


              <FormText value={record?.upd_date?.toYmd() ?? ''}
                        type={'datetime-local'}
                        className={'me-1 mb-1'}
                        size={'sm'}
                        disabled/>
            </FormCol>
          </Row>

          <div className={'flex-fill overflow-scroll mx-0 mt-0 mx-md-5 mt-md-3'}>
            <DetailTable list={record?.details ?? []}/>
          </div>
        </Container>
      </>
  );
}
