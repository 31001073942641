import {ListElementType, SummaryElementType} from "Backoffice/API/AppRouter/NSP";
import {useTRPC} from "Backoffice/UI/App";
import Big from "big.js";
import {useLoader, useMessage} from "core/UI/Components";
import React, {FC, useEffect, useState} from "react";
import {Breadcrumb, Tab, Table, Tabs} from "react-bootstrap";
import {Helmet} from "react-helmet-async";
import {useNavigate} from "react-router";


export const NSPPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();


  const [summary, setSummary] = useState([] as SummaryElementType[]),
        [list, setList]       = useState([] as ListElementType[]);

  // 初期化
  useEffect(() => {
    (async () => {
      const [summary, list] = await Promise.all([trpc.nsp.summary.query(),
                                                 trpc.nsp.list.query()]);

      setSummary(summary);
      setList(list);
    })();
  }, []);


  return (
      <>
        <Helmet>
          <title>NSP | backoffice</title>
        </Helmet>

        <div className={'container small'}>
          <Breadcrumb>
            <Breadcrumb.Item active>Veronica</Breadcrumb.Item>
            <Breadcrumb.Item active>NSP</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className={'container'}>
          <Tabs defaultActiveKey="summary" className="mb-3">
            <Tab eventKey="summary" title="サマリ">

              <div className={'m-md-5'}>
                <Table striped style={{fontFamily: 'monospace', fontSize: 14}}>
                  <thead>
                  <tr>
                    <th className={'text-center'}>年月</th>
                    <th className={'text-end'}>月次平均</th>
                    <th className={'text-end'}>前半平均</th>
                    <th className={'text-end'}>後半平均</th>
                  </tr>
                  </thead>

                  <tbody>
                  {summary.map(el => {
                    const ym    = `${String(el.ym.getFullYear()).substring(2)}-${el.ym.zMonth()}`,
                          price = el.price ? Big(el.price).toFixed(5, Big.roundHalfUp).withComma : '-',
                          fHalf = el.firsthalf ? Big(el.firsthalf).toFixed(5, Big.roundHalfUp).withComma : '-',
                          lHalf = el.lasthalf ? Big(el.lasthalf).toFixed(5, Big.roundHalfUp).withComma : '-';

                    return (
                        <tr key={ym}>
                          <td className={'text-start'}>{ym}</td>
                          <td className={'text-end'}>{price}</td>
                          <td className={'text-end'}>{fHalf}</td>
                          <td className={'text-end'}>{lHalf}</td>
                        </tr>
                    )
                  })}
                  </tbody>
                </Table>
              </div>
            </Tab>

            <Tab eventKey="list" title="入力値">
              <div className={'m-md-5'}>
                <Table striped style={{fontFamily: "monospace", fontSize: 14 }}>
                  <thead>
                  <tr>
                    <th className={'text-center'}>年月日</th>
                    <th className={'text-end'}>入力値</th>
                  </tr>
                  </thead>

                  <tbody>
                  {list.map(el => {
                    return (
                        <tr key={el.trandate.toYmd()}>
                          <td className={'text-center'}>{el.trandate.toYmd()}</td>
                          <td className={'text-end'}>{el.price?.withComma ?? '-'}</td>
                        </tr>
                    )
                  })}
                  </tbody>
                </Table>
              </div>
            </Tab>
          </Tabs>
        </div>
      </>
  );
}


