import React, {FC} from "react";
import {BudgetType} from "core/RDS";
import {Table} from "react-bootstrap";

export const Budgets: FC<{ budgets: BudgetType[] }> = ({budgets}) => {
  return (
      <>
        {budgets.length === 0 && (
            <span>なし</span>
        )}
        {budgets.map(budget => <BugetTable budget={budget} key={`${budget.id}`}/>)}
      </>
  )
}

const BugetTable: FC<{ budget: BudgetType }> = ({budget}) => {
  console.log(budget);
  return (
      <div className={'mb-4'}>
        <h6>ID:{budget.id}&nbsp; {budget.trandate.toYmd()}</h6>
        <Table striped bordered>
          <thead>
          <tr>
            <th>商品名</th>
            <th className={'text-end'}>単価</th>
            <th className={'text-end'}>粗利</th>
            <th className={'text-end'}>見積額</th>
          </tr>
          </thead>
          <tbody>
          {budget.details.map(detail => (
              <tr key={`${detail.no}`}>
                <td>{detail.item.name}</td>
                <td className={'text-end'}>{detail.basic_price?.toFixed(2).withComma ?? '-'}</td>
                <td className={'text-end'}>{detail.gross ?? '-'}</td>
                <td className={'text-end'}>{detail.unit_price?.toFixed(2).withComma ?? '-'}</td>
              </tr>
          ))}
          </tbody>
        </Table>
      </div>
  )
}
