import {Detail} from "core/RDS/Models/Trade";
import {ModelObjectRCSV} from "core/Utils/types";
import {FC} from "react";
import {Table} from "react-bootstrap";

export const DetailTable: FC<{ list: ModelObjectRCSV<Detail>[] }> = ({list}) => {
  return (
      <Table hover className={'thead-sticky ellipsis'}>
        <thead>
        <tr>
          <th className={'text-center'} style={{minWidth: '2em'}}>No</th>
          <th className={'text-start '} style={{minWidth: '8em'}}>品目</th>
          <th className={'text-end   '} style={{minWidth: '5em'}}>荷下前</th>
          <th className={'text-end   '} style={{minWidth: '5em'}}>荷下後</th>
          <th className={'text-end   '} style={{minWidth: '5em'}}>容器</th>
          <th className={'text-end   '} style={{minWidth: '5em'}}>重量</th>
          <th className={'text-end   '} style={{minWidth: '5em'}}>単価</th>
          <th className={'text-end   '} style={{minWidth: '5em'}}>価格</th>
          <th className={'text-end   '} style={{minWidth: '5em'}}>成績</th>
          <th className={'text-start '} style={{minWidth: '16em'}}>摘要</th>
          <th className={'text-start '} style={{minWidth: '16em'}}>社内メモ</th>
        </tr>
        </thead>
        <tbody>
        {list.length === 0 && <tr>
          <td colSpan={12}>詳細がありません</td>
        </tr>}
        {list.map(detail => (
            <tr key={`${detail.id}-${detail.branch}`}>
              <td className={'text-center'}>{detail.branch + 1}</td>
              <td className={'text-start '}>{detail.item?.name || '-'}</td>
              <td className={'text-end   '}>{detail.weight0?.toString().withComma ?? '-'}</td>
              <td className={'text-end   '}>{detail.weight1?.toString().withComma ?? '-'}</td>
              <td className={'text-end   '}>{detail.weight2?.sum.toFixed(2) ?? '-'}</td>
              <td className={'text-end   '}>{detail.quantity?.toString().withComma ?? '-'}</td>
              <td className={'text-end   '}>{detail.price?.toString().withComma ?? '-'}</td>
              <td className={'text-end   '}>{detail.basic_price?.toString().withComma ?? '-'}</td>
              <td className={'text-end   '}>{detail.price?.toString().withComma ?? '-'}</td>
              <td className={'text-start '}>{detail.memo}</td>
              <td className={'text-start '}>{detail.outline}</td>
            </tr>
        ))}
        </tbody>
      </Table>
  );
}
