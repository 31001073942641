import React, {ChangeEventHandler, useCallback, useState} from 'react';
import {useNavigate} from "react-router";
import {Button, Col, Form, Row} from "react-bootstrap";
import {confirmResetPassword, resetPassword} from "aws-amplify/auth";
import {FormText, useLoader, useMessage} from "core/UI/Components";
import {Helmet} from "react-helmet-async";

const defaultModel = {
  username        : '',
  confirmationCode: '',
  newPassword     : ''
}

export const ResetPasswordPage = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        navigate = useNavigate();

  const [step, setStep]   = useState("init"),
        [model, setModel] = useState(defaultModel);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(({currentTarget: {name, value}}) => {
    setModel(prev => ({...prev, [name]: value}));
  }, []);

  const handleResetRequest = () => {
    Loader.task(() => resetPassword({username: model.username}), 300)
          .then(({nextStep}) => setStep(nextStep.resetPasswordStep))
          .catch(err => Message.error(err));
  }

  const handleResetNextStep = () => {
    Loader.task(() => confirmResetPassword(model), 300)
          .then(() => Message.show('パスワードの更新が完了しました', () => location.href = '/'))
          .catch(err => Message.error(err));
  }

  return (
      <>
        <Helmet>
          <title>パスワードリセット</title>
        </Helmet>

        {step === 'init' && (
            <Form style={{marginTop: 150}}>
              <Row style={{marginBottom: 50}}>
                <Col sm={{span: 4, offset: 4}} style={{padding: 5}}>
                  <Form.Group controlId="userName">
                    <FormText name="username"
                              placeholder="ユーザー名"
                              value={model.username}
                              onChange={handleChange}/>
                  </Form.Group>
                </Col>
              </Row>

              <Row style={{marginTop: 10}}>
                <Col sm={{span: 4, offset: 4}} style={{padding: 5}}>
                  <Button variant="dark"
                          className={'w-100'}
                          onClick={handleResetRequest}>
                    リセットをリクエストする
                  </Button>
                </Col>
              </Row>
            </Form>
        )}

        {step === 'CONFIRM_RESET_PASSWORD_WITH_CODE' && (
            <Form style={{marginTop: 150}}>
              <Row style={{marginBottom: 50}}>
                <Col sm={{span: 4, offset: 4}} style={{padding: 5}}>
                  <Form.Group>
                    <FormText value={model.username} readOnly/>
                  </Form.Group>
                </Col>
              </Row>

              <Row style={{marginBottom: 50}}>
                <Col sm={{span: 4, offset: 4}} style={{padding: 5}}>
                  <Form.Group>
                    <FormText name="confirmationCode"
                              placeholder="リセットコード"
                              value={model.confirmationCode}
                              onChange={handleChange}/>
                  </Form.Group>
                </Col>
              </Row>

              <Row style={{marginBottom: 50}}>
                <Col sm={{span: 4, offset: 4}} style={{padding: 5}}>
                  <Form.Group>
                    <FormText name="newPassword"
                              placeholder="新パスワード"
                              value={model.newPassword}
                              onChange={handleChange}/>

                  </Form.Group>
                </Col>
              </Row>

              <Row style={{marginTop: 10}}>
                <Col sm={{span: 4, offset: 4}} style={{padding: 5}}>
                  <Button variant="dark"
                          className={'w-100'}
                          onClick={handleResetNextStep}>
                    パスワードを更新する
                  </Button>
                </Col>
              </Row>
            </Form>
        )}
      </>
  );
};
