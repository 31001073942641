import {faSquareCaretLeft, faSquareCaretRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTRPC} from "Backoffice/UI/App";
import {Member} from "Backoffice/UI/Pages/Schedule/Member";
import {DailyMemberType} from "core/RDS/Models/Schedule";
import {FormText, useLoader} from "core/UI/Components";
import React, {FC, useEffect, useState} from "react";
import {Breadcrumb, Container} from "react-bootstrap";
import {Helmet} from "react-helmet-async";
import {useNavigate, useParams} from "react-router";


export const UserPage: FC = () => {

  const Loader   = useLoader(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  const {userId, ymd} = useParams(),
        date          = new Date(ymd || '');


  useEffect(() => {
    Loader.task(async () => {
      const data = await trpc.schedule.user.query({userId: userId ?? '', date});
      setData(data);

      const first = Object.values(data).find(el => el);
      if (first) {
        setTitle(`${first?.user.name} ${first.user.mobile} | ${first.user.mobile2}`);
      }
    });
  }, [ymd]);


  // ステート
  const [data, setData]   = useState<null | Record<string, DailyMemberType | null>>(null),
        [title, setTitle] = useState<null | string>(null);

  if (data === null) {
    return <div className={'container'}>読込中</div>;
  }


  return (
      <>
        <Helmet>
          <title>スケジュール | backoffice</title>
        </Helmet>

        <div className={'container small'}>
          <Breadcrumb>
            <Breadcrumb.Item active>Veronica</Breadcrumb.Item>
            <Breadcrumb.Item href={'/schedule'}>スケジュール</Breadcrumb.Item>
            <Breadcrumb.Item active>{title}</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Container className={'my-3 small'}>
          <div className={'d-flex align-items-center me-3 mb-1'}>
            <div onClick={() => Navigate(`../user/${userId}/${date.addDate(-7).toYmd()}`)} className={'cursor-pointer'}>
              <FontAwesomeIcon icon={faSquareCaretLeft} className={'text-secondary'}/>
            </div>

            <FormText type={"date"}
                      value={date.toYmd()}
                      onChange={({target: {value}}) => Navigate(`../${value}`)}
                      className={'mx-1'}
                      size={'sm'}
                      style={{width: "8rem"}}/>

            <div onClick={() => Navigate(`../user/${userId}/${date.addDate(+7).toYmd()}`)} className={'cursor-pointer'}>
              <FontAwesomeIcon icon={faSquareCaretRight} className={'text-secondary'}/>
            </div>
          </div>
        </Container>

        <Container className={'d-flex flex-wrap mt-2 px-0'}>
          {Object.entries(data).map(([ymd, daily]) => {
            if (daily === null) {
              return null;
            }
            return (
                <Member key={`${ymd}`}
                        daily={daily}
                        header={
                          <span className={'me-2'}>{ymd}</span>
                        }/>
            )
          })}
        </Container>
      </>
  );
}


