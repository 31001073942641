import React, {FC} from "react";
import {ExistingCustomer} from "core/RDS";
import {ModelObjectRCSV} from "core/Utils/types";


export const Memos: FC<{ customer: ModelObjectRCSV<ExistingCustomer> }> = ({customer}) => (
    <ul className={'d-block list-unstyled'}>
      <li><Memo title={'メモ1'} memo={customer.memo1}/></li>
      <li><Memo title={'メモ2'} memo={customer.memo2}/></li>
      <li><Memo title={'メモ3'} memo={customer.memo3}/></li>
      <li><Memo title={'メモ4'} memo={customer.memo4}/></li>
      <li><Memo title={'メモ5'} memo={customer.memo5}/></li>
      <li><Memo title={'メモ6'} memo={customer.memo6}/></li>
      <li><Memo title={'メモ7'} memo={customer.memo7}/></li>
      <li><Memo title={'メモ8'} memo={customer.memo8}/></li>
      <li><Memo title={'メモ9'} memo={customer.memo9}/></li>
      <li><Memo title={'メモ10'} memo={customer.memo10}/></li>
    </ul>
)


const Memo: FC<{ title: string, memo?: string }> = ({title, memo}) => {
  return (
      <ul className={'d-block list-unstyled border border-1 m-3 p-3'}>
        <li><h6>{title}</h6></li>
        <li>{memo?.trim() || '--'}</li>
      </ul>
  )
}
