import React, {FC,} from "react";
import {Navigate, Routes} from "react-router";
import {Route} from "react-router-dom";
import {MaintenanceCheckRoot} from "./Check/index";
import {MaintenanceSelectPage} from "Backoffice/UI/Pages/Maintenance/select";
import {MaintenanceItemRoot} from "Backoffice/UI/Pages/Maintenance/item";
import {MaintenanceFormPage} from "Backoffice/UI/Pages/Maintenance/form";
import {MaintenanceVideoPage} from "Backoffice/UI/Pages/Maintenance/video";
import {VehicleType} from "Backoffice/UI/Pages/Maintenance/Types/vehicle";


export interface MaintenanceTypeProps {
  id: number,
  name: string,
  points: Record<string, string[]>
}

export const MaintenanceRoot: FC = () => {
  return (
      <Routes>
        <Route path="check/*" element={<MaintenanceCheckRoot/>}/>
        <Route path="vehicle/*">
          <Route index element={<MaintenanceSelectPage type={VehicleType} suffix={'入力'}/>}/>
          <Route path=":target_id/*" element={<MaintenanceItemRoot type={VehicleType}/>}>
            <Route index element={<MaintenanceFormPage/>}/>
            <Route path={'video'} element={<MaintenanceVideoPage/>}/>
          </Route>
          <Route path="*" element={<Navigate to=".." replace/>}/>
        </Route>

        <Route path="*" element={<Navigate to=".." replace/>}/>
      </Routes>
  );
}
