import {useLoader, useMessage} from "core/UI/Components";
import {useNavigate, useOutletContext, useParams} from "react-router";
import {useTRPC} from "Backoffice/UI/App";
import React, {FC} from "react";
import {Helmet} from "react-helmet-async";
import {Breadcrumb} from "react-bootstrap";
import {MaintenanceItemOutlet} from "Backoffice/UI/Pages/Maintenance/item";

export const MaintenanceVideoPage: FC = () => {

  const {type, target} = useOutletContext<MaintenanceItemOutlet>();

  const Loader    = useLoader(),
        Message   = useMessage(),
        Navi      = useNavigate(),
        tRPC      = useTRPC(),
        target_id = Number(useParams().target_id);


  return (
      <>
        <Helmet>
          <title>動画 #{String(target_id)} | backoffice</title>
        </Helmet>

        <div className={'container small'}>
          <Breadcrumb>
            <Breadcrumb.Item active>Veronica</Breadcrumb.Item>
            <Breadcrumb.Item href={'#'} onClick={() => Navi('../..')}>{type.name}</Breadcrumb.Item>
            <Breadcrumb.Item href={'#'} onClick={() => Navi(`../`)}>{target.name}</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className={'container'}>
          video test
        </div>
      </>
  )
}
