import React, {FC,} from "react";
import {Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";
import {PotentialCustomerListPage} from "./List";
import {PotentialCustomerDetailPage} from "./Detail";


export const PotentialCustomerPage: FC = () => {
  return (
      <Routes>
        <Route index element={<PotentialCustomerListPage/>}/>
        <Route path=":id" element={<PotentialCustomerDetailPage/>}/>
        <Route path="*" element={<Navigate to=".." replace/>}/>
      </Routes>
  );
}

