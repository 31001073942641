import React, {ChangeEventHandler, FC, MouseEventHandler, useCallback, useState} from "react";
import {Button, Row, Col, Modal} from "react-bootstrap";
import {FormTextArea, FormText} from "core/UI/Components";
import {v4 as uuid} from 'uuid';
import {UIRecruitType} from "./Create";


// コンポーネント
export const RecruitContents: FC<{ content: UIRecruitType[], setContent: (callback: (content: UIRecruitType[]) => UIRecruitType[]) => void }> = ({
  content,
  setContent
}) => {

  // 値変更
  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(({currentTarget: {name, value, dataset: {index}}}) => {
    setContent(content => {
      content[Number(index)][name as keyof (UIRecruitType)] = value;
      return content;
    })
  }, [setContent]);

  // 要素追加
  const handleAddContent: MouseEventHandler<HTMLButtonElement> = () => {
    setContent(content => {
      content.push({__uiKey: uuid(), key: '', value: ''});
      return content;
    })
  };

  // 項目選択
  const [selecting, setSelecting] = useState<string[]>([]);

  // 項目選択変更
  const handleChangeSelecting: ChangeEventHandler<HTMLInputElement> = useCallback(({currentTarget: {dataset: {key}}}) => {
    key && setSelecting(prev => prev.toggle(key));
  }, []);


  // 削除
  const [deleting, setDeleting] = useState(false);

  // 削除 (確認ダイアログ表示)
  const handleDeleteConfirm = () => setDeleting(true);

  // 削除（ダイアログ閉じる）
  const handleCancelDelete = () => setDeleting(false);

  // 削除
  const handleDeleteContent: MouseEventHandler<HTMLButtonElement> = () => {
    setContent(content => content.filter(elem => !selecting.includes(elem.__uiKey)));
    setSelecting([]);
    handleCancelDelete();
  }

  return (
      <>
        <div>
          {content.map((elem, index) => {
            const _index = String(index);
            return (
                <Row key={elem.__uiKey}>
                  <Col className={`d-flex flex-row`}>
                    <div className={'p-2 flex-shrink-1'}>
                      <input type="checkbox"
                             data-key={elem.__uiKey}
                             onChange={handleChangeSelecting}
                             checked={selecting.includes(elem.__uiKey)}
                             tabIndex={-1}/>
                    </div>

                    <div className={`flex-grow-1 container`}>
                      <Row>
                        <Col xs={{span: 8, offset: 0}} style={{padding: 5}}>
                          <FormText name={`key`}
                                    data-index={_index}
                                    placeholder="タイトル"
                                    defaultValue={elem.key}
                                    onChange={handleChange}
                                    required/>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={{span: 12, offset: 0}} style={{padding: 5}}>
                          <FormTextArea name={`value`}
                                        data-index={_index}
                                        placeholder="内容"
                                        defaultValue={elem.value}
                                        onChange={handleChange}
                                        required/>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
            )
          })}
        </div>

        <Row>
          <Col xs={{span: 5, offset: 1}} style={{padding: 5}}>
            <Button onClick={handleAddContent}
                    variant={'outline-secondary'}
                    size={'sm'}
                    className={'w-100'}>
              項目追加
            </Button>
          </Col>
          <Col xs={{span: 5, offset: 0}} style={{padding: 5}}>
            <Button onClick={handleDeleteConfirm}
                    variant={'outline-danger'}
                    size={'sm'}
                    className={'w-100'}
                    disabled={selecting.length === 0}>
              項目削除
            </Button>
          </Col>
        </Row>


        <Modal show={deleting}
               backdropClassName={'system-modal'}>

          <Modal.Body className={`m-body`}>
            {selecting.length}件の項目を削除しますか？
          </Modal.Body>

          <Modal.Footer className={'justify-content-center'}>
            <Button variant="secondary" style={{minWidth: '10em'}}
                    onClick={handleCancelDelete}>
              キャンセル
            </Button>

            <Button variant="danger" style={{minWidth: '10em'}}
                    onClick={handleDeleteContent}>
              削除する
            </Button>
          </Modal.Footer>
        </Modal>
      </>
  );
}

