import {faSquareCaretLeft, faSquareCaretRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTRPC} from "Backoffice/UI/App";
import {Trade} from "core/RDS";
import {ItemTypeWithLevel} from "core/RDS/Models/Trade";
import {FormSelect, FormText, TextDiv, useLoader, useMessage} from "core/UI/Components";
import React, {FC, useEffect, useState} from "react";
import {Breadcrumb, Button, Table} from "react-bootstrap";
import {Helmet} from "react-helmet-async";
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import {useImmer} from "use-immer";


const bgColors = {
  1: '#87CEEB',
  2: '#E9967A',
  3: '#DEB887',
  4: '#E0FFFF',
  5: '#BC8F8F',
  9: '#BDB76B',
}

const defaultCondition = {
  date : new Date().toYmd(),
  group: '',
}

export const PriceListPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  const [query, setQuery]            = useSearchParams(),
        [condition, setCondition]    = useImmer(defaultCondition),
        [code, setCode]              = useState({group: [] as Trade.ItemGroupType[], items: [] as ItemTypeWithLevel[]}),
        [{columns, rows}, setResult] = useState({
          columns: [] as { id: number, name: string }[],
          rows   : {} as Record<string, Record<string, number>>
        });


  // 初期化 1/2
  useEffect(() => {
    (async () => {
      const code = await trpc.priceList.code.query();
      setCode(code);
    })();
  }, []);

  // 初期化 2/2 (検索)
  useEffect(() => {
    const cond = {
      date : query.get('date') || defaultCondition.date,
      group: query.get('group') || defaultCondition.group,
    };

    setCondition(cond);

    // データ取得
    Loader.task(async () => {
      try {
        // @ts-ignore チェックはサーバーサイドで実行
        const res = await trpc.priceList.data.query(cond);
        setResult(res);

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, [query]);


  // 検索
  const handleSearch = () => {
    for (const [k, v] of Object.entries(condition)) {
      if (v.trim()) {
        query.set(k, v);
      } else {
        query.delete(k);
      }
    }

    query.set('ts', new Date().getTime().toString());
    handleDate(0);
  };

  // 日付遷移
  const handleDate = (date: number) => {
    query.set('date', new Date(condition.date).addDate(date).toYmd());
    query.set('ts', new Date().getTime().toString());
    setQuery(query);
  }

  const ts = query.get('ts');
  return (
      <>
        <Helmet>
          <title>価格表 | backoffice</title>
        </Helmet>

        <div className={'d-flex flex-column root-100vh pb-1'}>
          <div className={'d-flex flex-wrap small mb-1 mx-1 mx-md-5'}>
            <Breadcrumb>
              <Breadcrumb.Item active>Veronica</Breadcrumb.Item>
              <Breadcrumb.Item active>価格表</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className={'d-flex small mb-1 mx-1 mx-md-5'}>
            <div className={'d-flex flex-fill flex-wrap'}>
              <div className={'d-flex align-items-center me-3 mb-1'}>
                <div onClick={() => handleDate(-1)} className={'cursor-pointer'}>
                  <FontAwesomeIcon icon={faSquareCaretLeft} className={'text-secondary'}/>
                </div>

                <FormText type={"date"}
                          value={condition.date}
                          onChange={({target: {value}}) => setCondition(draft => void (draft.date = value))}
                          className={'mx-1'}
                          size={'sm'}
                          style={{width: "8rem"}}/>

                <div onClick={() => handleDate(+1)} className={'cursor-pointer'}>
                  <FontAwesomeIcon icon={faSquareCaretRight} className={'text-secondary'}/>
                </div>
              </div>

              <FormSelect value={condition.group}
                          onChange={({target: {value}}) => setCondition(draft => void (draft.group = value))}
                          className={'me-1 mb-1'}
                          size={'sm'}
                          style={{width: "8rem"}}>
                <option value="">品目</option>
                {code.group.map(el => <option key={el.id} value={el.id}>{el.name}</option>)}
              </FormSelect>
            </div>

            <Button onClick={handleSearch}
                    className={'me-1 mb-1'}
                    size={'sm'}
                    style={{width: 80}}>
              検索
            </Button>
          </div>

          <div className={'flex-fill overflow-scroll mx-0 mt-0 mx-md-5 mt-md-3'}>
            <Table hover className={'th-sticky'} style={{fontSize: 12}}>
              <thead>
              <tr>
                <th className={'bg-light'}>品目</th>
                {columns.map(col => <th key={col.id} className={'bg-light text-end'}><TextDiv width={"4rem"}>{col.name}</TextDiv></th>)}
              </tr>
              </thead>

              <tbody style={{borderTop: '1.05px solid gray'}}>
              {code.items.map(item => {

                // グループフィルタ
                if (condition.group) {
                  if (item.id0 !== Number(condition.group)) {
                    return null;
                  }
                }

                const row = rows[item.id];
                if (!row) {
                  return null;
                }

                return (
                    <tr id={`${ts}-${item.id}`}>
                      <th style={{backgroundColor: bgColors[item.id0 as keyof typeof bgColors] ?? ''}}>
                        <div className={'d-block d-xl-none'}>
                          <TextDiv width={"6rem"}>{item.name_intra}</TextDiv>
                        </div>
                        <div className={'d-none d-xl-block'}>
                          {item.name_intra}
                        </div>
                      </th>
                      {columns.map(col => <td key={col.id} className={'text-end'}>{row[String(col.id)] || ''}</td>)}
                    </tr>
                );
              })}
              </tbody>
            </Table>
          </div>
        </div>
      </>
  );
}


