import React, {FC, useEffect, useState} from "react";
import {Breadcrumb, Container, Row, Tab, Tabs} from "react-bootstrap";
import {useNavigate, useParams} from "react-router";
import {useTRPC} from "Backoffice/UI/App";
import {useLoader, useMessage} from "core/UI/Components";
import {Link} from "react-router-dom";
import {GetResponse} from "Backoffice/API/AppRouter/Customer/Existing";
import {Addresses} from "./Addresses";
import {Trades} from "./Trades";
import {SalesActivities} from "./SalesActivities";
import {Vehicles} from "./Vehicles";
import {Kyogo} from "./Kyogo";
import {Budgets} from "./Budgets";
import {Expects} from "Backoffice/UI/Pages/Customer/Existing/Detail/Expects";
import {Memos} from "Backoffice/UI/Pages/Customer/Existing/Detail/Memos";
import {ApproxPrices} from "Backoffice/UI/Pages/Customer/Existing/Detail/ApproxPrices";
import {Helmet} from "react-helmet-async";

export const ExistingCustomerDetailPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  const {id} = useParams();

  const [model, setModel] = useState<null | GetResponse>(null);


  useEffect(() => {
    Loader.task(async () => {
      try {
        const model = await trpc.customer.existing.getInfo.query(Number(id));
        setModel(model);

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, []);

  return (
      <>
        <Helmet>
          <title>ID: {id}|既存客</title>
        </Helmet>

        <div className={'container small'}>
          <Breadcrumb>
            <Breadcrumb.Item active>Veronica</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{to: "/customer/existing/"}}>既存客リスト</Breadcrumb.Item>
            <Breadcrumb.Item active>{model?.customer?.name ?? '読込中...'}</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Container className={'my-3 small'}>
          {model === null ? <span>読込中...</span> :
           <>
             <Row>
               <ul className={'d-block list-unstyled  py-3 '}>
                 <li className={'small'}>ID:{model.customer.id}</li>
                 <li className={'my-3'}><h5>{model.customer.name}</h5></li>
                 <li>
                   {model.customer.zip}
                   {model.customer.address1}
                 </li>
                 <li>担当:{model.customer.charge_dept} {model.customer.charge_name}</li>
                 <li>携帯:<a href={`tel:${model.customer.mobile}`}>{model.customer.mobile}</a>
                 </li>
                 <li>自社担当:{model.customer.chargeUser?.name}</li>
               </ul>
             </Row>

             <Row>
               <Tabs defaultActiveKey="addresses" className="mb-3">
                 <Tab eventKey="addresses" title="住所">
                   <Addresses customer={model.customer}/>
                 </Tab>

                 <Tab eventKey="trans" title="取引">
                   <Trades trades={model.trades}/>
                 </Tab>

                 <Tab eventKey="activities" title="営業">
                   <SalesActivities salesActivities={model.salesActivities}/>
                 </Tab>

                 <Tab eventKey="estimates" title="見積">
                   <Budgets budgets={model.budgets}/>
                 </Tab>

                 <Tab eventKey="vehicles" title="車両">
                   <Vehicles customer={model.customer}/>
                 </Tab>

                 <Tab eventKey="kyogo" title="競合">
                   <Kyogo customer={model.customer}/>
                 </Tab>

                 <Tab eventKey="items" title="商品">
                   <Expects expects={model.expects}/>
                 </Tab>

                 <Tab eventKey="memo" title="メモ">
                   <Memos customer={model.customer}/>
                 </Tab>

                 <Tab eventKey="kakaku" title="価格目安">
                   <ApproxPrices customer={model.customer}/>
                 </Tab>
               </Tabs>
             </Row>
           </>
          }
        </Container>
      </>
  );
}


