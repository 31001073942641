import {Trade} from "core/RDS";
import React, {FC} from "react";
import {Table} from "react-bootstrap";

export const Trades: FC<{ trades: Trade.RecordType[] }> = ({trades}) => {
  return (
      <>
        {trades.length === 0 && (
            <span>なし</span>
        )}
        {trades.map(trade =>
            <TradeTable trade={trade} key={`${trade.sh_type}-${trade.office_id}-${trade.id}`}/>)}
      </>
  )
}

const TradeTable: FC<{ trade: Trade.RecordType }> = ({trade}) => {
  return (
      <div className={'mb-4'}>
        <h6>{trade?.shType?.value} {trade?.type?.value}:{trade?.tran_date?.toYmd()}</h6>
        <Table striped bordered>
          <thead>
          <tr>
            <th>商品名</th>
            <th className={'text-end'}>重量</th>
            <th className={'text-end'}>単価</th>
            <th className={'text-end'}>成績</th>
          </tr>
          </thead>
          <tbody>
          {trade.details.map(detail => (
              <tr key={`${detail.sh_type}-${detail.office_id}-${detail.id}-${detail.branch}`}>
                <td>{detail.item.name}</td>
                <td className={'text-end'}>{detail.quantity?.toFixed(2).withComma}</td>
                <td className={'text-end'}>{detail.price?.toFixed(2).withComma}</td>
                <td className={'text-end'}>{detail.basic_price?.toFixed(0).withComma}</td>
              </tr>
          ))}
          </tbody>
        </Table>
      </div>
  )
}
