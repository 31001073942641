import React, {FC,} from "react";
import {Route} from "react-router-dom";
import {Routes, Navigate} from "react-router";
import {RecruitListPage} from "./List";
import {RecruitCreatePage} from "./Create";
import {RecruitEditPage} from "./Edit";

export const RecruitsPage: FC = () => {
  return (
      <Routes>
        <Route index element={<RecruitListPage/>}/>
        <Route path="create" element={<RecruitCreatePage/>}/>
        <Route path=":id/edit" element={<RecruitEditPage/>}/>
        <Route path="*" element={<Navigate to=".." replace/>}/>
      </Routes>
  );
}

