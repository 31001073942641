import React, {FormEventHandler, useCallback, useEffect} from 'react';
import {useNavigate, useParams} from "react-router";
import {Button, Col, Form, Row} from "react-bootstrap";
import {confirmSignIn} from 'aws-amplify/auth'
import {FormPassword, FormText, useLoader, useMessage} from "core/UI/Components";


export const ChangePasswordRequiredPage = () => {

  const Loader       = useLoader(),
        MessageModal = useMessage(),
        navigate     = useNavigate(),
        {email}      = useParams();

  useEffect(() => {
    document.title = 'パスワード変更｜backoffice';

    if (!email) {
      MessageModal.show('エラー', 'メールアドレスが確認出来ません。',
          () => navigate('/'));
    }
  }, []);


  // フォームサブミット
  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(async (event) => {

    // デフォルトの挙動を止める
    event.preventDefault();
    event.stopPropagation();

    const form: HTMLFormElement = event.currentTarget;

    // 入力値チェック
    if (!form.checkValidity()) {
      return MessageModal.show('エラー', '入力内容をご確認ください');
    }

    const new_password = form.new_password.value;

    Loader.task('通信中です', () => confirmSignIn({challengeResponse: new_password}), 300)
          .then(({isSignedIn}) => {
            if (isSignedIn) {
              MessageModal.show('完了通知', 'パスワードを変更しました', () => window.location.href = '/');
            } else {
              MessageModal.show('エラー');
            }
          });
  }, []);

  return (
      <Form onSubmit={handleSubmit} style={{marginTop: 150}}>
        <Row style={{marginBottom: 50}}>
          <Col className="text-center">
            <big>パスワードの変更</big>
          </Col>
        </Row>

        <Row>
          <Col sm={{span: 4, offset: 4}}>
            ログインを行うためにはパスワードを変更する必要があります。
          </Col>
        </Row>
        <Row>
          <Col sm={{span: 4, offset: 4}} style={{marginBottom: 50}}>
            パスワードは8〜255文字で、大文字・小文字・数字・記号をそれぞれ1文字以上含める必要があります。
          </Col>
        </Row>

        <Row>
          <Col sm={{span: 4, offset: 4}} style={{padding: 5}}>
            <Form.Group controlId="email">
              <FormText name="email" type="email" defaultValue={email} placeholder="メールアドレス" required/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={{span: 4, offset: 4}} style={{padding: 5}}>
            <Form.Group controlId="new_passwd">
              <FormPassword name="new_password" placeholder="新しいパスワード" required minLength={8} maxLength={255} autoComplete="new-password"/>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{marginTop: 10}}>
          <Col sm={{span: 4, offset: 4}} style={{padding: 5}}>
            <Button variant="dark" type="submit" className={'w-100'}>パスワード変更</Button>
          </Col>
        </Row>
      </Form>
  );
};

