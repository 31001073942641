import React, {FC,} from "react";
import {Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";
import {ExistingCustomerPage} from "./Existing";
import {PotentialCustomerPage} from "Backoffice/UI/Pages/Customer/Potential";

export const CustomerPage: FC = () => {
  return (
      <Routes>
        <Route path="existing/*" element={<ExistingCustomerPage/>}/>
        <Route path="potential/*" element={<PotentialCustomerPage/>}/>
        <Route path="*" element={<Navigate to="./existing" replace/>}/>
      </Routes>
  );
}


