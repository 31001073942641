import React, {FC,} from "react";
import {Route} from "react-router-dom";
import {Routes, Navigate} from "react-router";
import {NewsListPage} from "./List";
import {NewsCreatePage} from "./Create";
import {NewsEditPage} from "./Edit";

export const NewsPage: FC = () => {
  return (
      <Routes>
        <Route index element={<NewsListPage/>}/>
        <Route path="create" element={<NewsCreatePage/>}/>
        <Route path=":id/edit" element={<NewsEditPage/>}/>
        <Route path="*" element={<Navigate to=".." replace/>}/>
      </Routes>
  );
}


