import React, {FC} from "react";
import {ExistingCustomer} from "core/RDS";
import {Table} from "react-bootstrap";
import {ModelObjectRCSV} from "core/Utils/types";

export const Vehicles: FC<{ customer: ModelObjectRCSV<ExistingCustomer> }> = ({customer}) => {
  return (
      <Table striped bordered>
        <thead>
        <tr>
          <th>No</th>
          <th>地域</th>
          <th>重量</th>
          <th>記号</th>
          <th>車種</th>
        </tr>
        </thead>
        <tbody>
        {customer.vehicles.length === 0 && (
            <tr>
              <td colSpan={5}>なし</td>
            </tr>
        )}
        {customer.vehicles?.map(vehicle => (
            <tr key={vehicle.id}>
              <td className={'text-center'}>{vehicle.v_no}</td>
              <td className={'text-center'}>{vehicle.v_area}</td>
              <td className={'text-center'}>{vehicle.v_weight}</td>
              <td className={'text-center'}>{vehicle.v_sign}</td>
              <td className={'text-center'}>{vehicle.v_type}</td>
            </tr>
        ))}
        </tbody>
      </Table>
  )
}
