import {faSquareCaretLeft, faSquareCaretRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TradeElementType} from "Backoffice/API/AppRouter/Trade";
import {useTRPC} from "Backoffice/UI/App";
import {TradeType} from "core/Codes";
import {FormSelect, FormText, TextDiv, useLoader, useMessage} from "core/UI/Components";
import React, {FC, useEffect, useState} from "react";
import {Breadcrumb, Button, Table} from "react-bootstrap";
import {Helmet} from "react-helmet-async";
import {Navigate, useNavigate} from "react-router";
import {Link, useSearchParams} from "react-router-dom";
import {useImmer} from "use-immer";


const defaultCondition = {
  shType: '0',
  date  : new Date().toYmd(),
  type  : '',
}


export const TradeListPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        trpc     = useTRPC();

  const [query, setQuery]         = useSearchParams(),
        [condition, setCondition] = useImmer(defaultCondition),
        [result, setResult]       = useState([] as TradeElementType[]);


  // 初期化
  useEffect(() => {
    const cond = {
      shType: query.get('shType') || defaultCondition.shType,
      date  : query.get('date') || defaultCondition.date,
      type  : query.get('type') || defaultCondition.type,
    };

    setCondition(cond);

    // データ取得
    Loader.task(async () => {
      try {
        const req = {...cond};

        // @ts-ignore チェックはサーバーサイドで実行
        const res = await trpc.trade.list.query(req);
        setResult(res);

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, [query]);


  // 検索
  const handleSearch = () => {
    for (const [k, v] of Object.entries(condition)) {
      if (v.trim()) {
        query.set(k, v);
      } else {
        query.delete(k);
      }
    }
    handleDate(0);
  };

  // 日付遷移
  const handleDate = (date: number) => {
    query.set('date', new Date(condition.date).addDate(date).toYmd());
    query.set('ts', new Date().getTime().toString());
    setQuery(query);
  }

  const total = {
    weight: 0,
    price : 0,
  };

  return (
      <>
        <Helmet>
          <title>取引一覧 | backoffice</title>
        </Helmet>

        <div className={'d-flex flex-column root-100vh pb-1'}>
          <div className={'d-flex flex-wrap small mb-1 mx-1 mx-md-5'}>
            <Breadcrumb>
              <Breadcrumb.Item active>Veronica</Breadcrumb.Item>
              <Breadcrumb.Item active>取引一覧</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className={'d-flex mb-1 mx-1 mt-0 mx-md-5 mt-md-3'}>
            <div className={'d-flex flex-wrap flex-fill'}>
              <div className={'d-flex align-items-center me-3 mb-1'}>
                <div onClick={() => handleDate(-1)} className={'cursor-pointer'}>
                  <FontAwesomeIcon icon={faSquareCaretLeft} className={'text-secondary'}/>
                </div>

                <FormText type={'date'}
                          value={condition.date}
                          onChange={({target: {value}}) => setCondition(draft => void (draft.date = value))}
                          placeholder={'日付'}
                          className={'mx-1'}
                          size={'sm'}
                          style={{width: "8rem"}}/>

                <div onClick={() => handleDate(+1)} className={'cursor-pointer'}>
                  <FontAwesomeIcon icon={faSquareCaretRight} className={'text-secondary'}/>
                </div>
              </div>

              <div className={'d-flex'}>
                <FormSelect value={condition.shType}
                            onChange={({target: {value}}) => setCondition(draft => void (draft.shType = value))}
                            className={'me-1 mb-1'}
                            size={'sm'}
                            style={{width: "6rem"}}>
                  <option value="0">仕入</option>
                  <option value="1">販売</option>
                </FormSelect>

                <FormSelect value={condition.type}
                            onChange={({target: {value}}) => setCondition(draft => void (draft.type = value))}
                            className={'me-1 mb-1'}
                            size={'sm'}
                            style={{width: "7rem"}}>
                  <option value="">取引形態</option>
                  {TradeType.all.map(el => <option key={el.id} value={el.id}>{el.value}</option>)}
                </FormSelect>
              </div>
            </div>

            <Button onClick={handleSearch}
                    className={'me-1 mb-1'}
                    size={'sm'}
                    style={{width: 80}}>
              検索
            </Button>
          </div>

          <div className={'flex-fill overflow-scroll mx-0 mt-0 mx-md-5 mt-md-3'}>
            <Table hover className={'thead-sticky'} style={{fontSize: 12}}>
              <thead className={'bg-white'}>
              <tr>
                <th className={'text-end'}>ID</th>
                <th className={'text-start'}>顧客名</th>
                <th className={'text-start'}>品名</th>
                <th className={'text-end'}>重量</th>
                <th className={'text-end'}>単価</th>
                <th className={'text-end'}>価格</th>
              </tr>
              </thead>

              <tbody style={{borderTop: '1.05px solid gray'}}>
              {result === null && (
                  <tr>
                    <td colSpan={6}>---</td>
                  </tr>
              )}
              {result?.map(elem => {
                total.price += elem.price;
                total.weight += elem.quantity;

                return (
                    <tr key={`${elem.id}-${elem.no}`} className={`${!!(elem.index % 2) ? '' : 'table-light'}`}>
                      <td className={'p-1 text-end'}>
                        <Link to={`/trade/${elem.shType === 0 ? 'shiire': 'hanbai'}/${elem.officeId}-${elem.id}`}>{elem.id}</Link>
                      </td>
                      <td className={'p-1 text-start'}>
                        <div className={'d-block d-md-none'}>
                          <TextDiv width={'4rem'}>{elem.customerName}</TextDiv>
                        </div>
                        <div className={'d-none d-md-block'}>
                          {elem.customerName}
                        </div>
                      </td>

                      <td className={'p-1 text-start'}>
                        <div className={'d-block d-md-none'}>
                          <TextDiv width={'4rem'}>{elem.itemName}</TextDiv>
                        </div>
                        <div className={'d-none d-md-block'}>
                          {elem.itemName}
                        </div>
                      </td>
                      <td className={'p-1 text-end'}>{elem.quantity?.withComma ?? ''}</td>
                      <td className={'p-1 text-end'}>{elem.unitPrice?.withComma ?? ''}</td>
                      <td className={'p-1 text-end'}>{elem.price?.withComma ?? ''}</td>
                    </tr>
                )
              })}
              </tbody>
              <tfoot style={{borderTop: '1.05px solid gray'}}>
              <tr>
                <td colSpan={3}>合計</td>
                <td colSpan={1} className={'text-end'}>{total.weight.withComma}</td>
                <td colSpan={2} className={'text-end'}>{total.price.withComma}</td>
              </tr>
              </tfoot>
            </Table>
          </div>
        </div>
      </>
  );
}


