import {AbstractCode} from "core/Codes/abstract";

/**
 * コードリスト
 */
const CodeList = [
  {
    id   : 1,
    value: 'ボックス',
  },
  {
    id   : 2,
    value: 'ベール',
  },
  {
    id   : 3,
    value: 'プレス'
  },
  {
    id   : 4,
    value: '束'
  },
  {
    id   : 5,
    value: 'フレコン'
  },
  {
    id   : 6,
    value: 'ドラム'
  },
  {
    id   : 7,
    value: 'ばら'
  }

] as const;


/**
 * タイプ
 */
export type TareType = typeof CodeList[number];

/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キーアクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.value] = elem;
  return result;
}, {} as Record<TareType['value'], TareType>);


/**
 * 公開
 */
export const TareType = Object.assign(CodeClass, NameAccessor);
