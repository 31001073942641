import {FormTextArea, useLoader, useMessage} from "core/UI/Components";
import React, {ChangeEventHandler, FC, useCallback, useEffect,} from "react";
import {Breadcrumb, Button, Col, Container, Form, Row} from "react-bootstrap";
import {useNavigate} from "react-router";
import {useTRPC} from "Backoffice/UI/App";
import {useImmer} from "use-immer";

export const ArticlePage: FC = () => {

  useEffect(() => {
    document.title = '取り扱い品目';

    doGet().then;
  }, []);

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();


  const [model, setModel] = useImmer<null | {
    acceptable: string,
    unacceptable: string,
  }>(null);


  // データ読み込み
  const doGet: () => Promise<void> = async () => {

    Loader.task(async () => {
      try {
        const model = await trpc.web.article.list.query();
        if (model) {
          setModel(
              model.reduce((results, elem) => {
                switch (elem.id) {
                  case 'acceptable':
                  case 'unacceptable':
                    results[elem.id] = elem.content?.join("\n");
                    break;
                }
                return results;
              }, {acceptable: "", unacceptable: ""})
          );
        }

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  };

  // 変更
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(async ({currentTarget: {name, value}}) => {
    // @ts-ignore
    setModel(draft => void (draft && (draft[name] = value)));
  }, []);

  // 更新
  const handleSubmit = async () => {

    Loader.task(async () => {
      if (!model) {
        return;
      }

      try {
        const acceptable   = model.acceptable?.split("\n").filter(elem => elem.trim()) ?? [],
              unacceptable = model.unacceptable?.split("\n").filter(elem => elem.trim()) ?? [],
              response     = await trpc.web.article.update.mutate({acceptable, unacceptable});

        setModel({
          acceptable  : response?.acceptable?.join("\n") ?? "",
          unacceptable: response?.unacceptable?.join("\n") ?? "",
        })

        Message.show('更新が完了しました');

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 500).then();
  };

  return (
      <div className={'container'}>
        <Breadcrumb>
          <Breadcrumb.Item active>サイト管理</Breadcrumb.Item>
          <Breadcrumb.Item active>取り扱い品目</Breadcrumb.Item>
        </Breadcrumb>

        <Container className={'my-3'}>
          {model === null && '読込中...'}
          {model !== null && (
              <>
                <Row>
                  <Col sm={{span: 6}}>
                    <Form.Group controlId="acceptable">
                      <Form.Label>受入できるもの</Form.Label>
                      <FormTextArea name="acceptable" placeholder="品目を箇条書きに入力してください"
                                    style={{height: '17.5em'}}
                                    value={model?.acceptable ?? ''}
                                    onChange={handleChange}
                                    required/>

                      <Form.Text className="text-muted">
                        <small></small>
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col sm={{span: 6}}>
                    <Form.Group controlId="unacceptable">
                      <Form.Label>受入不可</Form.Label>
                      <FormTextArea name="unacceptable" placeholder="品目を箇条書きに入力してください"
                                    style={{height: '17.5em'}}
                                    value={model?.unacceptable ?? ''}
                                    onChange={handleChange}
                                    required/>

                      <Form.Text className="text-muted">
                        <small></small>
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>

                <Row style={{marginTop: 50}}>
                  <Col md={{span: 4, offset: 4}} style={{padding: 10}}>
                    <Button variant="primary" className={'w-100'} disabled={!model}
                            onClick={handleSubmit}>
                      更新する
                    </Button>
                  </Col>
                </Row>
              </>
          )}
        </Container>
      </div>
  );
}


