import {ValidationError} from "Backoffice/API/ValidationError";
import {useTRPC} from "Backoffice/UI/App";
import {Type} from "core/Codes/Document";
import {VersionType} from "core/RDS/Models/Document";
import {ConfirmModal, FormSelect, FormText, useLoader, useMessage} from "core/UI/Components";
import {FormCol} from "core/UI/Components/FormCol";
import React, {FC, useEffect, useState,} from "react";
import {Breadcrumb, Button, Col, FormControl, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {Helmet} from "react-helmet-async";
import {useNavigate, useParams} from "react-router";
import {useImmer} from "use-immer";
import {useImmerChangeHandler} from "core/Utils/react/hooks";


const defaultModel = {
  id       : undefined as (number | undefined),
  typeId   : '',
  name     : '',
  fileName : '',
  tradedAt : '',
  deletedAt: null as (Date | null),
}

export const DocumentDetailPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  const [model, setModel]       = useImmer(defaultModel),
        [error, setError]       = useState<ValidationError | null>(null),
        [versions, setVersions] = useState<VersionType[]>([]),
        [version, setVersion]   = useState(''),
        [file, setFile]         = useState<File | null>(null),
        [age, setAge]           = useState(1),
        [modal, setModal]       = useState('');

  const {id} = useParams();


  useEffect(() => {
    if (id === 'new') {
      return;
    }

    Loader.task(async () => {
      const {model, versions} = await trpc.document.get.query({id});
      setModel({
        id       : model.id,
        typeId   : model.typeId.toString(),
        name     : model.name,
        fileName : model.fileName,
        tradedAt : model.tradedAt.toYmd(),
        deletedAt: model.deletedAt,
      });
      setVersions(versions);
      setVersion('');

    }, 300).catch(err => {
      Message.error(err, () => Navigate('../'));
      console.error(err);
    });
  }, [id, age]);


  // 編集
  const handleChange = useImmerChangeHandler(setModel);

  // 登録
  const handleSubmit = async () => {
    Loader.task(async () => {
      setError(null);

      // データ更新
      const res = await trpc.document.set.mutate(model);

      // ファイルが設定されている場合、アップロード
      if (file) {
        await fetch(res.uploadUrl, {
          method: 'PUT',
          body  : file,
        });
      }

      if (id === 'new') {
        Navigate(`../${res.id}`);
      } else {
        setAge(age + 1);
        setFile(null);
      }

    }, 300).catch(err => {
      const [converted, error] = ValidationError.convert(err);
      if (converted) {
        Message.error('入力内容を確認してください');
        setError(error)
        return;
      }

      Message.error(err);
      console.error(err);
    })
  };


  // ダウンロード
  const handleDownload = () => {
    Loader.task(async () => {
      return await trpc.document.download.query({id, versionId: version})
    }, 300).then(({url}) => {
      location.href = url;

    }).catch(err => {
      Message.error(err);
      console.error(err);
    })
  };


  // 削除
  const handleDelete = () => {
    Loader.task(async () => {
      return await trpc.document.delete.mutate({id})
    }, 300).then(() => {
      setAge(age + 1);
      setModal('');

    }).catch(err => {
      Message.error(err);
      console.error(err);
    })
  }

  const deleted     = model.deletedAt !== null,
        deletedNote = deleted ? `(削除:${model.deletedAt?.toYmd_hms()})` : '';

  return (
      <>
        <Helmet>
          <title>{id === 'new' ? '新規' : `ID:${id}`} | 訂正削除記録システム</title>
        </Helmet>

        <div className={'container small'}>
          <Breadcrumb>
            <Breadcrumb.Item href={'./'}>訂正削除記録システム</Breadcrumb.Item>
            <Breadcrumb.Item active>{id === 'new' ? '新規登録' : `ID:${id} ${model.name || '名称未設定'} ${deletedNote}`}</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className={'container small m-0 m-md-5'}>
          <Row>
            <FormCol inputId={'typeId'}
                     className={'mb-1'}
                     labelColClassname={'text-end'}
                     title={'種別'}
                     error={error?.get('typeId')}
                     size={{xs: [3, 9], md: [3, 6]}}>

              <FormSelect name={'typeId'}
                          value={model.typeId}
                          onChange={handleChange}
                          className={'me-1 mb-1'}
                          size={'sm'}
                          style={{}}>

                <option value="">-</option>
                {Type.all.map(el => <option key={el.id} value={el.id}>{el.value}</option>)}
              </FormSelect>
            </FormCol>
          </Row>
          <Row>
            <FormCol inputId={'name'}
                     className={'mb-1'}
                     labelColClassname={'text-end'}
                     title={'名称'}
                     error={error?.get('name')}
                     size={{xs: [3, 9], md: [3, 6]}}>

              <FormText name={'name'}
                        value={model.name}
                        onChange={handleChange}
                        className={'me-1 mb-1'}
                        size={'sm'}
                        style={{}}/>
            </FormCol>
          </Row>
          <Row>
            <FormCol inputId={'tradedAt'}
                     className={'mb-1'}
                     labelColClassname={'text-end'}
                     title={'取引日'}
                     error={error?.get('tradedAt')}
                     size={{xs: [3, 9], md: [3, 6]}}>

              <FormText name={'tradedAt'}
                        value={model.tradedAt}
                        type={'date'}
                        onChange={handleChange}
                        className={'me-1 mb-1'}
                        size={'sm'}
                        style={{}}/>
            </FormCol>
          </Row>

          <Row>
            <FormCol inputId={'file'}
                     className={'mb-1'}
                     labelColClassname={'text-end'}
                     title={'ファイル'}
                     size={{xs: [3, 9], md: [3, 6]}}>

              <FormControl key={age}
                           type='file'
                           onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                             const file = event.currentTarget.files?.[0] ?? null;
                             if (file) {
                               setModel(draft => void (draft.fileName = file.name))
                             }
                             setFile(file);
                           }}
                           className={'me-1 mb-1'}
                           size={'sm'}
                           style={{}}/>
            </FormCol>
          </Row>

          <Row>
            {id === 'new' && (
                <Col xs={{offset: 3, span: 6}}>
                  <Button className={'w-100'}
                          onClick={handleSubmit}
                          disabled={deleted}>
                    {id === 'new' ? '登録' : '更新'}
                  </Button>
                </Col>
            )}
            {id !== 'new' && (
                <>
                  <Col xs={{offset: 3, span: 3}}>
                    <Button className={'w-100'}
                            variant={'outline-primary'}
                            onClick={handleSubmit}
                            disabled={deleted}>
                      更新
                    </Button>
                  </Col>

                  <Col xs={{span: 3}}>
                    <Button className={'w-100'}
                            variant={'outline-danger'}
                            disabled={deleted}
                            onClick={() => setModal('deleting')}>
                      削除
                    </Button>
                  </Col>
                </>
            )}
          </Row>

          {id !== 'new' && (
              <div className={'mt-5'}>
                <Row>
                  <FormCol inputId={'versions'}
                           className={'mb-1'}
                           labelColClassname={'text-end'}
                           title={'バージョン'}
                           size={{xs: [3, 9], md: [3, 6]}}>

                    <FormSelect value={version}
                                onChange={({currentTarget: {value}}) => setVersion(value)}
                                className={'me-1 mb-1'}
                                size={'sm'}
                                style={{}}>

                      <option value="">-</option>
                      {versions.map(el =>
                          <option key={el.VersionId}
                                  value={el.VersionId}>
                            {el.LastModified.toYmd_hms()} {el.IsLatest && '(最新)'}
                          </option>)}
                    </FormSelect>
                  </FormCol>
                </Row>

                <Row>
                  <Col xs={{offset: 3, span: 6}}>
                    <OverlayTrigger placement={'bottom'}
                                    show={version === '' && versions.length > 0}
                                    overlay={
                                      <Tooltip style={{textAlign: 'left'}}>
                                        バージョンを選択してください
                                      </Tooltip>
                                    }>
                      <Button className={'w-100'}
                              variant={'secondary'}
                              disabled={version === ''}
                              onClick={handleDownload}>
                        ダウンロード
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
              </div>
          )}

          {/* 削除 */}
          <ConfirmModal show={modal === 'deleting'}
                        confirmLabel={'削除する'}
                        confirmButtonProps={{variant: 'danger'}}
                        onConfirm={handleDelete}
                        onCancel={() => setModal('')}
                        keyword={'削除'}>

            このファイルを削除しますか？<br/>
            <small>検索結果上から削除されるだけで、実際のデータは削除されません</small>
          </ConfirmModal>
        </div>
      </>
  );
}


