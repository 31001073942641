import React, {FC,} from "react";
import {Navigate, Routes} from "react-router";
import {Route} from "react-router-dom";
import {MaintenanceSelectPage} from "Backoffice/UI/Pages/Maintenance/select";
import {MaintenanceItemRoot} from "Backoffice/UI/Pages/Maintenance/item";
import {MaintenanceCheckPage} from "Backoffice/UI/Pages/Maintenance/Check/check";
import {VehicleType} from "Backoffice/UI/Pages/Maintenance/Types/vehicle";


export const MaintenanceCheckRoot: FC = () => {
  return (
      <Routes>
        <Route path="vehicle/*">
          <Route index element={<MaintenanceSelectPage type={VehicleType} suffix={'確認'}/>}/>
          <Route path=":target_id" element={<MaintenanceItemRoot type={VehicleType}/>}>
            <Route index element={<MaintenanceCheckPage/>}/>
          </Route>
          <Route path="*" element={<Navigate to=".." replace/>}/>
        </Route>

        <Route path="*" element={<Navigate to=".." replace/>}/>
      </Routes>
  );
}
