import React, {FC, useEffect, useState,} from "react";
import {useLoader, useMessage} from "core/UI/Components";
import {useNavigate, useOutletContext, useParams} from "react-router";
import {Helmet} from "react-helmet-async";
import {Breadcrumb, Button, Card, Col, Row} from "react-bootstrap";
import {useTRPC} from "Backoffice/UI/App";
import {MaintenanceInfo} from "core/Kysely/types/support/maintenance_status";
import {MaintenanceItemOutlet} from "../item";


export const MaintenanceCheckPage: FC = () => {
  const {type, target} = useOutletContext<MaintenanceItemOutlet>();

  const Loader    = useLoader(),
        Message   = useMessage(),
        Navi      = useNavigate(),
        tRPC      = useTRPC(),
        target_id = Number(useParams().target_id);

  const [state, setState] = useState<Record<string, MaintenanceInfo>>({})


  useEffect(() => {
    Loader.task(() => tRPC.maintenance.status.query({target_id}), 300)
          .then(response => setState(response?.data ?? {}))
          .catch(err => {
            console.error(err);
            Message.error(err);
          });
  }, [target_id]);


  const points: Record<string, string[]> = {...type.points, ...target.data};

  return (
      <>
        <Helmet>
          <title>Check {type.name} {String(target_id)} | backoffice</title>
        </Helmet>

        <div className={'container small'}>
          <Breadcrumb>
            <Breadcrumb.Item href={'/'}>Veronica</Breadcrumb.Item>
            <Breadcrumb.Item active>メンテナンス確認</Breadcrumb.Item>
            <Breadcrumb.Item href={'#'} onClick={() => Navi('../')}>{type.name}選択</Breadcrumb.Item>
            <Breadcrumb.Item active>{target.name}</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className={'container'}>
          <Row className={'mb-2'}>
            <Col xs={4}>
              <Button onClick={() => Navi('../')}
                      className={'w-100 m-1'}
                      variant={'outline-primary'}
                      size={'sm'}>
                ← 戻る
              </Button>
            </Col>
          </Row>

          {Object.keys(points).map(category => (
              <React.Fragment key={category}>
                <Row className={'mt-4'}>
                  <Col xs={4}>
                    <h3>{category}</h3>
                  </Col>
                </Row>
                <Row>

                  {(points[category]).map(item => {
                    const result = state[item]?.result ?? '履歴なし',
                          ok     = result === 'ok',
                          bg     = ok ? '#afeeee' : '#ffa07a';

                    return (
                        <Col xs={6}
                             key={item}
                             className={'p-2'}
                        >
                          <Card className={'w-100'}
                                style={{background: `${bg}20`}}
                          >
                            <Card.Body>
                              <Card.Title>{item}</Card.Title>
                              <Card.Text>
                                <span>{state[item]?.result}</span>
                                <hr/>
                                <small className={'d-block'}>
                                  {state[item]?.date ?? '----/--/--'} | {state[item]?.user ?? '-----'}
                                </small>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                    );
                  })}
                </Row>
              </React.Fragment>
          ))}
        </div>
      </>
  );
}
