import {faSquareCaretLeft, faSquareCaretRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ScheduleResponse} from "Backoffice/API/AppRouter/Schedule";
import {useTRPC} from "Backoffice/UI/App";
import {Info105} from "Backoffice/UI/Pages/Schedule/Daily/Info105";
import {Member} from "Backoffice/UI/Pages/Schedule/Member";
import {FormText, useLoader} from "core/UI/Components";
import React, {FC, useEffect, useState} from "react";
import {Breadcrumb, Container, Row} from "react-bootstrap";
import {Helmet} from "react-helmet-async";
import {useNavigate, useParams} from "react-router";


export const DailyPage: FC = () => {

  const Loader   = useLoader(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  const {ymd} = useParams(),
        date  = new Date(ymd || '');


  useEffect(() => {
    Loader.task(async () => {
      const data = await trpc.schedule.daily.query(date);
      setData(data);
    });
  }, [ymd]);


  // ステート
  const [data, setData] = useState<null | ScheduleResponse>(null);
  if (data === null) {
    return <div className={'container'}>読込中</div>;
  }

  return (
      <>
        <Helmet>
          <title>スケジュール | backoffice</title>
        </Helmet>

        <div className={'container small'}>
          <Breadcrumb>
            <Breadcrumb.Item active>Veronica</Breadcrumb.Item>
            <Breadcrumb.Item active>スケジュール</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Container className={'my-3 small'}>
          <div className={'d-flex align-items-center me-3 mb-1'}>
            <div onClick={() => Navigate(`../${date.addDate(-1).toYmd()}`)} className={'cursor-pointer'}>
              <FontAwesomeIcon icon={faSquareCaretLeft} className={'text-secondary'}/>
            </div>

            <FormText type={"date"}
                      value={date.toYmd()}
                      onChange={({target: {value}}) => Navigate(`../${value}`)}
                      className={'mx-1'}
                      size={'sm'}
                      style={{width: "8rem"}}/>

            <div onClick={() => Navigate(`../${date.addDate(+1).toYmd()}`)} className={'cursor-pointer'}>
              <FontAwesomeIcon icon={faSquareCaretRight} className={'text-secondary'}/>
            </div>
          </div>

          <Row>
            <Info105 title={'引取'} list={data.hikitori}/>
          </Row>

          <Row>
            <Info105 title={'代納'} list={data.dainou}/>
          </Row>

          <Row>
            <div className={'border border-1 p-1 my-1 small'}>
              <h6>メモ</h6>
              <span>{data.daily.memo}</span>
            </div>
          </Row>
        </Container>

        <Container className={'d-flex flex-wrap mt-2 px-0'}>
          {data.members.map(member => (
              <Member key={`${member.daily?.[0]?.ymd}-${member.uid}`}
                      daily={member.daily?.[0] ?? null}
                      header={
                        <a href={`/schedule/user/${member.user.id}/${new Date().toYmd()}`}>
                          <span className={'me-2'}>{member.user.name}</span>
                          <span>{member.user.mobile} | {member.user.mobile2}</span>
                        </a>
                      }/>
          ))}
        </Container>
      </>
  );
}
