import React, {FC, useCallback, useEffect, useState} from "react";
import {Breadcrumb, Table} from "react-bootstrap";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import {useLoader, useMessage} from "core/UI/Components";
import {useTRPC} from "Backoffice/UI/App";
import {WebDataType} from "Backoffice/API/AppRouter/WebData";
import {NewsType} from "Backoffice/API/AppRouter/WebData/News";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {Helmet} from "react-helmet-async";

export const NewsListPage: FC = () => {

  useEffect(() => {
    doListNews().then();
  }, []);


  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  // ステート
  const [list, setList] = useState<WebDataType<NewsType>[]>([]);

  // ニュース読み込み
  const doListNews: () => Promise<void> = useCallback(async () => {
    Loader.task(async () => {
      try {
        const list = await trpc.web.news.list.query();
        setList(list.sort((a, b) => a.id.localeCompare(b.id)));

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, []);

  return (
      <>
        <Helmet>
          <title>ニュース管理 | backoffice</title>
        </Helmet>

        <div className={'d-flex flex-column root-100vh pb-1'}>
          <div className={'d-flex flex-wrap small mb-1 mx-1 mx-md-5'}>
            <Breadcrumb>
              <Breadcrumb.Item active>サイト管理</Breadcrumb.Item>
              <Breadcrumb.Item active>ニュース管理</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className={'flex-fill overflow-scroll mx-0 mt-0 mx-md-5 mt-md-3'}>
            <Table striped hover className={'thead-sticky'} style={{fontSize: 14}}>
              <thead>
              <tr>
                <th style={{minWidth: 100}}>内容</th>
                <th style={{minWidth: 100}}>メモ</th>
                <th className={'text-center'} style={{width: 60}}>公開</th>
                <th className={'text-center'} style={{width: 180}}>作成日時</th>
              </tr>
              </thead>

              <tbody style={{borderTop: '1.05px solid gray'}}>
              {list === null && (
                  <tr>
                    <td colSpan={3}>---</td>
                  </tr>
              )}
              {list?.map(elem => (
                  <tr key={elem.id}
                      className={'cursor-pointer'}
                      onClick={() => Navigate(`./${elem.id}/edit`)}>

                    <td className={'align-middle'}>{elem.content}</td>
                    <td className={'align-middle'}>{elem.memo}</td>
                    <td className={'align-middle text-center'}>
                      {elem.visible && <FontAwesomeIcon icon={faCheck}/>}
                    </td>
                    <td className={'align-middle text-center'}>
                      {new Date(elem.id).toYmd_hms()}
                    </td>
                  </tr>
              ))}
              </tbody>
              <tfoot style={{borderTop: '1.05px solid gray'}}>
              <tr>
                <td colSpan={4}>
                  <div className={'d-flex justify-content-center '}>
                    <Link to={'./create'} className={'btn btn-sm btn-outline-primary mx-2'} style={{width: '20em'}}>
                      新規作成
                    </Link>
                  </div>
                </td>
              </tr>
              </tfoot>
            </Table>
          </div>
        </div>
      </>
  );
}


