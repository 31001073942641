import React, {ChangeEventHandler, FC, FormEventHandler, MouseEventHandler, useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {Link} from "react-router-dom";
import {Breadcrumb, Button, Col, Form, Row} from "react-bootstrap";
import {ConfirmModal, FormCheck, FormText, Nl2Br, useLoader, useMessage} from "core/UI/Components";
import {useTRPC} from "Backoffice/UI/App";
import {UserType} from "Backoffice/API/AppRouter/User";
import {ValidationError} from "Backoffice/API/ValidationError";
import {useImmer} from "use-immer";
import {BoolPropsOf, StringPropsOf} from "core/Utils/types";
import {RoleType} from "Backoffice/API/AppRouter/User//Role";


export const UserEditPage: FC = () => {

  useEffect(() => {
    document.title = 'ユーザー編集';

    doGetUser().then();

    (async () => {
      try {
        const code = await trpc.user.role.get.query();
        setRoles(code.contents);

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    })();
  }, []);


  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  // パラメータ
  const {userId} = useParams();

  // ステート
  const [roles, setRoles]       = useState<RoleType[] | null>(null),
        [model, setModel]       = useImmer<UserType | null>(null),
        [error, setError]       = useState<ValidationError | null>(null),
        [deleting, setDeleting] = useState<boolean>(false);

  // ユーザー読み込み
  const doGetUser: () => Promise<void> = useCallback(async () => {
    Loader.task(async () => {
      if (!userId) {
        return;
      }

      try {
        const user = await trpc.user.get.query(userId);
        setModel(user);

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, [userId]);


  // 変更
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(async ({currentTarget: {name, value}}) => {
    setModel(draft => void (draft && (draft[name as StringPropsOf<UserType>] = value)));
  }, []);

  // 変更
  const handleSwitch: ChangeEventHandler<HTMLInputElement> = useCallback(async ({currentTarget: {name, checked}}) => {
    console.log(name, checked);
    setModel(draft => void (draft && (draft[name as BoolPropsOf<UserType>] = checked)));
  }, []);

  // 更新
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {

    // デフォルトの挙動を止める
    event.preventDefault();
    event.stopPropagation();

    Loader.task(async () => {
      if (!model) {
        return;
      }

      setError(null);

      try {
        const response = await trpc.user.update.mutate(model);
        console.log(response);

        Message.show('更新が完了しました', () => {
          Navigate('..');
        });

      } catch (err) {
        const [converted, error] = ValidationError.convert(err);
        if (converted) {
          Message.error('入力内容を確認してください');
          setError(error)
          return;
        }

        console.error(err);
        Message.error(err);
      }
    }, 500).then();
  };


  // 削除
  const handleClickDelete: MouseEventHandler<HTMLButtonElement> = useCallback(async (_event) => {
    if (!userId || !model || !model.version) {
      return;
    }

    Loader.task(async () => {
      try {
        const response = await trpc.user.delete.mutate({id: userId, version: model.version});
        console.log(response);

        Message.show('削除が完了しました', () => {
          Navigate('..');
        });

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 500).then();
  }, [userId, model?.version]);


  return (
      <div className={'container'}>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: ".."}}>ユーザー管理</Breadcrumb.Item>
          <Breadcrumb.Item active>
            {!!model?.deletedAt && "削除済み"}
            {!!model?.deletedAt || "編集"}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Form onSubmit={handleSubmit} style={{marginTop: 50}}>
          <input type='submit' className={'d-none'} disabled/>

          {!!model?.deletedAt && (
              <Row style={{marginBottom: 50}}>
                <Col md={{span: 8, offset: 2}} style={{padding: 5}}>
                  <p className={'fs-2 text-danger border border-1 border-danger rounded p-2 text-center'}>
                    削除済みユーザーは編集できません
                  </p>
                </Col>
              </Row>
          )}

          <Row style={{marginBottom: 50}}>
            <Col md={{span: 8, offset: 2}} style={{padding: 5}}>
              <Form.Group controlId="email">
                <Form.Label>メールアドレス</Form.Label>
                <FormText type="email"
                          value={model?.email ?? ''}
                          readOnly
                          disabled={!!model?.deletedAt}/>

                <Form.Text className="text-muted">
                  <small>
                    メールアドレスの変更はできません。
                  </small>
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={{span: 8, offset: 2}} style={{padding: 10}}>
              <Form.Group controlId="username">
                <Form.Label>お名前</Form.Label>
                <FormText placeholder="泉金属 太郎"
                          name={'name'}
                          value={model?.name ?? ''}
                          onChange={handleChange}
                          disabled={!!model?.deletedAt}
                          required/>
                <Form.Text className="text-muted">
                  <small>
                  </small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('name')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={{span: 8, offset: 2}} style={{padding: 10}}>
              <Form.Group controlId="veronicaId">
                <Form.Label>Veronica Id</Form.Label>
                <FormText placeholder="Veronicaで使用しているID"
                          type={'number'}
                          name={'veronicaId'}
                          value={model?.veronicaId ?? ''}
                          onChange={handleChange}
                          disabled={!!model?.deletedAt}/>
                <Form.Text className="text-muted">
                  <small>
                  </small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('veronicaId')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={{span: 8, offset: 2}} style={{padding: 10}}>
              <Form.Label>ロール</Form.Label>
              <div className={'d-flex justify-content-start'}>
                <FormCheck id={'role-admin'}
                           checked={model?.admin ?? false}
                           onChange={() => setModel(draft => void (draft && (draft.admin = !draft?.admin)))}
                           disabled={!!model?.deletedAt}>
                  <span>管理者</span>
                </FormCheck>

                {roles?.map(role => (
                    <FormCheck id={role.id} key={role.id}
                               checked={model?.roles?.includes(role.id) ?? false}
                               onChange={() => setModel(draft => void (draft && (draft.roles = draft.roles?.toggle(role.id) ?? [role.id])))}
                               disabled={!!model?.deletedAt}>
                      <span>{role.name}</span>
                    </FormCheck>
                ))}
              </div>
            </Col>
          </Row>

          <Row style={{marginTop: 20}}>
            <Col md={{span: 8, offset: 2}} style={{padding: 5}}>
              <FormCheck id={'disabled'}
                         checked={model?.disabled ?? false}
                         onChange={handleSwitch}
                         disabled={!!model?.deletedAt}>
                <span>アクセス無効</span>
              </FormCheck>
            </Col>
          </Row>

          <Row>
            <Col md={{span: 8, offset: 2}} style={{padding: 10}}>
              <Form.Group controlId="memo">
                <Form.Label>メモ</Form.Label>
                <FormText placeholder="メモ"
                          name={'memo'}
                          value={model?.memo ?? ''}
                          onChange={handleChange}
                          disabled={!!model?.deletedAt}/>
                <Form.Text className="text-muted">
                  <small></small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('memo')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>
          </Row>


          <Row style={{marginTop: 50}}>
            <Col md={{span: 4, offset: 2}} style={{padding: 10}}>
              <Button variant="outline-primary" className={'w-100'} type='submit' disabled={!model || !!model?.deletedAt}>
                更新する
              </Button>
            </Col>

            <Col md={{span: 4}} style={{padding: 10}}>
              <Button variant={'outline-danger'} className={'w-100'} onClick={() => setDeleting(true)} disabled={!model || !!model?.deletedAt}>
                削除する
              </Button>
            </Col>
          </Row>

          <Row style={{marginTop: 100}}>
            <Col md={{span: 4, offset: 4}} style={{padding: 10}}>
              <Link to={'..'} className={'btn btn-secondary w-100'}>
                もどる
              </Link>
            </Col>
          </Row>
        </Form>

        {/* 削除モーダル */}
        <ConfirmModal show={deleting}
                      keyword={model?.email ?? '削除'}
                      confirmLabel={'削除する'}
                      confirmButtonProps={{
                        variant: 'danger',
                        value  : userId
                      }}
                      onConfirm={handleClickDelete}
                      onCancel={() => setDeleting(false)}>

          ユーザー「{model?.name}({model?.email})」を削除しますか？
        </ConfirmModal>
      </div>
  );
}


