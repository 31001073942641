import React, {FC,} from "react";
import {Navigate, Routes} from "react-router";
import {Route} from "react-router-dom";
import {DailyReportFormPage} from "Backoffice/UI/Pages/DailyReport/Form";


export const DailyReportPage: FC = () => {
  return (
      <Routes>
        <Route index element={<Navigate to={`./form?date=${new Date().toYmd()}`}/>}/>
        <Route path="form" element={<DailyReportFormPage/>}/>
        <Route path="*" element={<Navigate to=".." replace/>}/>
      </Routes>
  );
}
