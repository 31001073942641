import {AbstractCode} from "core/Codes/abstract";

/**
 * コードリスト
 */
const CodeList = [
  {
    id   : 1,
    value: '持ち込み',
  },
  {
    id   : 2,
    value: '引き取り',
  },
  {
    id   : 3,
    value: '代納',
  },
  {
    id   : 4,
    value: 'ﾁｬｰﾀｰ持込',
  },
  {
    id   : 5,
    value: 'ﾁｬｰﾀｰ引取',
  },
  {
    id   : 6,
    value: '持込代納',
  },
  {
    id   : 7,
    value: '引取代納',
  },
  {
    id   : 99,
    value: 'その他',
  },
] as const;


/**
 * タイプ
 */
export type TradeType = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キーアクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.value] = elem;
  return result;
}, {} as Record<TradeType['value'], TradeType>);


/**
 * 公開
 */
export const TradeType = Object.assign(CodeClass, NameAccessor);
