import React, {ChangeEventHandler, FC, FormEventHandler, MouseEventHandler, useCallback, useEffect, useState} from "react";
import {Breadcrumb, Button, Form, Modal, Row, Col} from "react-bootstrap";
import {useNavigate, useParams} from "react-router";
import {useLoader, useMessage, FormTextArea, FormCheck} from "core/UI/Components";
import {Link} from "react-router-dom";
import {RecruitContents} from "../Contents";
import {v4 as uuid} from 'uuid';
import {useTRPC} from "Backoffice/UI/App";
import {useImmer} from "use-immer";
import {UpdateWebDataType} from "Backoffice/API/AppRouter/WebData";
import {ValidationError} from "Backoffice/API/ValidationError";
import {UIRecruitType} from "../Create";
import {BoolPropsOf, StringPropsOf} from "core/Utils/types";

export const RecruitEditPage: FC = () => {

  useEffect(() => {
    document.title = '求人編集';

    doGetRecruit().then();
  }, []);


  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  // パラメータ
  const {id} = useParams();

  // ステート
  const [model, setModel]       = useImmer<UpdateWebDataType<UIRecruitType[]> | null>(null),
        setContent              = useCallback((callback: (content: UIRecruitType[]) => UIRecruitType[]) => {
          setModel(draft => void (draft && (draft.content = callback(draft.content))));
        }, [setModel]),
        [_error, setError]      = useState<ValidationError | null>(null),
        [deleting, setDeleting] = useState<boolean>(false);

  // 対象読み込み
  const doGetRecruit: () => Promise<void> = useCallback(async () => {
    Loader.task(async () => {
      if (!id) {
        return;
      }

      try {
        const model = await trpc.web.recruits.get.query(id);

        // __uiKey付与
        setModel({
          ...model,
          content: model.content.map(elem => ({...elem, __uiKey: uuid()}))
        });

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, []);

  // 変更
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(async ({currentTarget: {name, value}}) => {
    setModel(draft => void (draft && (draft[name as StringPropsOf<UpdateWebDataType<UIRecruitType[]>>] = value)));
  }, []);

  // 変更
  const handleSwitch: ChangeEventHandler<HTMLInputElement> = useCallback(async ({currentTarget: {name, checked}}) => {
    setModel(draft => void (draft && (draft[name as BoolPropsOf<UpdateWebDataType<UIRecruitType[]>>] = checked)));
  }, []);

  // 更新
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {

    // デフォルトの挙動を止める
    event.preventDefault();
    event.stopPropagation();

    Loader.task(async () => {
      if (!model) {
        return;
      }

      setError(null);

      try {
        const response = await trpc.web.recruits.update.mutate(model);
        console.log(response);

        Message.show('更新が完了しました', () => {
          Navigate('..');
        });

      } catch (err) {
        const [converted, error] = ValidationError.convert(err);
        if (converted) {
          Message.error('入力内容を確認してください');
          setError(error)
          return;
        }

        console.error(err);
        Message.error(err);
      }
    }, 500).then();
  };


  // 削除
  const handleClickDelete: MouseEventHandler<HTMLButtonElement> = useCallback(async (_event) => {
    if (!id) {
      return;
    }

    Loader.task(async () => {
      try {
        const response = await trpc.web.recruits.delete.mutate(id);
        console.log(response);

        Message.show('削除が完了しました', () => {
          Navigate('..');
        });

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 500).then();
  }, []);


  return (
      <div className={'container'}>
        <Breadcrumb>
          <Breadcrumb.Item active>サイト管理</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: ".."}}>求人管理</Breadcrumb.Item>
          <Breadcrumb.Item active>編集</Breadcrumb.Item>
        </Breadcrumb>

        <Form onSubmit={handleSubmit} style={{marginTop: 50}}>
          <input type='submit' className={'d-none'} disabled/>
          <input type='hidden' name={'dataid'} value={model?.id ?? ''}/>

          <Row>
            <Col style={{padding: 5}}>
              <Form.Label>募集要項</Form.Label>
              <RecruitContents content={model?.content ?? []} setContent={setContent}/>
            </Col>
          </Row>

          <Row>
            <Col md={{span: 8, offset: 2}} style={{padding: 5}}>
              <Form.Group controlId="memo">
                <Form.Label>メモ</Form.Label>
                <FormTextArea name="memo" placeholder="メモ"
                              style={{height: '7.5em'}}
                              value={model?.memo ?? ''}
                              onChange={handleChange}/>

                <Form.Text className="text-muted">
                  <small></small>
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={{span: 8, offset: 2}} className={'text-center'} style={{marginTop: '2em', padding: 5}}>
              <FormCheck id={'visible'}
                         checked={model?.visible ?? false}
                         onChange={handleSwitch}
                         disabled={false}>
                <span>公開する</span>
              </FormCheck>
            </Col>
          </Row>

          <Row style={{marginTop: 50}}>
            <Col md={{span: 4, offset: 2}} style={{padding: 10}}>
              <Button variant="outline-primary" className={'w-100'} type='submit' disabled={!model}>
                更新する
              </Button>
            </Col>

            <Col md={{span: 4}} style={{padding: 10}}>
              <Button variant={'outline-danger'} className={'w-100'} onClick={() => setDeleting(true)} disabled={!model}>
                削除する
              </Button>
            </Col>
          </Row>

          <Row style={{marginTop: 100}}>
            <Col md={{span: 4, offset: 4}} style={{padding: 10}}>
              <Link to={'..'} className={'btn btn-secondary w-100'}>
                もどる
              </Link>
            </Col>
          </Row>
        </Form>

        <Modal show={deleting}
               backdropClassName={'system-modal'}>

          <Modal.Body className={`m-body`}>
            この求人を削除しますか？
          </Modal.Body>

          <Modal.Footer className={'justify-content-center'}>
            <Button variant="secondary" style={{minWidth: '10em'}}
                    onClick={() => setDeleting(false)}>
              キャンセル
            </Button>

            <Button variant="danger" style={{minWidth: '10em'}}
                    onClick={handleClickDelete}
                    disabled={!model}>
              削除する
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
  );
}


