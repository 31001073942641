import React, {ChangeEventHandler, FC, useCallback, useEffect} from "react";
import {useImmer} from "use-immer";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {FormText} from "core/UI/Components";
import {PermissionTable} from "./PermissionTable";
import {RoleType} from "Backoffice/API/AppRouter/User/Role";


export const RoleModal: FC<{
  value: RoleType | null,
  onSubmit: (role: RoleType) => void,
  onHide: () => void
}> = ({value, onSubmit, onHide}) => {

  const [role, setRole] = useImmer<RoleType>({} as RoleType);

  useEffect(() => {
    if (value !== null) {
      setRole(value);
    }
  }, [value])

  // onChange
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(({currentTarget: {name, value}}) => {
    setRole(draft => void (draft && (draft[name as keyof Omit<RoleType, "permissions">] = value)));
  }, []);

  return (
      <Modal show={value !== null} size={'lg'}>
        <Modal.Body>
          <Row className={'pb-3'}>
            <Col md={{span: 8, offset: 2}}>
              <Form.Group controlId="rolename">
                <Form.Label>ロール名称</Form.Label>
                <FormText placeholder="xxxx管理者"
                          name={'name'}
                          value={role.name ?? ''}
                          onChange={handleChange}
                          required/>
              </Form.Group>
            </Col>
          </Row>

          <Row className={'pb-3'}>
            <Col md={{span: 8, offset: 2}}>
              <label className="form-label">許可</label>
              {role && (
                  <PermissionTable role={role} setRole={setRole}/>
              )}
            </Col>
          </Row>

          <Row>
            <Col md={{span: 8, offset: 2}}>
              <Form.Group controlId="memo">
                <Form.Label>メモ</Form.Label>
                <FormText placeholder="メモ"
                          name="memo"
                          value={role.memo ?? ''}
                          onChange={handleChange}/>
                <Form.Text className="text-muted">
                  <small></small>
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger"
                  onClick={onHide}
                  style={{minWidth: '10em'}}>
            キャンセル
          </Button>

          <Button variant="primary"
                  onClick={() => onSubmit(role)}
                  style={{minWidth: '10em'}}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

  );
}
