import React, {FC,} from "react";
import {Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";
import {NewsPage} from "./News";
import {RecruitsPage} from "./Recruits";
import {ArticlePage} from "./Article";

export const WebsitePage: FC = () => {
  return (
      <Routes>
        <Route path="article/*" element={<ArticlePage/>}/>
        <Route path="news/*" element={<NewsPage/>}/>
        <Route path="recruits/*" element={<RecruitsPage/>}/>
        <Route path="*" element={<Navigate to=".." replace/>}/>
      </Routes>
  );
}


