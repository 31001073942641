import React, {FC,} from "react";
import {Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";
import {WeighingSummaryPage} from "Backoffice/UI/Pages/Trade/Weighing/Summary";


export const WeighingPage: FC = () => {
  return (
      <Routes>
        <Route index element={<WeighingSummaryPage/>}/>
        <Route path="*" element={<Navigate to=".." replace/>}/>
      </Routes>
  );
}

