import {FC, useEffect} from "react";
import {signOut} from "aws-amplify/auth";

export const LogoutPage: FC = () => {

  useEffect(() => {
    (async () => {
      await signOut();

      // 画面リロード
      window.location.href = '/';
    })();
  }, []);

  return <></>;
}
