import React, {FC, useEffect, useState} from "react";
import {Breadcrumb, Container, Row, Tab, Tabs} from "react-bootstrap";
import {useNavigate, useParams} from "react-router";
import {useTRPC} from "Backoffice/UI/App";
import {useLoader, useMessage} from "core/UI/Components";
import {Link} from "react-router-dom";
import {GetResponse} from "Backoffice/API/AppRouter/Customer/Potential";
import {SalesActivities} from "./SalesActivities";
import {Helmet} from "react-helmet-async";
import {Info} from "Backoffice/UI/Pages/Customer/Potential/Detail/Info";

export const PotentialCustomerDetailPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  const {id} = useParams();

  const [model, setModel] = useState<null | GetResponse>(null);


  useEffect(() => {
    Loader.task(async () => {
      try {
        const model = await trpc.customer.potential.get.query(Number(id));
        setModel(model);

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, []);

  return (
      <>
        <Helmet>
          <title>ID: ${id} ${model?.customer?.progressName ?? ''}|開拓客</title>
        </Helmet>

        <div className={'container small'}>
          <Breadcrumb>
            <Breadcrumb.Item active>Veronica</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{to: "/customer/potential/"}}>開拓客リスト</Breadcrumb.Item>
            <Breadcrumb.Item active>{model?.customer?.name ?? '読込中...'}</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Container className={'my-3 small'}>
          {model === null ? <span>読込中...</span> :
           <>
             <Row>
               <Tabs defaultActiveKey="info" className="mb-3">
                 <Tab eventKey="info" title="詳細情報">
                   <Info customer={model.customer}/>
                 </Tab>

                 <Tab eventKey="activities" title="営業">
                   <SalesActivities salesActivities={model.salesActivities}/>
                 </Tab>
               </Tabs>
             </Row>
           </>
          }
        </Container>
      </>
  );
}


