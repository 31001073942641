import React, {FC, useEffect, useState,} from "react";
import {useLoader, useMessage} from "core/UI/Components";
import {useNavigate} from "react-router";
import {useTRPC} from "Backoffice/UI/App";
import {MaintenanceTarget} from "core/Kysely/types/support/maintenance_targets";
import {Breadcrumb, Button, Col, Row} from "react-bootstrap";
import {Helmet} from "react-helmet-async";
import {MaintenanceTypeProps} from "Backoffice/UI/Pages/Maintenance/index";


export const MaintenanceSelectPage: FC<{ type: MaintenanceTypeProps, suffix: string }> = ({type, suffix}) => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        tRPC     = useTRPC();


  const [targets, setTargets] = useState<Pick<MaintenanceTarget, 'id' | 'name'>[]>([]);

  useEffect(() => {
    Loader.task(async () => await tRPC.maintenance.targets.query({type_id: type.id}), 300)
          .then(response => setTargets(response))
          .catch(err => {
            console.error(err);
            Message.error(err);
          });
  }, [])

  return (
      <>
        <Helmet>
          <title>メンテナンス | backoffice</title>
        </Helmet>

        <div className={'container small'}>
          <Breadcrumb>
            <Breadcrumb.Item href={'/'}>Veronica</Breadcrumb.Item>
            <Breadcrumb.Item active>メンテナンス{suffix}</Breadcrumb.Item>
            <Breadcrumb.Item active>{type.name}選択</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className={'container'}>
          <Row>
            {targets.map(({id, name}) => (
                <Col xs={6}>
                  <Button onClick={() => Navigate(`${id}`)}
                          className={'w-100 m-1'}
                          variant={'secondary'}>
                    {name}
                  </Button>
                </Col>
            ))}
          </Row>
        </div>
      </>
  );
}
