import React, {FC} from "react";
import {SalesActivityType} from "core/RDS";

export const SalesActivities: FC<{
  salesActivities: SalesActivityType[]
}> = ({salesActivities}) => {
  return (
      <>
        {salesActivities === null && (
            <span>なし</span>
        )}
        {salesActivities.map(salesActivity =>
            <Activity key={salesActivity.id} salesActivity={salesActivity}/>)}
      </>
  )
}

const Activity: FC<{ salesActivity: SalesActivityType }> = ({salesActivity}) => {
  return (
      <ul className={'d-block list-unstyled border border-1 m-3 p-3'}>
        <li>{salesActivity.trandate.toYmd()} | {salesActivity.salesType?.value ?? '不明'}</li>
        <li>実行: {salesActivity.chargeUser?.name}</li>
        <li>感触: {salesActivity.reply}</li>
        <li>メモ: {salesActivity.memo}</li>
      </ul>
  )
}
