import React, {FC, useEffect, useState,} from "react";
import {Helmet} from "react-helmet-async";
import {Breadcrumb, Button, Table} from "react-bootstrap";
import {FormSelect, FormText, Pager, useLoader, useMessage} from "core/UI/Components";
import {useNavigate} from "react-router";
import {useTRPC} from "Backoffice/UI/App";
import {Link, useSearchParams} from "react-router-dom";
import {useImmer} from "use-immer";
import {useImmerChangeHandler} from "core/Utils/react/hooks";
import {Type} from "core/Codes/Document";
import {DocumentType} from "core/RDS/Models/Document";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";

const pageSize = 50;

const defaultCondition = {
  typeId : '',
  name   : '',
  since  : '',
  until  : '',
  deleted: false as boolean,
}


export const DocumentListPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  const [query, setQuery]          = useSearchParams(),
        [condition, setCondition]  = useImmer(defaultCondition),
        [{total, list}, setResult] = useState({total: 0, list: [] as DocumentType[]});


  // 初期化
  useEffect(() => {
    const cond = {
            typeId : query.get('typeId') || defaultCondition.typeId,
            name   : query.get('name') || defaultCondition.name,
            since  : query.get('since') || defaultCondition.since,
            until  : query.get('until') || defaultCondition.until,
            deleted: query.get('deleted') === '1',
          },
          page = Number(query.get('page')) || 1;

    setCondition(cond);

    // データ取得
    if (query.get('ts')) {
      Loader.task(async () => {
        try {
          const req = {...cond, page, pageSize};

          // @ts-ignore チェックはサーバーサイドで実行
          const res = await trpc.document.list.query(req);

          setResult(res);

        } catch (err) {
          console.error(err);
          Message.error(err);
        }
      }, 300).then();
    }
  }, [query]);

  // 編集
  const handleChange = useImmerChangeHandler(setCondition);

  // 検索
  const handleSearch = () => {
    for (const [k, v] of Object.entries(condition)) {
      if (typeof v === 'boolean') {
        if (v) {
          query.set(k, '1')
        } else {
          query.delete(k);
        }
      } else if (v.trim()) {
        query.set(k, v);
      } else {
        query.delete(k);
      }
    }
    handlePage(1);
  };

  // ページ遷移
  const handlePage = (page: number) => {
    query.set('page', String(page));
    query.set('ts', new Date().getTime().toString());
    setQuery(query);
  }

  return (
      <>
        <Helmet>
          <title>訂正削除記録システム</title>
        </Helmet>

        <div className={'d-flex flex-column root-100vh pb-1'}>
          <div className={'d-flex flex-wrap small mb-1 mx-1 mx-md-5'}>
            <Breadcrumb>
              <Breadcrumb.Item active>訂正削除記録システム</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className={'d-flex small mb-1 mx-1 mx-md-5'}>
            <div className={'d-flex flex-wrap flex-fill'}>
              <div className={'d-flex'}>
                <FormSelect name={'typeId'}
                            value={condition.typeId}
                            onChange={handleChange}
                            placeholder={'種別'}
                            className={'me-1 mb-1'}
                            size={'sm'}
                            style={{width: "8rem"}}>
                  <option value=''>-</option>
                  {Type.all.map(({id, value}) => (
                      <option key={id} value={id}>{value}</option>
                  ))}
                </FormSelect>

                <FormText name={'name'}
                          value={condition.name}
                          onChange={handleChange}
                          placeholder={'名称'}
                          className={'me-1 mb-1'}
                          size={'sm'}
                          style={{width: "8rem"}}/>
              </div>

              <div className={'d-flex'}>
                <FormText name={'since'}
                          type={'date'}
                          value={condition.since}
                          onChange={handleChange}
                          placeholder={'開始日'}
                          className={'me-1 mb-1'}
                          size={'sm'}
                          style={{width: "8rem"}}/>

                <FormText name={'until'}
                          type={'date'}
                          value={condition.until}
                          onChange={handleChange}
                          placeholder={'終了日'}
                          className={'me-1 mb-1'}
                          size={'sm'}
                          style={{width: "8rem"}}/>
              </div>

              <div className={'d-flex align-items-center me-3 mb-1'}>
                <input id={'deleted'}
                       type={'checkbox'}
                       name={'deleted'}
                       checked={condition.deleted}
                       onChange={handleChange}
                       className='mx-1'/>
                <label htmlFor={'deleted'}
                       className={'me-2'}>
                  削除済み
                </label>
              </div>
            </div>

            <Button onClick={handleSearch}
                    className={'me-1 mb-1'}
                    size={'sm'}
                    style={{width: 80}}>
              検索
            </Button>
          </div>

          <div className={'flex-fill overflow-scroll mx-0 mt-0 mx-md-5 mt-md-3'}>
            <Table hover striped className={'thead-sticky'} style={{fontSize: 12}}>
              <thead>
              <tr>
                <th className={'text-center'} style={{width: '6rem'}}>ID</th>
                <th className={'text-center'} style={{width: '5rem'}}>種別</th>
                <th className={'text-start'} style={{minWidth: '5rem'}}>名称</th>
                <th className={'text-center'} style={{width: '7rem'}}>取引日</th>
                <th className={'text-center'} style={{width: '11rem'}}>最終更新日</th>
                <th className={'text-center'} style={{width: '2rem'}}>&nbsp;</th>
              </tr>
              </thead>

              <tbody style={{borderTop: '1.05px solid gray'}}>
              {total === 0 && (
                  <tr>
                    <td colSpan={6}>---</td>
                  </tr>
              )}
              {list?.map(elem => (
                  <tr key={elem.id}>
                    <td className={'align-middle text-center'}>
                      <Link to={`./${elem.id}`}>{elem.id}</Link>
                    </td>
                    <td className={'align-middle text-center'}>{Type.fval(elem.typeId)}</td>
                    <td className={'align-middle'}>{elem.name}</td>
                    <td className={'align-middle text-center'}>
                      {elem.tradedAt.toYmd()}
                    </td>
                    <td className={'align-middle text-center'}>
                      {elem.updatedAt?.toYmd_hms() ?? elem.createdAt?.toYmd_hms()}
                    </td>
                    <td className={'align-middle'}>
                      <Link to={`./${elem.id}`} className={'btn btn-secondary btn-sm'}>
                        <FontAwesomeIcon icon={faMagnifyingGlass}/>
                      </Link>
                    </td>
                  </tr>
              ))}
              </tbody>
              <tfoot>
              <tr>
                <td colSpan={5} className={'text-center'}>
                  <Button href={'/document/new'} size={'sm'} variant={'secondary'} style={{width: '10rem'}}>
                    新規追加
                  </Button>
                </td>
              </tr>
              </tfoot>
            </Table>

            <div className={'d-flex justify-content-center'}>
              <Pager activePage={Number(query.get('page')) || 1}
                     totalCount={total}
                     pageSize={pageSize}
                     displaySize={3}
                     onChange={({page}) => handlePage(page)}/>
            </div>
          </div>
        </div>
      </>
  );
}


