import {RawCreateParams, z, ZodType} from "zod";

export const z_prestring_ignore_empty = <T extends ZodType>(schema: T, params?: RawCreateParams) => {
  return z.preprocess(v => typeof v === "string" ? v.trim() || undefined : v, schema, params);
}


/**
 * string -> number
 */
export function z_string_number(msg = '数値を入力してください') {
  return z.string()
          .default('')
          .refine(v => v.toNumber() !== null, msg)
          .transform(v => Number(v)).or(z.number());
}

/**
 * string -> number optional
 */
export function z_string_number_optional() {
  return z_prestring_ignore_empty(z_string_number().optional());
}


/**
 * string -> Date
 */
export function z_string_date() {
  return z.string()
          .transform(function (v, ctx) {
            if (v.trim() === '') {
              ctx.addIssue({
                code   : z.ZodIssueCode.custom,
                message: "必須項目です",
              });
            }

            const date = new Date(v);
            if (date.toDateString() === 'Invalid Date') {
              ctx.addIssue({
                code   : z.ZodIssueCode.custom,
                message: "不正な日付です",
              });
              return z.NEVER;
            }
            return date
          })
          .or(z.date());

}


/**
 * string -> Date
 */
export function z_string_date_optional() {
  return z.string()
          .transform(function (v, ctx) {
            if (v.trim() === '') {
              return null;
            }

            const date = new Date(v);
            if (date.toDateString() === 'Invalid Date') {
              ctx.addIssue({
                code   : z.ZodIssueCode.custom,
                message: "不正な日付です",
              });
              return z.NEVER;
            }
            return date
          })
          .default('')
          .or(z.date());
}
