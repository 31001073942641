import {MaintenanceTypeProps} from "../index";

export const VehicleType: MaintenanceTypeProps = {
  id    : 1,
  name  : '車両',
  points: {
    "運転席": [
      "エンジンかかり具合・異音",
      "ブレーキペダル",
      "駐車ブレーキ",
      "キャビン内清掃",
      "ワイパー",
      "燃料",
      "走行距離",
    ],

    "エンジンルーム": [
      "ラジエター冷却水",
      "ファンベルト",
      "エンジオイル",
      "バッテリー液量",
      "ウィンドウウォシャ",
      "ブレーキオイル",
    ],

    "外回り": [
      "ウインカー",
      "前照灯",
      "車幅灯",
      "後退灯",
      "タイヤ空気圧",
      "タイヤ亀裂・損傷",
      "タイヤ溝",
      "損傷（傷・へこみ等）",
    ],

    "備品系": [
      "ロープ",
      "ラッシングベルト",
      "箒",
      "ミ",
      "フレコン",
      "シート",
      "輪止め",
    ],

    "架装": []
  }
}
