import React, {FC} from "react";
import {ExistingCustomer} from "core/RDS";
import {ModelObjectRCSV} from "core/Utils/types";


export const Addresses: FC<{ customer: ModelObjectRCSV<ExistingCustomer> }> = ({customer}) => (
    <ul className={'d-block list-unstyled'}>
      {/* @formatter:off */}
                               <li><Address title={customer.addr_title}  zip={customer.zip}  address={customer.address1} tel={customer.tel}/></li>
      {customer.addr_title2 && <li><Address title={customer.addr_title2} zip={customer.zip2} address={customer.address2} tel={customer.tel2}/></li>}
      {customer.addr_title3 && <li><Address title={customer.addr_title3} zip={customer.zip3} address={customer.address3} tel={customer.tel3}/></li>}
      {customer.addr_title4 && <li><Address title={customer.addr_title4} zip={customer.zip4} address={customer.address4} tel={customer.tel4}/></li>}
      {customer.addr_title5 && <li><Address title={customer.addr_title5} zip={customer.zip5} address={customer.address5} tel={customer.tel5}/></li>}
      {/* @formatter:on */}
    </ul>
)


const Address: FC<{ title?: string, zip: string, address: string, tel?: string }> = ({title, zip, address, tel}) => {
  return (
      <ul className={'d-block list-unstyled border border-1 m-3 p-3'}>
        <li><h5>{title}</h5></li>
        <li>{`${zip} ${address}`.trim()}</li>
        {tel && <li>TEL:<a href={`tel:${tel}`}>{tel}</a></li>}
      </ul>
  )
}
