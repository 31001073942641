import {AbstractCode} from "core/Codes/abstract";

/**
 * コードリスト
 */
const CodeList = [
  {
    id   : 0,
    value: '仕入',
  },
  {
    id   : 1,
    value: '販売',
  },
] as const;


/**
 * タイプ
 */
export type ShType = typeof CodeList[number];

/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キーアクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.value] = elem;
  return result;
}, {} as Record<ShType['value'], ShType>);


/**
 * 公開
 */
export const ShType = Object.assign(CodeClass, NameAccessor);
