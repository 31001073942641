import {AbstractCode} from "./abstract";


/**
 * コードリスト
 */
const CodeList = [
  {
    id     : 'users',
    value  : 'ユーザーリスト',
    actions: [
      'read',
      'create',
      'update',
      'delete',
    ]
  },
  {
    id     : 'user-roles',
    value  : 'ロール管理',
    actions: [
      'read',
      'edit',
    ]
  },
  {
    id     : 'www-data',
    value  : 'サイト管理',
    actions: [
      'article', 'news', 'recruits'
    ]
  },
  {
    id     : 'existing-customer',
    value  : '既存客管理',
    actions: [
      'get', 'set', 'list',
    ]
  },
  {
    id     : 'potential-customer',
    value  : '開拓客管理',
    actions: [
      'get', 'list',
    ]
  },
  {
    id     : 'documents',
    value  : '訂正削除記録システム',
    actions: [
      'list', 'get', 'download', 'set', 'delete'
    ]
  }
] as const;


/**
 * タイプ
 */
export type Functions = typeof CodeList[number];

/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);

/**
 * キーアクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.value] = elem;
  return result;
}, {} as Record<Functions['value'], Functions>);


/**
 * 公開
 */
export const Functions = Object.assign(CodeClass, NameAccessor);
