import React, {FC,} from "react";
import {Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";
import {DailyPage} from "./Daily";
import {UserPage} from "./User";
import {SearchPage} from "./Search";

export const SchedulePage: FC = () => {
  return (
      <Routes>
        <Route index element={<Navigate to={`./${new Date().toYmd()}`} replace/>}/>
        <Route path="search" element={<SearchPage/>}/>
        <Route path="user/:userId/:ymd" element={<UserPage/>}/>
        <Route path=":ymd" element={<DailyPage/>}/>

      </Routes>
  );
}


