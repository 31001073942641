import React, {FC, useEffect, useState} from "react";
import {Accordion, Breadcrumb, Button, Col, Container, FormControl, Row, Table} from "react-bootstrap";
import {useNavigate} from "react-router";
import {useTRPC} from "Backoffice/UI/App";
import {FormSelect, useLoader, useMessage} from "core/UI/Components";
import {useImmer} from "use-immer";
import {useSearchParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {ItemTree} from "core/RDS/Models/Trade/ItemTree";
import {UserType} from "core/RDS";
import {WeighingType} from "core/RDS/Models/Trade/Weighing/Weighing";
import {FormCol} from "core/UI/Components/FormCol";

import "./index.scss";

const defaultCondition = {
  method          : '',
  createdSince    : '',
  createdUntil    : '',
  transactionSince: new Date().toYmd(),
  transactionUntil: new Date().toYmd(),
  charge          : '',
  driver          : '',
  itemMethod      : '0',
  itemGroup       : '',
  item            : '',
} as Record<string, string>


export const WeighingSummaryPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  const [{users, userIndex, itemTree}, setCodes] = useState<{
    users: null | UserType[],
    userIndex: null | Record<number, UserType>,
    itemTree: null | ItemTree
  }>({
    users    : null,
    userIndex: null,
    itemTree : null
  });

  const [query, setQuery]         = useSearchParams(),
        [condition, setCondition] = useImmer(defaultCondition),
        [result, setResult]       = useState([] as WeighingType[]);

  // コード取得
  useEffect(() => {
    Loader.task(async () => {
      const {users, items, groups} = await trpc.trade.weighing.code.query();
      setCodes({users: users, userIndex: users.index('id'), itemTree: new ItemTree(items, groups)})
    }, 300).then();
  }, []);

  // 初期化
  useEffect(() => {

    // 検索条件の初期化
    const cond = Object.entries(defaultCondition).reduce((cond, [k, v]) => {
      cond[k] = query.get(k) || v;
      return cond;
    }, {} as typeof defaultCondition);

    setCondition(cond);

    // データ取得
    Loader.task(async () => {
      try {
        // @ts-ignore
        const res = await trpc.trade.weighing.summary.query(cond);
        setResult(res);

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, [query]);


  // 検索
  const handleSearch = () => {
    for (const [k, v] of Object.entries(condition)) {
      if (v.trim()) {
        query.set(k, v);
      } else {
        query.delete(k);
      }
    }
    query.set('ts', new Date().getTime().toString());
    setQuery(query);
  };

  return (
      <>
        <Helmet>
          <title>計量一覧 | backoffice</title>
        </Helmet>

        <div className={'d-flex flex-column root-100vh pb-1'}>
          <div className={'d-flex flex-wrap small mb-1 mx-1 mx-md-5'}>
            <Breadcrumb>
              <Breadcrumb.Item active>Veronica</Breadcrumb.Item>
              <Breadcrumb.Item active>計量一覧</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className={'small mb-1 mx-1 mx-md-5'}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <small>検索条件</small>
                </Accordion.Header>
                <Accordion.Body>
                  <div className={'container small'}>
                    <Row>
                      <FormCol inputId={'method'}
                               className={'mb-1'}
                               labelColClassname={'text-end'}
                               title={'種別'}
                               size={{xs: [3, 3], lg: [1, 2]}}>

                        <FormSelect value={condition.method}
                                    onChange={({target: {value}}) => setCondition(draft => void (draft.method = value))}
                                    className={'me-1 mb-1'}
                                    size={'sm'}
                                    style={{}}>

                          <option value="">-</option>
                          <option value="0">仕入</option>
                          <option value="1">販売</option>
                          <option value="2">加工</option>
                          <option value="3">調整</option>
                          <option value="2+3">加工+調整</option>
                        </FormSelect>
                      </FormCol>

                      <Col xs={12} lg={5}>
                        <Container className={'p-0'}>
                          <Row className={'p-0'}>
                            <FormCol inputId={'created'}
                                     className={'mb-1'}
                                     labelColClassname={'text-end'}
                                     title={'登録日'}
                                     size={{xs: [3, 9]}}>

                              <div className={'d-flex align-items-center'}>
                                <FormControl type={'date'}
                                             value={condition.createdSince}
                                             onChange={({target: {value}}) => setCondition(draft => void (draft.createdSince = value))}
                                             size={'sm'}
                                             style={{}}/>
                                <div>&nbsp;~&nbsp;</div>
                                <FormControl type={'date'}
                                             value={condition.createdUntil}
                                             onChange={({target: {value}}) => setCondition(draft => void (draft.createdUntil = value))}
                                             size={'sm'}
                                             style={{}}/>
                              </div>
                            </FormCol>
                          </Row>

                          <Row>
                            <FormCol inputId={'transaction'}
                                     className={'mb-1'}
                                     labelColClassname={'text-end'}
                                     title={'取引日'}
                                     size={{xs: [3, 9]}}>

                              <div className={'d-flex align-items-center'}>
                                <FormControl type={'date'}
                                             value={condition.transactionSince}
                                             onChange={({target: {value}}) => setCondition(draft => void (draft.transactionSince = value))}
                                             size={'sm'}
                                             style={{}}/>
                                <div>&nbsp;~&nbsp;</div>
                                <FormControl type={'date'}
                                             value={condition.transactionUntil}
                                             onChange={({target: {value}}) => setCondition(draft => void (draft.transactionUntil = value))}
                                             size={'sm'}
                                             style={{}}/>
                              </div>
                            </FormCol>
                          </Row>
                        </Container>
                      </Col>

                      <Col xs={12} lg={4}>
                        <Container className={'p-0'}>
                          <Row>
                            <FormCol inputId={'charge'}
                                     className={'mb-1'}
                                     labelColClassname={'text-end'}
                                     title={'担当者'}
                                     size={{xs: [3, 7], md: [3, 3], lg: [3, 9]}}>

                              <FormSelect value={condition.charge}
                                          onChange={({target: {value}}) => setCondition(draft => void (draft.charge = value))}
                                          size={'sm'}>

                                <option value={''}>-</option>
                                {Object.values(users || {}).map(el => <option key={el.id} value={el.id}>{el.name}</option>)}
                              </FormSelect>
                            </FormCol>

                            <FormCol inputId={'driver'}
                                     className={'mb-1'}
                                     labelColClassname={'text-end'}
                                     title={'引取担当'}
                                     size={{xs: [3, 7], md: [3, 3], lg: [3, 9]}}>

                              <FormSelect value={condition.driver}
                                          onChange={({target: {value}}) => setCondition(draft => void (draft.driver = value))}
                                          size={'sm'}>

                                <option value={''}>-</option>
                                {Object.values(users || {}).map(el => <option key={el.id} value={el.id}>{el.name}</option>)}
                              </FormSelect>
                            </FormCol>
                          </Row>
                        </Container>
                      </Col>
                    </Row>

                    <Row className={'mt-4'}>
                      <FormCol inputId={'itemMethod'}
                               className={'mb-1'}
                               labelColClassname={'text-end'}
                               title={'品目対象'}
                               size={{xs: [3, 7], lg: [1, 3]}}>

                        <FormSelect value={condition.itemMethod}
                                    onChange={({target: {value}}) => setCondition(draft => void (draft.itemMethod = value))}
                                    size={'sm'}>

                          <option value={'0'}>両方</option>
                          <option value={'1'}>加工元</option>
                          <option value={'2'}>加工先</option>
                        </FormSelect>
                      </FormCol>

                      <FormCol inputId={'itemGroup'}
                               className={'mb-1'}
                               labelColClassname={'text-end'}
                               title={'品目種別'}
                               size={{xs: [3, 7], lg: [1, 3]}}>

                        <FormSelect value={condition.itemGroup}
                                    onChange={({target: {value}}) => setCondition(draft => {
                                      draft.itemGroup = value;
                                      draft.item      = ''
                                    })}
                                    size={'sm'}>

                          <option value="">-</option>
                          {itemTree?.roots.map(el => (
                              <React.Fragment key={el.id}>
                                <option value={el.id}>{el.name}</option>
                                {el.children.map(child => (
                                    <option key={child.id} value={child.id}>{el.name}&nbsp;&gt;&nbsp;{child.name}</option>
                                ))}
                              </React.Fragment>
                          ))}
                        </FormSelect>
                      </FormCol>

                      <FormCol inputId={'item'}
                               className={'mb-1'}
                               labelColClassname={'text-end'}
                               title={'品目'}
                               size={{xs: [3, 7], lg: [1, 3]}}>

                        <FormSelect value={condition.item}
                                    onChange={({target: {value}}) => setCondition(draft => {
                                      draft.item = value;
                                    })}
                                    size={'sm'}>

                          <option value="">-</option>
                          {itemTree?.endsItem(Number(condition.itemGroup)).map(el => (
                              <option key={el.id} value={el.id}>{el.name_intra}</option>
                          ))}
                        </FormSelect>
                      </FormCol>
                    </Row>

                    <Row className={'my-3'}>
                      <Col xs={{offset: 2, span: 8}} md={{offset: 4, span: 4}}>
                        <Button onClick={handleSearch}
                                className={'me-1 mb-1 w-100'}
                                size={'sm'}>
                          検索
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

          <div className={'flex-fill overflow-scroll mx-0 mt-0 mx-md-5 mt-md-3'}>
            <Table hover className={'thead-sticky ellipsis'} style={{fontSize: 12}}>
              <thead>
              <tr>
                <th className={'text-center'} style={{minWidth: '8em'}}>種別</th>
                <th className={'text-start '} style={{minWidth: '8em'}}>顧客名</th>
                <th className={'text-start '} style={{minWidth: '6em'}}>加工元</th>
                <th className={'text-end   '} style={{minWidth: '4em'}}>*成績</th>
                <th className={'text-start '} style={{minWidth: '7em'}}>品名</th>
                <th className={'text-start '} style={{minWidth: '7em'}}>加工内容</th>
                <th className={'text-end   '} style={{minWidth: '6em'}}>重量</th>
                <th className={'text-end   '} style={{minWidth: '4em'}}>成績</th>
                <th className={'text-end   '} style={{minWidth: '6em'}}>成績価格</th>
                <th className={'text-start '} style={{minWidth: '8em'}}>備考</th>
                <th className={'text-start '} style={{minWidth: '8em'}}>備考(内)</th>
                <th className={'text-center'} style={{minWidth: '6em'}}>担当者</th>
                <th className={'text-center'} style={{minWidth: '6em'}}>引取者</th>
                <th className={'text-center'} style={{minWidth: '8em'}}>取引日</th>
                <th className={'text-center'} style={{minWidth: '8em'}}>確定日</th>
              </tr>
              </thead>

              <tbody style={{borderTop: '1.05px solid gray'}}>
              {result === null && (
                  <tr>
                    <td colSpan={15}>---</td>
                  </tr>
              )}
              {result.map(weighing => weighing.details.map(detail => {
                // 種別
                const [method, trClassName] = (() => {
                  if (weighing.method === 0) {
                    return [`仕入:${weighing.trade?.type?.value}`, 'siire'];
                  } else if (weighing.method === 1) {
                    return [`販売:${weighing.trade?.type?.value}`, 'hanbai'];
                  } else if (detail.orig_item_id !== null) {
                    return ['加工', 'kako'];
                  } else {
                    return ['調整', 'chosei'];
                  }
                })();

                // 重量
                const quantity = weighing.method === 1 ? detail.quantity.mul(-1) : detail.quantity;

                // 価格
                const price = (() => {
                  if (detail.basic_price && detail.orig_basic_price) {
                    return quantity.mul(detail.basic_price - detail.orig_basic_price);
                  }
                  return undefined;
                })();

                // 担当者
                const myCharge = (() => {
                  return weighing.trade?.charge_user ?? weighing.process_user ?? weighing.upduser;
                })();

                return (
                    <tr key={`${weighing.id}-${detail.branch}`} className={trClassName}>
                      <td className={'text-center'}>{method}</td>
                      <td className={'text-start '}>{weighing.trade?.customer?.name ?? '-'}</td>
                      <td className={'text-start '}>{detail.origItem?.name_intra}</td>
                      <td className={'text-end   '}>{detail.orig_basic_price?.withComma}</td>
                      <td className={'text-start '}>{detail.item?.name_intra}</td>
                      <td className={'text-start '}>{detail.tare?.value}</td>
                      <td className={'text-end   '}>{quantity.toFixed(2).withComma}</td>
                      <td className={'text-end   '}>{detail.basic_price?.withComma}</td>
                      <td className={'text-end   '}>{price?.toNumber().withComma}</td>
                      <td className={'text-start '}>{detail.memo}</td>
                      <td className={'text-start '}>{detail.outline}</td>
                      <td className={'text-center'}>{userIndex?.[myCharge]?.name}</td>
                      <td className={'text-center'}>{weighing.trade?.driver ? userIndex?.[weighing.trade?.driver]?.name : ''}</td>
                      <td className={'text-center'}>{weighing.trandate.toYmd()}</td>
                      <td className={'text-center'}>{detail.check_date?.toYmd()}</td>
                    </tr>
                );
              }))}
              </tbody>
            </Table>
          </div>
        </div>
      </>
  );
}


