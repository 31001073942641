import React, {FC, useEffect, useState} from "react";
import {getCurrentUser} from "aws-amplify/auth";
import {Button, Container, FormControl, Row} from "react-bootstrap";

export const DebugPage: FC = () => {
  const [userId, setUserId] = useState('');

  useEffect(() => {
    (async () => {
      const cognitoUser = await getCurrentUser(),
            debugUserId = localStorage.getItem('debugUserId');

      setUserId(debugUserId ?? cognitoUser.username);
    })();

  }, []);

  return (
      <Container className={'small my-5'}>
        <Row>
          <div className={'d-flex align-items-center'}>
            <label>
              xDevUserId
            </label>

            <FormControl className={'mx-2'}
                         style={{maxWidth: 500}}
                         value={userId}
                         onChange={event => setUserId(event.currentTarget.value)}
            />

            <Button variant={'primary'}
                    size={'sm'}
                    className={'mx-1'}
                    style={{minWidth: 100}}
                    onClick={() => {
                      localStorage.setItem('debugUserId', userId);
                      location.reload();
                    }}>
              保存
            </Button>

            <Button variant={'outline-danger'}
                    size={'sm'}
                    className={'mx-1'}
                    style={{minWidth: 100}}
                    onClick={() => {
                      localStorage.removeItem('debugUserId');
                      location.reload();
                    }}>
              reset
            </Button>
          </div>
        </Row>

        <Row>
          <div className={'d-flex flex-column my-3'}>
            <div>40 / 山崎 / 50246db9-cdbc-411a-af35-f308fcec9f1f</div>
            <div>95 / 花島 / 4fbe5833-36c0-49ff-84af-25a026828cfe</div>
            <div>109 / パネル / 59bcc3db-119c-4bb4-b15a-3cffe08c5703</div>
          </div>
        </Row>
      </Container>

  )
}
