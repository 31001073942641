import React, {FC, MouseEventHandler, ReactNode, useState} from "react";
import {Button, Modal, Form} from "react-bootstrap";
import {FormText} from "./index";
import {ButtonProps} from "react-bootstrap/Button";

// ボタンタイプ
type ConfirmModalButtonProps = Omit<ButtonProps, 'onClick' | 'disabled' | 'children'>;

// モーダル
export const ConfirmModal: FC<{
  children: ReactNode,
  size?: 'sm' | 'lg' | 'xl',
  show: boolean,
  keyword?: string,
  confirmButtonProps: ConfirmModalButtonProps,
  confirmLabel: string,
  onConfirm: MouseEventHandler<HTMLButtonElement>,
  onCancel: () => void
}> = ({show, size, children, confirmButtonProps, confirmLabel, keyword, onConfirm, onCancel}) => {

  // キーワード入力
  const [inputKeyword, setInputKeyword] = useState<string>('');

  return (
      <Modal show={show}
             backdropClassName={'system-modal'}
             size={size || 'lg'}>

        <Modal.Body className={`m-body`}>
          <div className={`m-2 my-3 my-lg-5 fs-5`}>
            {children}
          </div>

          {/* キーワード */}
          {keyword && (
              <div className={'m-3'}>
                <FormText placeholder={keyword}
                          value={inputKeyword}
                          onChange={(event) => setInputKeyword(event.currentTarget.value)}/>
                <Form.Text className="text-muted">
                  * 実行するには「{keyword}」と入力してください
                </Form.Text>
              </div>
          )}
        </Modal.Body>

        <Modal.Footer className={'justify-content-center'}>
          <Button variant="secondary" style={{minWidth: '10em'}}
                  onClick={onCancel}>
            キャンセル
          </Button>

          <Button {...confirmButtonProps}
                  style={{minWidth: '10em'}}
                  onClick={onConfirm}
                  disabled={!keyword || keyword !== inputKeyword}>
            {confirmLabel}
          </Button>
        </Modal.Footer>
      </Modal>

  )
}
