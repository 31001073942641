import {ValidationError} from "Backoffice/API/ValidationError";
import {useTRPC, useUser} from "Backoffice/UI/App";
import {FormNumber, FormText, FormTextArea, useLoader, useMessage} from "core/UI/Components";
import {FormCol} from "core/UI/Components/FormCol";
import React, {FC, useCallback, useEffect, useState,} from "react";
import {Breadcrumb, Button, Col, Container, Row} from "react-bootstrap";
import {Helmet} from "react-helmet-async";
import {useNavigate} from "react-router";
import {useImmerChangeHandler} from "core/Utils/react/hooks";
import {useSearchParams} from "react-router-dom";
import {useImmer} from "use-immer";
import Big from "big.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquareCaretLeft, faSquareCaretRight} from "@fortawesome/free-solid-svg-icons";
import {HouseworkModal} from "Backoffice/UI/Pages/DailyReport/Form/HouseworkModal";
import {TransportModal} from "Backoffice/UI/Pages/DailyReport/Form/TransportModal";


const defaultModel = {
  jik_nai: '0',
  jik_gai: '0',

  denwa  : '0',
  houmon : '0',
  meishi : '0',
  seiyaku: '0',

  housework: '0',
  transport: '0',

  memo: '',
}

export const DailyReportFormPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        User     = useUser(),
        tRPC     = useTRPC();

  const [query, setQuery] = useSearchParams(),
        [model, setModel] = useImmer(defaultModel),
        [age, setAge]     = useState(1),
        [modal, setModal] = useState('');

  const hideModal = useCallback(() => {
    setModal('');
    setAge(age => age + 1);
  }, []);

  const userId = Number(query.get('userId') ?? User.veronicaId!),
        date   = Date.forge(query.get('date')) ?? new Date();

  // 日移動
  const setDate = useCallback((date: string) => {
    query.set('date', date);
    setQuery(query);
  }, [query]);


  useEffect(() => {
    Loader.task(async () => {
      const response = await tRPC.dailyReport.form.get.query({date: date.toYmd()});

      setModel({
        ...defaultModel,
        ...response
      });

    }, 300).catch(err => {
      Message.error(err, () => Navigate('../'));
      console.error(err);
    });
  }, [date.toYmd(), age]);


  // 編集
  const handleChange = useImmerChangeHandler(setModel);

  // 登録
  const handleSubmit = async () => {
    Loader.task(async () => {

      // データ更新
      const res = await tRPC.dailyReport.form.set.mutate({trandate: date.toYmd(), ...model});
      if (res) {
        setAge(prev => prev + 1);
      }

    }, 300).catch(err => {
      const [converted, error] = ValidationError.convert(err);
      if (converted) {
        Message.error('入力内容を確認してください');
        return;
      }

      Message.error(err);
      console.error(err);
    })
  };


  return (
      <>
        <Helmet>
          <title>日報入力 {date.toYmd()}</title>
        </Helmet>

        <div className={'container small'}>
          <Breadcrumb>
            <Breadcrumb.Item active>日報入力</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className={'container small'}>
          <Row className={'my-5'}>
            <Col xs={{offset: 2}} lg={{offset: 0}}>
              <div className={'d-flex align-items-center me-3 mb-1'}>
                <div onClick={() => setDate(date.addDate(-1).toYmd())} className={'cursor-pointer'}>
                  <FontAwesomeIcon icon={faSquareCaretLeft} className={'text-secondary'}/>
                </div>

                <FormText type={"date"}
                          value={date.toYmd()}
                          onChange={({target: {value}}) => setDate(value)}
                          className={'mx-1'}
                          size={'sm'}
                          style={{width: "8rem"}}/>

                <div onClick={() => setDate(date.addDate(+1).toYmd())} className={'cursor-pointer'}>
                  <FontAwesomeIcon icon={faSquareCaretRight} className={'text-secondary'}/>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={4} className={'mb-3 mb-lg-0'}>
              <Container>
                <Row>
                  <FormCol inputId={'jikan'}
                           className={'mb-1'}
                           labelColClassname={'text-end'}
                           title={'合計時間'}
                           size={{xs: [3, 9]}}>

                    <FormNumber value={Big(model.jik_nai.toNumber(2) || '0').add(model.jik_gai.toNumber(2) || '0').toFixed(2)}
                                precision={2}
                                className={'me-1 mb-1'}
                                size={'sm'}
                                disabled/>
                  </FormCol>
                </Row>

                <Row>
                  <FormCol inputId={'jik_nai'}
                           className={'mb-1'}
                           labelColClassname={'text-end'}
                           title={'時間内'}
                           size={{xs: [3, 9]}}>

                    <FormNumber name={'jik_nai'}
                                value={model.jik_nai}
                                precision={2}
                                onChange={handleChange}
                                className={'me-1 mb-1'}
                                size={'sm'}/>
                  </FormCol>
                </Row>

                <Row>
                  <FormCol inputId={'jik_gai'}
                           className={'mb-1'}
                           labelColClassname={'text-end'}
                           title={'時間外'}
                           size={{xs: [3, 9]}}>

                    <FormNumber name={'jik_gai'}
                                value={model.jik_gai}
                                precision={2}
                                onChange={handleChange}
                                className={'me-1 mb-1'}
                                size={'sm'}/>
                  </FormCol>
                </Row>
              </Container>
            </Col>

            <Col xs={12} lg={4} className={'mb-3 mb-lg-0'}>
              <Container>
                <Row>
                  <FormCol inputId={'denwa'}
                           className={'mb-1'}
                           labelColClassname={'text-end'}
                           title={'架電件数'}
                           size={{xs: [3, 9]}}>

                    <FormNumber value={model.denwa}
                                precision={0}
                                className={'me-1 mb-1'}
                                size={'sm'}
                                disabled/>
                  </FormCol>
                </Row>

                <Row>
                  <FormCol inputId={'houmon'}
                           className={'mb-1'}
                           labelColClassname={'text-end'}
                           title={'訪問件数'}
                           size={{xs: [3, 9]}}>

                    <FormNumber value={model.houmon}
                                precision={0}
                                className={'me-1 mb-1'}
                                size={'sm'}
                                disabled/>
                  </FormCol>
                </Row>

                <Row>
                  <FormCol inputId={'meishi'}
                           className={'mb-1'}
                           labelColClassname={'text-end'}
                           title={'名刺獲得数'}
                           size={{xs: [3, 9]}}>

                    <FormNumber value={model.meishi}
                                precision={0}
                                className={'me-1 mb-1'}
                                size={'sm'}
                                disabled/>
                  </FormCol>
                </Row>

                <Row>
                  <FormCol inputId={'seiyaku'}
                           className={'mb-1'}
                           labelColClassname={'text-end'}
                           title={'成約件数'}
                           size={{xs: [3, 9]}}>

                    <FormNumber name={'close'}
                                value={model.seiyaku}
                                precision={0}
                                className={'me-1 mb-1'}
                                size={'sm'}
                                disabled/>
                  </FormCol>
                </Row>
              </Container>
            </Col>

            <Col xs={12} lg={4} className={'mb-3 mb-lg-0'}>
              <Container>
                <Row className={'position-relative'}>
                  <FormCol inputId={'housework'}
                           className={'mb-1'}
                           labelColClassname={'text-end'}
                           title={'作業量'}
                           size={{xs: [3, 9]}}>

                    <FormText name={'housework'}
                              value={model.housework}
                              className={'me-1 mb-1'}
                              size={'sm'}
                              disabled/>
                  </FormCol>

                  <div className={'position-absolute w-100 h-100 cursor-pointer'}
                       style={{top: 0, left: 0}}
                       onClick={() => setModal('housework')}/>
                </Row>

                <Row className={'position-relative'}>
                  <FormCol inputId={'transport'}
                           className={'mb-1'}
                           labelColClassname={'text-end'}
                           title={'運搬量'}
                           size={{xs: [3, 9]}}>

                    <FormNumber name={'transport'}
                                value={model.transport}
                                precision={2}
                                className={'me-1 mb-1'}
                                size={'sm'}
                                disabled/>
                  </FormCol>

                  <div className={'position-absolute w-100 h-100 cursor-pointer'}
                       style={{top: 0, left: 0}}
                       onClick={() => setModal('transport')}/>
                </Row>
              </Container>
            </Col>
          </Row>

          <Row className={'mt-5'}>
            <div className={'d-flex'}>
              <div className={'me-2'}>
                <label className={'small lh-sm'}>
                  メモ<br/>
                  <small className={'text-muted'}>memo</small>
                </label>
              </div>

              <FormTextArea name={'memo'}
                            value={model.memo}
                            onChange={handleChange}
                            className={'me-1 mb-1'}
                            size={'sm'}/>
            </div>
          </Row>
        </div>

        {/* フッター */}
        <footer>
          <div className={'d-flex flex-row-reverse mb-1 mx-1 mx-md-5'}>
            <Button variant='primary'
                    onClick={handleSubmit}
                    className={'mx-2'}
                    style={{width: "10em"}}
                    form={'role-form'}
                    size={'sm'}>
              保存
            </Button>
          </div>
        </footer>

        <HouseworkModal show={modal === 'housework'}
                        onHide={hideModal}
                        userId={userId}
                        date={date}/>

        <TransportModal show={modal === 'transport'}
                        onHide={hideModal}
                        userId={userId}
                        date={date}/>
      </>
  );
}


