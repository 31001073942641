import {AbstractCode} from "core/Codes/abstract";

/**
 * コードリスト
 */
const CodeList = [
  {
    id   : 101,
    value: '請求書',
  },
  {
    id   : 201,
    value: '領収書',
  },
] as const;

/**
 * ID
 */
export type Type = typeof CodeList[number];

/**
 * コードクラス化
 */
const TypeClass = new AbstractCode(CodeList);


/**
 * キーアクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.value] = elem;
  return result;
}, {} as Record<Type['value'], Type>);


/**
 * 公開
 */
export const Type = Object.assign(TypeClass, NameAccessor);
