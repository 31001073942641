import {faCalculator, faSquareCaretLeft, faSquareCaretRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTRPC} from "Backoffice/UI/App";
import {Trade} from "core/RDS";
import {ItemTypeWithLevel} from "core/RDS/Models/Trade";
import {DailyElementType} from "core/RDS/Models/Trade/Weighing/Utils";
import {FormSelect, FormText, TextDiv, useLoader, useMessage} from "core/UI/Components";
import React, {FC, useEffect, useState} from "react";
import {Breadcrumb, Button, Table} from "react-bootstrap";
import {Helmet} from "react-helmet-async";
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import {useImmer} from "use-immer";


const bgColors = {
  1: '#87CEEB',
  2: '#E9967A',
  3: '#DEB887',
  4: '#E0FFFF',
  5: '#BC8F8F',
  9: '#BDB76B',
}

const before = {
  '15days': new Date().addDate(-15).toYmd(),
  '30days': new Date().addDate(-30).toYmd()
}

const defaultCondition = {
  date : new Date().toYmd(),
  group: '',
  type : '',
}

export const StockPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  const [query, setQuery]         = useSearchParams(),
        [condition, setCondition] = useImmer(defaultCondition),
        [code, setCode]           = useState<{ group: Trade.ItemGroupType[], items: ItemTypeWithLevel[] } | null>(null),
        [result, setResult]       = useState({} as Record<string, DailyElementType>);

  // 検索
  useEffect(() => {
    const cond = {
      date : query.get('date') || defaultCondition.date,
      group: query.get('group') || defaultCondition.group,
      type : query.get('type') || defaultCondition.type,
    };

    setCondition(cond);

    // データ取得
    Loader.task(async () => {
      try {
        // コード取得
        if (code === null) {
          setCode(await trpc.stock.code.query());
        }

        // @ts-ignore チェックはサーバーサイドで実行
        const result = await trpc.stock.data.query(cond);
        setResult(result);

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }).then();
  }, [query]);

  // 検索
  const handleSearch = () => {
    for (const [k, v] of Object.entries(condition)) {
      if (v.trim()) {
        query.set(k, v);
      } else {
        query.delete(k);
      }
    }

    query.set('ts', new Date().getTime().toString());
    handleDate(0);
  };

  // 日付遷移
  const handleDate = (date: number) => {
    query.set('date', new Date(condition.date).addDate(date).toYmd());
    query.set('ts', new Date().getTime().toString());
    setQuery(query);
  }

  const ts = query.get('ts');

  const subtotal = {
          siire     : 0,
          hanbai    : 0,
          chosei    : 0,
          total     : 0,
          subtotal  : 0,
          totalPrice: 0
        },
        total    = {
          siire     : 0,
          hanbai    : 0,
          chosei    : 0,
          total     : 0,
          subtotal  : 0,
          totalPrice: 0
        };

  return (
      <>
        <Helmet>
          <title>在庫確認 | backoffice</title>
        </Helmet>

        <div className={'d-flex flex-column root-100vh pb-1'}>
          <div className={'d-flex small mb-1 mx-1 mx-md-5'}>
            <Breadcrumb>
              <Breadcrumb.Item active>Veronica</Breadcrumb.Item>
              <Breadcrumb.Item active>在庫確認</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className={'d-flex small mb-1 mx-1 mx-md-5'}>
            <div className={'d-flex flex-fill flex-wrap'}>
              <div className={'d-flex align-items-center me-3 mb-1'}>
                <div onClick={() => handleDate(-1)} className={'cursor-pointer'}>
                  <FontAwesomeIcon icon={faSquareCaretLeft} className={'text-secondary'}/>
                </div>

                <FormText type={"date"}
                          value={condition.date}
                          onChange={({target: {value}}) => setCondition(draft => void (draft.date = value))}
                          className={'mx-1'}
                          size={'sm'}
                          style={{width: "8rem"}}/>

                <div onClick={() => handleDate(+1)} className={'cursor-pointer'}>
                  <FontAwesomeIcon icon={faSquareCaretRight} className={'text-secondary'}/>
                </div>
              </div>

              <div className={'d-flex'}>
                <FormSelect value={condition.group}
                            onChange={({target: {value}}) => setCondition(draft => void (draft.group = value))}
                            className={'me-1 mb-1'}
                            size={'sm'}
                            style={{width: "8rem"}}>

                  <option value="">品目</option>
                  {code?.group.map(el => <option key={el.id} value={el.id}>{el.name}</option>)}
                </FormSelect>

                <FormSelect value={condition.type}
                            onChange={({target: {value}}) => setCondition(draft => void (draft.type = value))}
                            className={'me-1 mb-1'}
                            size={'sm'}
                            style={{width: "8rem"}}>

                  <option value="">区分</option>
                  <option value="1">仕入</option>
                  <option value="2">販売</option>
                </FormSelect>
              </div>
            </div>

            <Button onClick={handleSearch}
                    className={'me-3 mb-1'}
                    size={'sm'}
                    style={{width: 80}}>
              検索
            </Button>
          </div>

          <div className={'flex-fill overflow-scroll mx-0 mt-0 mx-md-5 mt-md-3'}>
            <Table hover className={'th-sticky'} style={{fontSize: 12}}>
              <thead>
              <tr>
                <th className={'bg-light text-start'}>品目</th>
                <th className={'bg-light text-end'}>累計</th>
                <th className={'bg-light text-end'}>前日</th>
                <th className={'bg-light text-end'}>仕入</th>
                <th className={'bg-light text-end'}>販売</th>
                <th className={'bg-light text-end'}>調整</th>
                <th className={'bg-light text-center'}>確定日</th>
                <th className={'bg-light'}>&nbsp;</th>
              </tr>
              </thead>

              <tbody style={{borderTop: '1.05px solid gray'}}>
              {code?.items.map(item => {

                // グループフィルタ
                if (condition.group) {
                  if (item.id0 !== Number(condition.group)) {
                    return null;
                  }
                }

                // 区分フィルタ
                if (condition.type) {
                  if (item.type !== Number(condition.type)) {
                    return null;
                  }
                }

                const row = result[item.id];
                if (!row) {
                  return null;
                }

                // 集計
                subtotal.siire += row.siire;
                subtotal.hanbai += row.hanbai;
                subtotal.chosei += row.chosei;

                total.total += row.total;
                total.subtotal += row.subtotal;

                if (row.itemType == 1) {
                  total.siire += row.total;
                } else if (row.itemType == 2) {
                  total.hanbai += row.total;
                }
                total.totalPrice += row.totalPrice;

                // 日付
                let dateClass     = '';
                const latestCheck = row.latestCheck?.toYmd();
                if (latestCheck) {
                  if (latestCheck < before["30days"]) {
                    dateClass = "bg-danger text-light";
                  } else if (latestCheck < before["15days"]) {
                    dateClass = "bg-warning";
                  }
                }

                return (
                    <tr key={`${ts}-${item.id}`}>
                      <th style={{backgroundColor: bgColors[item.id0 as keyof typeof bgColors] ?? ''}}>
                        <div className={'d-block d-xl-none'}>
                          <TextDiv width={"6rem"}>{item.name_intra}</TextDiv>
                        </div>
                        <div className={'d-none d-xl-block'}>
                          {item.name_intra}
                        </div>
                      </th>

                      <td className={`text-end ${row.total < 0 ? 'table-danger' : ''}`}>
                        {row.total.withComma}
                      </td>
                      <td className={'text-end'}>{row.subtotal.withComma}</td>
                      <td className={'text-end'}>{row.siire.withComma}</td>
                      <td className={'text-end'}>{row.hanbai.withComma}</td>
                      <td className={'text-end'}>{row.chosei.withComma}</td>
                      <td className={`text-center ${dateClass}`} style={{minWidth: "4rem"}}>{row.latestCheck?.toMd()}</td>
                      <td className={'text-center align-middle p-1'}>
                        <Button target={'_blank'}
                                variant={'secondary'}
                                href={`/trade/weighing?${
                                    new URLSearchParams({
                                      transactionSince: condition.date,
                                      transactionUntil: condition.date,
                                      itemGroup       : String(item.id1),
                                      item            : String(item.id),
                                    }).toString()}`}
                                size={'sm'}>
                          <FontAwesomeIcon icon={faCalculator} size={'xs'}/>
                        </Button>
                      </td>
                    </tr>
                );
              })}

              <tr>
                <th className={'bg-light'}>当日分</th>
                <td className={`text-end`}>{subtotal.total?.withComma}</td>
                <td className={'text-end'}>{subtotal.subtotal?.withComma}</td>
                <td className={'text-end'}>{subtotal.siire?.withComma}</td>
                <td className={'text-end'}>{subtotal.hanbai?.withComma}</td>
                <td className={'text-end'}>{subtotal.chosei?.withComma}</td>
                <td className={`text-center`}>-</td>
                <td className={`text-center`}>&nbsp;</td>
              </tr>
              <tr>
                <th className={'bg-light'}>合計</th>
                <td className={`text-end`}>{total.total?.withComma}</td>
                <td className={'text-end'}>{total.subtotal?.withComma}</td>
                <td className={'text-end'}>{total.siire?.withComma}</td>
                <td className={'text-end'}>{total.hanbai?.withComma}</td>
                <td className={'text-end'}>{total.chosei?.withComma}</td>
                <td className={`text-center`}>-</td>
                <td className={`text-center`}>&nbsp;</td>
              </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </>
  );
}


