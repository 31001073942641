import React, {FC} from "react";
import {Trade} from "core/RDS";
import {Table} from "react-bootstrap";


export const Expects: FC<{ expects: { item: Trade.ItemType, amount: number }[] }> = ({expects}) => {
  return (
      <Table striped bordered>
        <thead>
        <tr>
          <th>商品名</th>
          <th>発生量</th>
        </tr>
        </thead>
        <tbody>
        {expects.length === 0 && (
            <tr>
              <td colSpan={2}>なし</td>
            </tr>
        )}
        {expects.map((expect, index) => (
            <tr key={`${index}-${expect.item.id}`}>
              <td>{expect.item.name}</td>
              <td>{expect.amount}</td>
            </tr>
        ))}
        </tbody>
      </Table>
  )
}
