import React, {FormEventHandler, useCallback, useEffect} from 'react';
import {useNavigate} from "react-router";
import {Link} from 'react-router-dom';
import {Button, Col, Form, Row} from "react-bootstrap";
import {signIn} from 'aws-amplify/auth'
import {FormPassword, FormText, useLoader, useMessage} from "core/UI/Components";

export const LoginPage = () => {

  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'ログイン｜backoffice';
  }, []);


  const MessageModal = useMessage(),
        Loader       = useLoader();

  // フォームサブミット
  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(async (event) => {

    // デフォルトの挙動を止める
    event.preventDefault();
    event.stopPropagation();

    const form: HTMLFormElement = event.currentTarget;

    // 入力値チェック
    if (!form.checkValidity()) {
      return MessageModal.show('エラー', '入力内容をご確認ください');
    }

    const email    = form.email.value,
          password = form.password.value;


    Loader.task('通信中です', async () => await signIn({username: email, password}), 500)
          .then(({isSignedIn, nextStep}) => {

            //パスワードの変更要求があった場合
            if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
              navigate(`/change-password-required/${email}`);
            }

            // ログイン成功
            else if (isSignedIn) {

              // ログイン成功（リロード）
              const searchParams   = new URLSearchParams(window.location.search)
              window.location.href = searchParams.get('path') || '/';
            }
          })
          .catch(err => MessageModal.show('エラー', err.message));
  }, []);

  return (
      <Form onSubmit={handleSubmit} style={{marginTop: 150}}>
        <Row style={{marginBottom: 50}}>
          <Col className="text-center">
            <big>ログインページ</big>
          </Col>
        </Row>

        <Row>
          <Col sm={{span: 4, offset: 4}} style={{padding: 5}}>
            <Form.Group controlId="email">
              <FormText name="email" type="email" placeholder="メールアドレス" required/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={{span: 4, offset: 4}} style={{padding: 5}}>
            <Form.Group controlId="passwd">
              <FormPassword name="password" placeholder="パスワード" required/>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{marginTop: 20}}>
          <Col sm={{span: 4, offset: 4}} style={{padding: 5}}>
            <Button variant="dark" type="submit" className={'w-100'}>ログイン</Button>
          </Col>
        </Row>
        <Row>
          <Col sm={{span: 4, offset: 4}}>
            <p style={{marginTop: 50}}>
              パスワードをお忘れの場合は<Link to={'/reset-password'}>こちら</Link>からパスワードの再発行を行ってください。
            </p>
          </Col>
        </Row>
      </Form>
  );
};
