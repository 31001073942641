import React, {FC, useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import {Breadcrumb, Table} from "react-bootstrap";
import {useLoader, useMessage} from "core/UI/Components";
import {useTRPC} from "Backoffice/UI/App";
import {UserType} from "Backoffice/API/AppRouter/User";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquare, faSquareCheck} from "@fortawesome/free-regular-svg-icons";
import {Helmet} from "react-helmet-async";


export const UserListPage: FC = () => {

  useEffect(() => {
    doListUsers().then();
  }, []);

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  // ステート
  const [list, setList] = useState<UserType[]>([]);

  // ユーザーリスト読み込み
  const doListUsers: () => Promise<void> = useCallback(async () => {
    Loader.task(async () => {
      try {
        const list = await trpc.user.list.query();
        setList(list.filter(elem => !elem.deletedAt)
                    .sort((a, b) => a.createdAt.localeCompare(b.createdAt)));

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, []);

  return (
      <>
        <Helmet>
          <title>ユーザー管理 | Backoffice</title>
        </Helmet>

        <div className={'d-flex flex-column root-100vh pb-1'}>
          <div className={'d-flex flex-wrap small mb-1 mx-1 mx-md-5'}>
            <Breadcrumb>
              <Breadcrumb.Item active>ユーザー管理</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className={'flex-fill overflow-scroll mx-0 mt-0 mx-md-5 mt-md-3'}>
            <Table striped hover className={'thead-sticky'} style={{fontSize: 14}}>
              <thead>
              <tr>
                <th style={{minWidth: 100}}>メールアドレス</th>
                <th style={{minWidth: 100}}>名称</th>
                <th style={{minWidth: 100}}>メモ</th>
                <th className={'text-center'} style={{minWidth: 50}}>管理者</th>
                <th className={'text-center'} style={{minWidth: 50}}>アクセス</th>
                <th className={'text-center'} style={{minWidth: 180}}>作成日時</th>
              </tr>
              </thead>

              <tbody style={{borderTop: '1.05px solid gray'}}>
              {list.length === 0 && (
                  <tr>
                    <td colSpan={6}>---</td>
                  </tr>
              )}
              {list?.map(user => (
                  <tr key={user.id} className={'cursor-pointer'}
                      onClick={() => Navigate(`./${user.id}/edit`)}>
                    <td className={'align-middle'}>{user.email}</td>
                    <td className={'align-middle'}>{user.name}</td>
                    <td className={'align-middle'}>{user.memo}</td>
                    <td className={'text-center align-middle'}>
                      {user.admin ? <FontAwesomeIcon icon={faSquareCheck}/>
                                  : <FontAwesomeIcon icon={faSquare}/>}
                    </td>
                    <td className={'text-center align-middle'}>
                      {user.disabled ? <FontAwesomeIcon icon={faSquare}/>
                                     : <FontAwesomeIcon icon={faSquareCheck}/>}
                    </td>
                    <td className={'align-middle text-center'}>
                      {new Date(user.createdAt).toYmd_hms()}
                    </td>
                  </tr>
              ))}
              </tbody>
              <tfoot style={{borderTop: '1.05px solid gray'}}>
              <tr>
                <td colSpan={6}>
                  <div className={'d-flex justify-content-center'}>
                    <Link to={'./create'} className={'btn btn-sm btn-outline-primary mx-2'} style={{width: '20em'}}>
                      新規作成
                    </Link>
                  </div>
                </td>
              </tr>
              </tfoot>
            </Table>
          </div>
        </div>
      </>
  );
}


