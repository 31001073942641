import React, {FC,} from "react";
import {Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";
import {ExistingCustomerListPage} from "./List";
import {ExistingCustomerDetailPage} from "./Detail";
import {ExistingCustomerMemoPage} from "./Memo";


export const ExistingCustomerPage: FC = () => {
  return (
      <Routes>
        <Route index element={<ExistingCustomerListPage/>}/>
        <Route path=":id" element={<ExistingCustomerDetailPage/>}/>
        <Route path=":id/memo" element={<ExistingCustomerMemoPage/>}/>
        <Route path="*" element={<Navigate to=".." replace/>}/>
      </Routes>
  );
}

