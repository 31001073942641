import React, {FC} from "react";
import {PotentialCustomerType} from "core/RDS";
import {Table} from "react-bootstrap";


export const Info: FC<{ customer: PotentialCustomerType }> = ({customer}) => {
  return (
      <Table striped bordered>
        <tbody>
        <tr>
          <th>〒</th>
          <td>{customer.zipcode}</td>
        </tr>
        <tr>
          <th>所在地1</th>
          <td>{customer.address}</td>
        </tr>
        <tr>
          <th>所在地2</th>
          <td>{customer.address2}</td>
        </tr>
        <tr>
          <th>TEL</th>
          <td>{customer.tel}</td>
        </tr>
        <tr>
          <th>業種</th>
          <td>{customer.type}</td>
        </tr>
        <tr>
          <th>担当者</th>
          <td>{customer.charge_name}</td>
        </tr>
        <tr>
          <th>携帯等</th>
          <td>{customer.charge_mobile}</td>
        </tr>
        <tr>
          <th>進捗</th>
          <td>{customer.status}</td>
        </tr>
        <tr>
          <th>発生量</th>
          <td>{customer.hassei2}</td>
        </tr>
        <tr>
          <th>ランク</th>
          <td>{customer.seiyaku}</td>
        </tr>
        <tr>
          <th>商品名</th>
          <td>{customer.item}</td>
        </tr>
        <tr>
          <th>発生</th>
          <td>{customer.hassei1}</td>
        </tr>
        <tr>
          <th>判断理由</th>
          <td>{customer.handan}</td>
        </tr>
        <tr>
          <th>訪問</th>
          <td>{customer.houmon}</td>
        </tr>
        <tr>
          <th>単価</th>
          <td>{customer.itanka}</td>
        </tr>
        <tr>
          <th>関係</th>
          <td>{customer.relation}</td>
        </tr>
        <tr>
          <th>区分</th>
          <td>{customer.trans}</td>
        </tr>
        <tr>
          <th>競合</th>
          <td>
            {customer.kyogo || ''}
            {customer.kyogo1 || ''}
            {customer.kyogo2 || ''}
            {customer.kyogo3 || ''}
            {customer.kyogo4 || ''}
            {customer.kyogo5 || ''}
            {customer.kyogo6 || ''}
            {customer.kyogo7 || ''}
            {customer.kyogo8 || ''}
            {customer.kyogo9 || ''}
          </td>
        </tr>
        <tr>
          <th>メモ1</th>
          <td>{customer.memo1}</td>
        </tr>
        <tr>
          <th>メモ2</th>
          <td>{customer.memo2}</td>
        </tr>
        <tr>
          <th>メモ3</th>
          <td>{customer.memo3}</td>
        </tr>
        </tbody>
      </Table>
  )
}
