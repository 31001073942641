import {useTRPC} from "Backoffice/UI/App";
import {FormNumber, FormText, useLoader, useMessage} from "core/UI/Components";
import React, {ChangeEventHandler, FC, useCallback, useEffect,} from "react";
import {Button, Form, Modal, Table} from "react-bootstrap";
import {useNavigate} from "react-router";
import {useImmer} from "use-immer";
import {v4 as uuid} from "uuid";
import {Overwrite} from "core/Utils/types";
import {NewTransportRecord, TransportRecordUpdate} from "core/Kysely/types/eigyo/t_transport";

interface PropsType {
  show: boolean
  onHide: () => void,
  userId: number
  date: Date
}

// 編集用タイプ
type EditType = Overwrite<
    (NewTransportRecord | TransportRecordUpdate),
    {
      quantity1: string,
      quantity2: string
    }
> & { uuid?: string, delete?: boolean }


export const TransportModal: FC<PropsType> = ({show, onHide, userId, date}) => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        tRPC     = useTRPC();

  const [list, setList] = useImmer<EditType[]>([]);

  const newElem = useCallback(() => ({
    uuid         : uuid(),
    trandate     : date.toYmd(),
    user_id      : userId,
    starttime    : '',
    endtime      : '',
    customer_name: '',
    item1        : '',
    quantity1    : '',
    item2        : '',
    quantity2    : '',
    memo         : '',
    delete       : false,
    checked      : false,
  }), []);

  // 読み込み
  useEffect(() => {
    if (show) {
      Loader.task(() => tRPC.dailyReport.form.transport.get.query({userId, date: date.toYmd()}), 100)
            .then(response => setList([...response, newElem()]))
            .catch(err => {
              console.error(err);
              Message.error(err, () => Navigate('../'));
            });
    }
  }, [show, userId, date.toYmd()]);


  // 編集
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(({currentTarget}) => {
    const {type, name, value, dataset: {index}, checked} = currentTarget;

    setList(draft => {
      const i = Number(index);

      // @ts-ignore
      draft[i][name] = type === 'checkbox' ? checked : value;
      if (draft.length === i + 1) {
        draft.push(newElem())
      }
    });
  }, [setList]);


  // 保存処理
  const handleSubmit = () => {
    (async () => {
      try {
        await tRPC.dailyReport.form.transport.set.mutate({
          userId,
          date: date.toYmd(),

          // @ts-ignore
          list: list.slice(0, -1)
        });
        onHide();

      } catch (err) {
        Message.error(err, onHide);
      }
    })();
  };

  return (
      <Modal show={show} size={'xl'} className={'small'}>
        <Modal.Body>
          <Table responsive>
            <thead>
            <tr>
              <th className={'text-center'}>開始</th>
              <th className={'text-center'}>終了</th>
              <th className={'text-center'}>顧客名</th>
              <th className={'text-center'}>品目１</th>
              <th className={'text-center'}>重量１</th>
              <th className={'text-center'}>品目２</th>
              <th className={'text-center'}>重量２</th>
              <th className={'text-center'}>メモ</th>
              <th className={'text-center'}>削除</th>
            </tr>
            </thead>
            <tbody>
            {list.map((el, index) => (
                <tr key={el.uuid ?? el.id}>
                  <td className={'p-1'}>
                    <FormText type={'time'}
                              data-index={index}
                              name={'starttime'}
                              value={el.starttime}
                              onChange={handleChange}
                              size={'sm'}
                              style={{minWidth: "6em"}}
                              disabled={el.checked}/>
                  </td>
                  <td className={'p-1'}>
                    <FormText type={'time'}
                              data-index={index}
                              name={'endtime'}
                              value={el.endtime}
                              onChange={handleChange}
                              size={'sm'}
                              style={{minWidth: "6em"}}
                              disabled={el.checked}/>
                  </td>

                  <td className={'p-1'}>
                    <FormText data-index={index}
                              name={'customer_name'}
                              value={el.customer_name}
                              onChange={handleChange}
                              size={'sm'}
                              style={{minWidth: "20em"}}
                              disabled={el.checked}/>
                  </td>
                  <td className={'p-1'}>
                    <FormText data-index={index}
                              name={'item1'}
                              value={el.item1}
                              onChange={handleChange}
                              size={'sm'}
                              style={{minWidth: "6em"}}
                              disabled={el.checked}/>
                  </td>
                  <td className={'p-1'}>
                    <FormNumber data-index={index}
                                name={'quantity1'}
                                precision={0}
                                value={el.quantity1}
                                onChange={handleChange}
                                size={'sm'}
                                wrapperStyle={{minWidth: "6em"}}
                                disabled={el.checked}/>
                  </td>
                  <td className={'p-1'}>
                    <FormText data-index={index}
                              name={'item2'}
                              value={el.item2}
                              onChange={handleChange}
                              size={'sm'}
                              style={{minWidth: "6em"}}
                              disabled={el.checked}/>
                  </td>
                  <td className={'p-1'}>
                    <FormNumber data-index={index}
                                name={'quantity2'}
                                precision={0}
                                value={el.quantity2}
                                onChange={handleChange}
                                size={'sm'}
                                wrapperStyle={{minWidth: "6em"}}
                                disabled={el.checked}/>
                  </td>
                  <td className={'p-1'}>
                    <FormText data-index={index}
                              name={'memo'}
                              value={el.memo}
                              onChange={handleChange}
                              size={'sm'}
                              style={{minWidth: "20em"}}
                              disabled={el.checked}/>
                  </td>
                  <td className={'p-1 text-center align-middle'} style={{minWidth: "5em"}}>
                    <Form.Check type={'checkbox'}
                                data-index={index}
                                name={"delete"}
                                checked={el.delete ?? false}
                                onChange={handleChange}
                                disabled={el.checked}/>
                  </td>
                </tr>
            ))}
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-danger"
                  onClick={onHide}
                  style={{minWidth: '10em'}}>
            キャンセル
          </Button>

          <Button variant="primary"
                  onClick={handleSubmit}
                  style={{minWidth: '10em'}}>
            保存
          </Button>
        </Modal.Footer>
      </Modal>
  );
}


