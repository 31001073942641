import React, {FC, useCallback, useEffect, useState,} from "react";
import {FormText, FormTextArea, useLoader, useMessage} from "core/UI/Components";
import {useNavigate, useOutletContext} from "react-router";
import {useSearchParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {Breadcrumb, Button, Col, Container, Modal, Row} from "react-bootstrap";
import {useTRPC} from "Backoffice/UI/App";
import {AppRouterInput} from "Backoffice/API/AppRouter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVideo} from "@fortawesome/free-solid-svg-icons";
import {MaintenanceItemOutlet} from "Backoffice/UI/Pages/Maintenance/item";


export const MaintenanceFormPage: FC = () => {
  const {type, target} = useOutletContext<MaintenanceItemOutlet>();

  const Loader  = useLoader(),
        Message = useMessage(),
        Navi    = useNavigate(),
        tRPC    = useTRPC();

  const [query, setQuery]       = useSearchParams(),
        [category, setCategory] = useState(''),
        [modal, setModal]       = useState(''),
        [result, setResult]     = useState(''),
        [state, setState]       = useState<Record<string, { id: number, result: string, timestamp: string }>>({})


  const date = query.get('date') ?? (new Date().toYmd());

  useEffect(() => {
    Loader.task(() => tRPC.maintenance.read.query({target_id: target.id, date}), 300)
          .then(response => setState(response))
          .catch(err => {
            console.error(err);
            Message.error(err);
          });
  }, [target.id, date]);


  const handleRegister = useCallback((point: string, result: string) => {
    const request: AppRouterInput['maintenance']['write'] = {
      target_id: target.id, date, point, result
    };

    if (state[point]) {
      request['id']        = state[point].id;
      request['timestamp'] = state[point].timestamp;
    }

    tRPC.maintenance.write.mutate(request)
        .then(({result, data}) => {

          setState({...state, [point]: data});
          if (result === 'ok') {
            setModal('');
            return;
          }
          Message.show(result);
        })
        .catch(err => {
          console.error(err);
          Message.error(err, () => location.reload());
        });

  }, [target.id, date, state]);

  const points: Record<string, string[]> = {...type.points, ...target.data};

  return (
      <>
        <Helmet>
          <title>{type.name} {String(target.name)} | backoffice</title>
        </Helmet>

        <div className={'container small'}>
          <Breadcrumb>
            <Breadcrumb.Item href={'/'}>Veronica</Breadcrumb.Item>
            <Breadcrumb.Item active>メンテナンス入力</Breadcrumb.Item>
            <Breadcrumb.Item href={'#'} onClick={() => Navi('../')}>{type.name}選択</Breadcrumb.Item>
            <Breadcrumb.Item href={'#'} onClick={() => setCategory('')}>{target.name}</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className={'container'}>
          <Row>
            <Col xs={4}>
              <FormText type={'date'}
                        value={date}
                        onChange={event => setQuery(prev => {
                          prev.set('date', event.currentTarget.value);
                          return prev;
                        })}
                        className={'m-1'}
                        size={'sm'}/>
            </Col>
          </Row>
        </div>

        {category === '' && (
            <div className={'container'}>
              <Row className={'mb-2'}>
                <Col xs={4}>
                  <Button onClick={() => Navi('../')}
                          className={'w-100 m-1'}
                          variant={'outline-primary'}
                          size={'sm'}>
                    ← 戻る
                  </Button>
                </Col>
              </Row>
              <Row>
                {Object.keys(points).map(category => (
                    <Col xs={6} key={category}>
                      <Button onClick={() => setCategory(category)}
                              className={'w-100 m-1'}
                              variant={'secondary'}>
                        {category}
                      </Button>
                    </Col>
                ))}

                <Col xs={6}>
                  <Button onClick={() => Navi('./video')}
                          className={'w-100 m-1'}
                          variant={'secondary'}>
                    動画 <FontAwesomeIcon icon={faVideo}/>
                  </Button>
                </Col>
              </Row>
            </div>
        )}

        {category !== '' && (
            <div className={'container'}>
              <Row className={'mb-2'}>
                <Col xs={4}>
                  <Button onClick={() => setCategory('')}
                          className={'w-100 m-1'}
                          variant={'outline-primary'}
                          size={'sm'}>
                    ← 戻る
                  </Button>
                </Col>
              </Row>
              <Row>
                {(points[category]).map(item => {
                  const result = state[item]?.result,
                        ok     = result === 'ok',
                        ng     = ok ? false : !!result;

                  return (
                      <div className={'d-flex align-items-center border-1 border-bottom'} key={item}>
                        <span className={'flex-grow-1'}>{item}</span>

                        <Button className={'m-1'}
                                variant={ok ? 'secondary' : 'outline-secondary'}
                                size={'sm'}
                                onClick={() => handleRegister(item, 'ok')}>
                          OK
                        </Button>

                        <Button className={'m-1'}
                                variant={ng ? 'secondary' : 'outline-secondary'}
                                size={'sm'}
                                onClick={() => {
                                  setResult(result ?? '')
                                  setModal(item);
                                }}>
                          NG
                        </Button>
                      </div>
                  )
                })}
              </Row>
            </div>
        )}

        <Modal show={modal !== ''}
               backdropClassName={'system-modal'}>

          <Modal.Body className={`m-body`}>
            <Container>
              <Row>
                <span>{modal}</span>
              </Row>
              <Row>
                <FormTextArea style={{height: '17.5em'}}
                              value={result}
                              onChange={event => setResult(event.currentTarget.value)}/>
              </Row>
            </Container>
          </Modal.Body>

          <Modal.Footer className={'justify-content-center'}>
            <Button variant="secondary" style={{minWidth: '10em'}}
                    onClick={() => setModal('')}>
              キャンセル
            </Button>

            <Button variant="danger" style={{minWidth: '10em'}}
                    onClick={() => handleRegister(modal, result)}
                    disabled={result.trim().length === 0}>
              NG登録
            </Button>
          </Modal.Footer>
        </Modal>
      </>
  );
}
