import {useTRPC} from "Backoffice/UI/App";
import {Member} from "Backoffice/UI/Pages/Schedule/Member";
import {FormText, Pager, useLoader, useMessage} from "core/UI/Components";
import React, {FC, useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Container, Row} from "react-bootstrap";
import {Helmet} from "react-helmet-async";
import {useNavigate} from "react-router";
import {Link, useSearchParams} from "react-router-dom";
import {DailyMember} from "core/RDS/Models/Schedule";
import {ModelObjectRCSV} from "core/Utils/types";
import {PageSize} from "Backoffice/API/AppRouter/Schedule/config";


const defaultCondition = {
  keyword: '',
}


export const SearchPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        tRPC     = useTRPC();

  const [query, setQuery]         = useSearchParams(),
        [condition, setCondition] = useState(defaultCondition),
        [result, setResult]       = useState({total: 0, list: [] as ModelObjectRCSV<DailyMember>[]});


  // 初期化
  useEffect(() => {
    const cond = Object.entries(defaultCondition).reduce((cond, [k, v]) => {
      cond[k as keyof typeof defaultCondition] = query.get(k) || v;
      return cond;
    }, {} as typeof defaultCondition);

    setCondition(cond);

    if (cond.keyword) {

      // データ取得
      Loader.task(async () => {
        try {
          const {total, list} = await tRPC.schedule.search.query({...cond, page: Number(query.get('page')) || 1});
          setResult({total: total ?? 0, list});

        } catch (err) {
          console.error(err);
          Message.error(err);
        }
      }, 300).then();
    }

  }, [query])


  // 検索
  const handleSearch = () => {
    for (const [k, v] of Object.entries(condition)) {
      if (v.trim()) {
        query.set(k, v);
      } else {
        query.delete(k);
      }
    }
    handlePage(1);
  };

  // ページ遷移
  const handlePage = (page: number) => {
    query.set('page', String(page));
    query.set('ts', new Date().getTime().toString());
    setQuery(query);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
      <>
        <Helmet>
          <title>スケジュール検索 | backoffice</title>
        </Helmet>

        <div className={'container small'}>
          <Breadcrumb>
            <Breadcrumb.Item active>Veronica</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{to:'/schedule'}}>スケジュール</Breadcrumb.Item>
            <Breadcrumb.Item active>検索</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Container className={'my-3 small'}>
          <Row style={{marginTop: 20}}>
            <Col xs={{span: 9}}>
              <FormText value={condition.keyword}
                        placeholder={'行き先、前方一致、複数入力はスペース区切り（最大３箇所）'}
                        onChange={({currentTarget: {value}}) => setCondition(prev => ({...prev, keyword: value}))}
                        className={'mx-1'}
                        size={'sm'}/>
            </Col>

            <Col xs={{span: 3}}>
              <Button variant="primary"
                      onClick={handleSearch}
                      size={'sm'}>
                検索する
              </Button>
            </Col>
          </Row>
        </Container>

        <Container className={'d-flex flex-wrap mt-2 px-0'}>
          {result.list.length === 0 && (
              <div>対象はありません</div>
          )}

          {result.list.map(daily => {
            const tmp = String(daily.ymd),
                  ymd = `${tmp.substring(0, 4)}-${tmp.substring(4, 6)}-${tmp.substring(6)}`;


            return (
                <Member key={`${daily.ymd}-${daily.uid}`}
                        daily={daily}
                        header={
                          <span className={'me-2'}>
                          <a href={`./${ymd}`}>{ymd}</a> / {daily.user.name}</span>
                        }/>
            );
          })}
        </Container>

        <div className={'d-flex justify-content-center'}>
          <Pager activePage={Number(query.get('page')) || 1}
                 totalCount={result.total}
                 pageSize={PageSize}
                 displaySize={3}
                 onChange={({page}) => handlePage(page)}/>
        </div>
      </>
  );
}
