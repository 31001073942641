import React, {FC} from "react";
import {ExistingCustomer} from "core/RDS";
import {Table} from "react-bootstrap";
import {ModelObjectRCSV} from "core/Utils/types";

export const ApproxPrices: FC<{ customer: ModelObjectRCSV<ExistingCustomer> }> = ({customer}) => {
  return (
      <Table striped bordered>
        <tbody>
        <tr>
          <th>アルミ</th>
          <td>{customer.price_alminium}</td>
        </tr>
        <tr>
          <th>銅</th>
          <td>{customer.price_copper}</td>
        </tr>
        <tr>
          <th>ステンレス</th>
          <td>{customer.price_stainless}</td>
        </tr>
        <tr>
          <th>雑品</th>
          <td>{customer.price_etc}</td>
        </tr>
        <tr>
          <th>鉄くず</th>
          <td>{customer.price_kuzu}</td>
        </tr>
        </tbody>
      </Table>
  )
}
