import React, {FC, useEffect, useRef, useState} from 'react';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {Link, useLocation} from "react-router-dom";
import {useUser} from "Backoffice/UI/App";
import {DropDirection} from 'react-bootstrap/esm/DropdownContext';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";

type MenuItem = MenuGroup | MenuLink | MenuDivider

interface MenuGroup {
  title: string,
  submenu: MenuItem[]
}

interface MenuLink {
  title: string,
  href: string,
}

interface MenuDivider {
  divider: true
}

const Divider: MenuDivider = {divider: true};

const Menu: MenuGroup[] = [
  {
    title  : 'サイト管理',
    submenu: [
      {title: 'ニュース管理', href: '/website/news'},
      {title: '求人管理', href: '/website/recruits'},
      {title: '取り扱い品目', href: '/website/article'},
    ]
  },
  {
    title  : 'Veronica',
    submenu: [
      {title: '既存客', href: '/customer/existing'},
      {title: '開拓客', href: '/customer/potential'},
      Divider,
      {title: '取引一覧', href: '/trade'},
      {title: '計量一覧', href: '/trade/weighing'},
      {title: '価格表', href: '/price-list'},
      {title: '在庫確認', href: '/stock'},
      {title: 'スケジュール', href: '/schedule'},
      {title: 'スケジュール検索', href: '/schedule/search'},
      {title: 'NSP', href: '/nsp'},
      Divider,
      {title: '日報入力', href: `/daily-report/form?date=${new Date().toYmd()}`}
    ]
  },
  {
    title  : '補助機能',
    submenu: [
      {title: '訂正削除記録システム', href: '/document'},
    ]
  },
  {
    title  : '設定⛭',
    submenu: [
      {title: 'ユーザー管理', href: '/users'},
      {title: 'ロール管理', href: '/users/roles'},
    ]
  }
];

// Component
export const Header: FC = () => {

  const user                    = useUser(),
        location                = useLocation(),
        [expanded, setExpanded] = useState(false);


  // メニュー外クリックでメニューを閉じる
  const headerRef = useRef<HTMLElement>(null);
  useEffect(() => {
    window.addEventListener('click', event => {
      if (headerRef.current && event.target) {
        if (headerRef.current.contains(event.target as Node)) {
          return;
        }
        setExpanded(false);
      }
    })
  }, [setExpanded]);


  // スケジュール検索表示
  const [showScheduleSearch, setShowScheduleSearch] = useState(false);
  useEffect(() => {
    setShowScheduleSearch(!!location.pathname.match(/^\/schedule(\/(?!search).*)?$/));
  }, [location.pathname])

  return (
      <header className={'fixed-top'} ref={headerRef}>
        <Navbar bg="light" className={'small px-3'} expand="sm" expanded={expanded}
                onToggle={() => setExpanded(!expanded)}>
          <Navbar.Brand as={Link} to='/'>
            <small className={'ps-1'}>
              Backoffice
            </small>
          </Navbar.Brand>

          {/* スケジュール検索 */}
          <Nav.Item className={showScheduleSearch ? `btn btn-outline-primary d-block d-sm-none ms-auto me-2` : `d-none`}
                    as={Link} to="/schedule/search">
            <FontAwesomeIcon icon={faMagnifyingGlass}/>
          </Nav.Item>


          <Navbar.Toggle aria-controls='basic-navbar-nav'/>
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className={'me-auto'} as={'ul'}>
              {Menu.map((item, index) => {
                const key = `menu-${index}`;
                return (<MenuItem item={item} key={key} subKey={key}
                                  onClick={() => setExpanded(false)}/>)
              })}
            </Nav>

            {/* スケジュール検索 */}
            <Nav.Item className={showScheduleSearch ? `d-none d-sm-block` : `d-none`}
                      as={Link} to="/schedule/search">
              <FontAwesomeIcon icon={faMagnifyingGlass}/>
            </Nav.Item>

            <Nav>
              <NavDropdown title={user.name ?? user.email ?? 'unknown'} align={'end'}>
                <NavDropdown.Item href='/logout'>ログアウト</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
  );
}

const MenuItem: FC<{ item: MenuItem, subKey: string, drop?: DropDirection, onClick: () => void }> = ({item, subKey, drop = 'down', onClick}) => {
  if ('submenu' in item) {
    return (
        <NavDropdown drop={drop} title={item.title} className={'px-2'}>
          {item.submenu.map((subItem, subIndex) => {
            const key = `${subKey}-${subIndex}`;
            return <MenuItem item={subItem} key={key} subKey={key} drop={'end'} onClick={onClick}/>
          })}
        </NavDropdown>
    );
  } else if ('title' in item) {
    return (
        <NavDropdown.Item as={Link} to={item.href}
                          onClick={onClick}>
          {item.title}
        </NavDropdown.Item>
    )
  } else {
    return <NavDropdown.Divider/>
  }
}
