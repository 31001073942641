import React, {FC, useEffect, useState,} from "react";
import {Outlet, useNavigate, useParams} from "react-router";
import {useTRPC} from "Backoffice/UI/App";
import {AppRouterOutput} from "Backoffice/API/AppRouter";
import {useLoader, useMessage} from "core/UI/Components";
import {MaintenanceTypeProps} from "Backoffice/UI/Pages/Maintenance/index";


export interface MaintenanceItemOutlet {
  type: MaintenanceTypeProps
  target: AppRouterOutput['maintenance']['target']
}

export const MaintenanceItemRoot: FC<{ type: MaintenanceTypeProps }> = ({type}) => {

  const target_id = Number(useParams().target_id);

  const Loader  = useLoader(),
        Navi    = useNavigate(),
        tRPC    = useTRPC(),
        Message = useMessage();

  const [target, setTarget] = useState<AppRouterOutput['maintenance']['target'] | null>(null);


  useEffect(() => {
    Loader.task(() => tRPC.maintenance.target.query({type_id: type.id, target_id}), 300)
          .then(target => setTarget(target))
          .catch(err => {
            console.error(err);
            Message.error(err, () => Navi('../'));
          });
  }, [type.id, target_id])


  if (target === null) {
    return null;
  }

  return <Outlet context={{type, target} as MaintenanceItemOutlet}/>
}
