import React, {ChangeEventHandler, FC, FormEventHandler, useCallback, useEffect, useState} from "react";
import {Breadcrumb, Button, Form, Row, Col} from "react-bootstrap";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import {v4 as uuid} from 'uuid';
import {RecruitContents} from "../Contents";
import {CreateWebDataType} from "Backoffice/API/AppRouter/WebData";
import {RecruitType} from "Backoffice/API/AppRouter/WebData/Recruits";
import {useImmer} from "use-immer";
import {useLoader, useMessage, FormCheck, FormTextArea} from "core/UI/Components";
import {useTRPC} from "Backoffice/UI/App";
import {ValidationError} from "Backoffice/API/ValidationError";
import {BoolPropsOf, StringPropsOf} from "core/Utils/types";


export interface UIRecruitType extends RecruitType {
  __uiKey: string,
}

export const RecruitCreatePage: FC = () => {

  useEffect(() => {
    document.title = '新規作成';
  }, []);

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  // ステート
  const [model, setModel]  = useImmer<CreateWebDataType<UIRecruitType[]>>({
          content: [{__uiKey: uuid(), key: '', value: ''}],
          memo   : '',
          visible: false
        }),
        setContent         = useCallback((callback: (content: UIRecruitType[]) => UIRecruitType[]) => {
          setModel(draft => void (draft.content = callback(draft.content)))
        }, [setModel]),
        [_error, setError] = useState<ValidationError | null>(null);


  // 変更
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(async ({currentTarget: {name, value}}) => {
    setModel(draft => void (draft[name as StringPropsOf<CreateWebDataType<UIRecruitType[]>>] = value))
  }, []);

  // 変更
  const handleSwitch: ChangeEventHandler<HTMLInputElement> = useCallback(async ({currentTarget: {name, checked}}) => {
    setModel(draft => void (draft && (draft[name as BoolPropsOf<CreateWebDataType<UIRecruitType[]>>] = checked)));
  }, []);


  // 新規作成
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {

    // デフォルトの挙動を止める
    event.preventDefault();
    event.stopPropagation();

    const form: HTMLFormElement = event.currentTarget;

    // 入力値チェック
    if (!form.checkValidity()) {
      return Message.show('エラー', '入力内容をご確認ください');
    }

    Loader.task(async () => {
      setError(null);

      try {
        const response = await trpc.web.recruits.create.mutate(model);
        console.log(response);

        Message.show('登録が完了しました', () => {
          Navigate('..');
        });

      } catch (err) {
        const [converted, error] = ValidationError.convert(err);
        if (converted) {
          Message.error('入力内容を確認してください');
          setError(error)
          return;
        }

        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  };

  return (
      <div className={'container'}>
        <Breadcrumb>
          <Breadcrumb.Item active>サイト管理</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: ".."}}>求人管理</Breadcrumb.Item>
          <Breadcrumb.Item active>新規作成</Breadcrumb.Item>
        </Breadcrumb>

        <Form onSubmit={handleSubmit} style={{marginTop: 50}} className={'container'}>
          <input type='submit' className={'d-none'} disabled/>

          <Row>
            <Col style={{padding: 5}}>
              <Form.Label>募集要項</Form.Label>
              <RecruitContents content={model.content} setContent={setContent}/>
            </Col>
          </Row>

          <Row>
            <Col style={{padding: 5}}>
              <Form.Group controlId="memo">
                <Form.Label>メモ</Form.Label>
                <FormTextArea name="memo" placeholder="メモ"
                              style={{height: '7.5em'}}
                              value={model?.memo ?? ''}
                              onChange={handleChange}/>
                <Form.Text className="text-muted">
                  <small></small>
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={{span: 8, offset: 2}} className={'text-center'} style={{marginTop: '2em', padding: 5}}>
              <FormCheck id={'visible'}
                         checked={model?.visible ?? false}
                         onChange={handleSwitch}
                         disabled={false}>
                <span>公開する</span>
              </FormCheck>
            </Col>
          </Row>

          <Row style={{marginTop: 50}}>
            <Col md={{span: 8, offset: 2}} style={{padding: 10}}>
              <Button variant="primary" type={'submit'} className={'w-100'}>
                作成する
              </Button>
            </Col>
          </Row>

          <Row style={{marginTop: 100}}>
            <Col md={{span: 4, offset: 4}} style={{padding: 10}}>
              <Link to={'..'} className={'btn btn-secondary w-100'}>
                もどる
              </Link>
            </Col>
          </Row>
        </Form>
      </div>
  );
}

