import {AbstractCode} from "core/Codes/abstract";

/**
 * コードリスト
 */
const CodeList = [
  {
    id   : 0,
    value: '架電(開拓)'
  },
  {
    id   : 1,
    value: '架電(見込)'
  },
  {
    id   : 9,
    value: '架電(既存)'
  },
  {
    id   : 10,
    value: '訪問(開拓)'
  },
  {
    id   : 11,
    value: '訪問(見込)'
  },
  {
    id   : 19,
    value: '訪問(既存)'
  },
] as const;


/**
 * タイプ
 */
export type SalesType = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キーアクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.value] = elem;
  return result;
}, {} as Record<SalesType['value'], SalesType>);


/**
 * 公開
 */
export const SalesType = Object.assign(CodeClass, NameAccessor);
