import {FC, useEffect} from "react";
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router";

export const HomePage: FC = () => {
  const Navigate = useNavigate();

  // パスチェック
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('path')) {
      window.location.href = searchParams.get('path')!;
    }
  }, ['path-check']);

  return (
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-6'}>
            <Button onClick={() => Navigate('/customer/existing')}
                    className={'w-100 m-1'}
                    variant={'secondary'}>
              既存客検索
            </Button>
          </div>

          <div className={'col-6'}>
            <Button onClick={() => Navigate('/customer/potential')}
                    className={'w-100 m-1'}
                    variant={'secondary'}>
              新規開拓客検索

            </Button>
          </div>
        </div>

        <div className={'row'}>
          <div className={'col-6'}>
            <Button onClick={() => Navigate('/trade')}
                    className={'w-100 m-1'}
                    variant={'secondary'}>
              取引一覧
            </Button>
          </div>

          <div className={'col-6'}>
            <Button onClick={() => Navigate('/price-list')}
                    className={'w-100 m-1'}
                    variant={'secondary'}>
              価格表
            </Button>
          </div>
        </div>

        <div className={'row'}>
          <div className={'col-6'}>
            <Button onClick={() => Navigate('/stock')}
                    className={'w-100 m-1'}
                    variant={'secondary'}>
              在庫確認
            </Button>
          </div>

          <div className={'col-6'}>
            <Button onClick={() => Navigate('/schedule')}
                    className={'w-100 m-1'}
                    variant={'secondary'}>
              スケジュール
            </Button>
          </div>
        </div>

        <div className={'row'}>
          <div className={'col-6'}>
            <Button onClick={() => Navigate('/nsp')}
                    className={'w-100 m-1'}
                    variant={'secondary'}>
              NSP
            </Button>
          </div>

          <div className={'col-6'}>
            <Button onClick={() => Navigate('/trade/weighing')}
                    className={'w-100 m-1'}
                    variant={'secondary'}>
              計量一覧
            </Button>
          </div>
        </div>

        <div className={'row'}>
          <div className={'col-6'}>
            <Button onClick={() => Navigate(`/daily-report/form?date=${new Date().toYmd()}`)}
                    className={'w-100 m-1'}
                    variant={'secondary'}>
              日報入力
            </Button>
          </div>

          <div className={'col-6'}>
            <Button onClick={() => Navigate(`/maintenance/vehicle`)}
                    className={'w-100 m-1'}
                    variant={'secondary'}>
              車両メンテナンス
            </Button>
          </div>

          <div className={'col-6'}>
            <Button onClick={() => Navigate(`/maintenance/check/vehicle`)}
                    className={'w-100 m-1'}
                    variant={'secondary'}>
              車両チェック
            </Button>
          </div>
        </div>
      </div>
  )
}
