import React, {FC, useEffect, useState} from "react";
import {Breadcrumb, Button, Container, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router";
import {useTRPC} from "Backoffice/UI/App";
import {FormTextArea, useLoader, useMessage} from "core/UI/Components";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {ModelObjectSlim} from "core/Utils/types";
import {ExistingCustomer} from "core/RDS";

export const ExistingCustomerMemoPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  const {id} = useParams();

  const [model, setModel] = useState<null | ModelObjectSlim<ExistingCustomer>>(null);


  useEffect(() => {
    Loader.task(async () => {
      try {
        const model = await trpc.customer.existing.get.query(Number(id));
        setModel(model);

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, []);


  const handleSave = async () => {
    if (model === null) {
      return;
    }

    try {
      await Loader.task(async () => {
        await trpc.customer.existing.set.mutate(model);
      }, 500);

      Message.show('登録が完了しました');

    } catch (err) {
      Message.error(err);
    }
  }

  return (
      <>
        <Helmet>
          <title>ID: {id}|既存客</title>
        </Helmet>

        <div className={'container small'}>
          <Breadcrumb>
            <Breadcrumb.Item active>Veronica</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{to: "/customer/existing/"}}>既存客リスト</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{to: `/customer/existing/${model?.id}`}}>{model?.name ?? '読込中...'}</Breadcrumb.Item>
            <Breadcrumb.Item active>メモ編集</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Container className={'my-3 pb-5 small'}>
          {model === null ? <span>読込中...</span> :
           <>
             <Row>
               <ul className={'d-block list-unstyled  py-3 '}>
                 <li className={'small'}>ID:{model.id}</li>
                 <li className={'my-3'}><h5>{model.name}</h5></li>
                 <li>
                   {model.zip}
                   {model.address1}
                 </li>
                 <li>担当:{model.charge_dept} {model.charge_name}</li>
                 <li>携帯:<a href={`tel:${model.mobile}`}>{model.mobile}</a>
                 </li>
               </ul>
             </Row>

             <Row>
               {[...Array(10)].map((_, i) => {
                 const prop = `memo${i + 1}` as 'memo1';

                 return (
                     <ul key={`${id}-${i}`}
                         className={'d-block list-unstyled'}>
                       <li><h6>メモ{i + 1}</h6></li>
                       <li>
                         <FormTextArea onChange={({currentTarget: {value}}) => setModel(prev => prev === null ? null
                                                                                                              : ({...prev, [prop]: value ?? ''}))}>
                           {model[prop]?.trim()}
                         </FormTextArea>
                       </li>
                     </ul>
                 )
               })}
             </Row>
           </>
          }
        </Container>

        {/* フッター */}
        <footer>
          <div className={'d-flex flex-row-reverse mb-1 mx-1 mx-md-5'}>
            <Button variant='primary'
                    onClick={handleSave}
                    className={'mx-2'}
                    style={{width: "10em"}}
                    form={'role-form'}
                    size={'sm'}>
              保存
            </Button>
          </div>
        </footer>
      </>
  );
}


