import {Schedule} from "core/RDS";
import {TextDiv} from "core/UI/Components";
import React, {FC, ReactComponentElement} from "react";
import {Table} from "react-bootstrap";

export const Member: FC<{
  header: ReactComponentElement<any>,
  daily: Schedule.DailyMemberType | null, title?: string
}> = ({header, daily}) => {
  return (
      <div className={'pe-1 small'} style={{marginLeft: 1, fontSize: 12}}>
        <div className={'d-flex flex-row'}>
          {header}
        </div>
        <div className={'d-flex justify-content-start'}>
          <div className={'pe-2'} style={{width: "6rem"}}><label>出発: {daily?.dep?.toLowerCase() || '--:--'}</label></div>
          {/*<div className={'pe-2'} style={{width: "6rem"}}><label>帰着: {daily?.ret?.toLowerCase() || '--:--'}</label></div>*/}
          {/*<div className={'pe-2'} style={{width: "6rem"}}><label>車両: {daily?.vehicleModel?.name || '----'}</label></div>*/}
        </div>
        <Table bordered>
          <tbody>
          {[0, 1, 2, 3, 4].map(i => {
            const detail = daily?.details[i],
                  color  = detail?.finished ? '#e9967a' : detail?.arrived ? '#98fb98' : '';

            return (
                <tr key={i}>
                  <td className={'p-1'}
                      style={{background: color}}>
                    <TextDiv width={"6rem"}>
                      {detail?.name || '-'}
                    </TextDiv>
                  </td>
                  <td className={'p-1'}>
                    <TextDiv width={"6rem"}>
                      {detail?.memo || '-'}
                    </TextDiv>
                  </td>
                  <td className={'p-1 text-center'} style={{width: "3rem"}}>{detail?.p_arrive?.toLowerCase() || '--:--'}</td>
                  <td className={'p-1 text-center'} style={{width: "3rem"}}>{detail?.p_finish?.toLowerCase() || '--:--'}</td>
                  <td className={'p-1 text-center'} style={{width: "3rem"}}>{detail?.vehicleModel?.name || '----'}</td>
                  <td className={'p-1 text-center'} style={{width: "3rem", background: '#e9967a'}}>{detail?.r_finish || '--:--'}</td>
                </tr>
            );
          })}
          <tr>
            <td colSpan={6}>{daily?.status || (<>&nbsp;</>)}</td>
          </tr>
          </tbody>
        </Table>
      </div>
  );
}


