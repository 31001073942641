import React, {FC} from "react";
import {Navigate, useParams} from "react-router";
import {HanbaiFormPage} from "./Hanbai";
import {ShiireFormPage} from "./Shiire";


export const FormPage: FC = () => {
  const {type, slipId} = useParams(),
        [officeId, id] = slipId!.split('-').map(Number);

  if (type === 'shiire') {
    return <ShiireFormPage officeId={officeId} id={id}/>
  }
  if (type === 'hanbai') {
    return <HanbaiFormPage officeId={officeId} id={id}/>
  }
  return <Navigate to=".." replace/>
}
