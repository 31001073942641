import {AbstractCode} from "core/Codes/abstract";

/**
 * コードリスト
 */
const CodeList = [
  {
    id   : 0,
    value: '本社',
  },
  {
    id   : 1,
    value: '野田',
  },
  {
    id   : 2,
    value: '柏',
  }
] as const;

/**
 * ID
 */
export type Office = typeof CodeList[number];

/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キーアクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.value] = elem;
  return result;
}, {} as Record<Office['value'], Office>);


/**
 * 公開
 */
export const Office = Object.assign(CodeClass, NameAccessor);
