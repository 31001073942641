import React, {FC, useEffect, useState} from "react";
import {Breadcrumb, Button, Table} from "react-bootstrap";
import {useNavigate} from "react-router";
import {useTRPC} from "Backoffice/UI/App";
import {FormSelect, FormText, Pager, useLoader, useMessage} from "core/UI/Components";
import {ExistingCustomer, Vehicle} from "core/RDS";
import {useImmer} from "use-immer";
import {Link, useSearchParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {ModelObjectRCSV} from "core/Utils/types";

const pageSize = 50;

const defaultCondition = {
  name   : '',
  address: '',
  vehicle: '',
  tel    : '',
  order  : 'id',
}

type CustomerType = ModelObjectRCSV<ExistingCustomer> & Partial<ModelObjectRCSV<Vehicle>>

export const ExistingCustomerListPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  const [query, setQuery]          = useSearchParams(),
        [condition, setCondition]  = useImmer(defaultCondition),
        [{total, list}, setResult] = useState({total: 0, list: [] as CustomerType[]});


  // 初期化
  useEffect(() => {
    const cond = {
            name   : query.get('name') || defaultCondition.name,
            address: query.get('address') || defaultCondition.address,
            vehicle: query.get('vehicle') || defaultCondition.vehicle,
            tel    : query.get('tel') || defaultCondition.tel,
            order  : query.get('order') || defaultCondition.order,
          },
          page = Number(query.get('page')) || 1;

    setCondition(cond);

    // データ取得
    if (query.get('ts')) {
      Loader.task(async () => {
        try {
          const req = {...cond, page, pageSize};

          // @ts-ignore チェックはサーバーサイドで実行
          const res = await trpc.customer.existing.list.query(req);
          setResult(res);

        } catch (err) {
          console.error(err);
          Message.error(err);
        }
      }, 300).then();
    }
  }, [query]);


  // 検索
  const handleSearch = () => {
    for (const [k, v] of Object.entries(condition)) {
      if (v.trim()) {
        query.set(k, v);
      } else {
        query.delete(k);
      }
    }
    handlePage(1);
  };

  // ページ遷移
  const handlePage = (page: number) => {
    query.set('page', String(page));
    query.set('ts', new Date().getTime().toString());
    setQuery(query);
  }

  return (
      <>
        <Helmet>
          <title>既存客リスト | backoffice</title>
        </Helmet>

        <div className={'container small'}>
          <Breadcrumb>
            <Breadcrumb.Item active>Veronica</Breadcrumb.Item>
            <Breadcrumb.Item active>既存客リスト</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className={'container d-flex small mb-1'}>
          <div className={'d-flex flex-fill flex-wrap'}>
            <FormText value={condition.name}
                      onChange={({target: {value}}) => setCondition(draft => void (draft.name = value))}
                      placeholder={'名称'}
                      className={'me-1 mb-1'}
                      size={'sm'}
                      style={{width: "8rem"}}/>

            <FormText value={condition.address}
                      onChange={({target: {value}}) => setCondition(draft => void (draft.address = value))}
                      placeholder={'住所'}
                      className={'me-1 mb-1'}
                      size={'sm'}
                      style={{width: "8rem"}}/>

            <FormText value={condition.vehicle}
                      onChange={({target: {value}}) => setCondition(draft => void (draft.vehicle = value))}
                      placeholder={'車番号'}
                      className={'me-1 mb-1'}
                      size={'sm'}
                      style={{width: "5rem"}}/>

            <FormText value={condition.tel}
                      onChange={({target: {value}}) => setCondition(draft => void (draft.tel = value))}
                      placeholder={'電話番号'}
                      className={'me-1 mb-1'}
                      size={'sm'}
                      style={{width: "8rem"}}/>

            <FormSelect name={'order'}
                        value={condition.order}
                        onChange={({target: {value}}) => setCondition(draft => void (draft.order = value))}
                        className={'me-1 mb-1'}
                        size={'sm'}
                        style={{width: "6rem"}}>
              <option value={'id'}>ID順</option>
              <option value={'trade'}>取引順</option>
              <option value={'contact'}>交渉順</option>
            </FormSelect>
          </div>

          <Button onClick={handleSearch}
                  className={'me-1 mb-1'}
                  size={'sm'}
                  style={{width: 80}}>
            検索
          </Button>
        </div>


        <div className={'container small'}>
          <Table striped hover>
            <thead>

            {/* md以上でヘッダー表示 */}
            <tr className='d-none d-md-table-row'>
              <th>ID</th>
              <th>名称</th>
              <th>〒</th>
              <th>住所</th>
              <th>TEL</th>
              <th>担当者</th>
              <th>&nbsp;</th>
            </tr>
            </thead>

            <tbody style={{borderTop: '1.05px solid gray'}}>
            {total === 0 && (
                <tr>
                  <td colSpan={7}>---</td>
                </tr>
            )}
            {list?.map(elem => (
                <tr key={elem.id}>
                  {/* 詳細 */}
                  <td className={'d-table-cell d-md-none position-relative'}>
                    <ul className={'list-unstyled'}>
                      <li>
                        <Link to={`./${elem.id}`}>ID:{elem.id}</Link>
                        <small>
                        <span className={'ms-3'}>
                          {elem.v_area!}
                          {elem.v_weight!}
                        </span>
                          <span className={'ms-1'}>
                          {elem.v_sign!}
                        </span>
                          <span className={'ms-1'}>
                          {elem.v_no!}
                        </span>
                        </small>
                      </li>
                      <li><h6>{elem.name}</h6></li>
                      <li>
                        {elem.zip}
                        {elem.address1}
                      </li>
                      <li>TEL:<a href={`tel:${elem.tel}`}>{elem.tel}</a></li>
                      <li>担当:{elem.charge_dept} {elem.charge_name}</li>
                      <li>携帯:<a href={`tel:${elem.mobile}`}>{elem.mobile}</a></li>
                      <li>自社担当:{elem.chargeUser?.name}</li>
                    </ul>

                    <Link to={`./${elem.id}`}
                          className={'btn btn-secondary btn-sm position-absolute'}
                          style={{right: 5, bottom: 5, width: 40, height: 40}}>
                      <div className={'d-flex justify-content-center align-items-center w-100 h-100'}>
                        <FontAwesomeIcon icon={faMagnifyingGlass}/>
                      </div>
                    </Link>
                  </td>

                  {/* リスト(md以上) */}
                  <td className={'d-none d-md-table-cell align-middle text-center'}>
                    <Link to={`./${elem.id}`}>{elem.id}</Link>
                  </td>
                  <td className={'d-none d-md-table-cell align-middle'}>{elem.name}</td>
                  <td className={'d-none d-md-table-cell align-middle text-center'}>{elem.zip}</td>
                  <td className={'d-none d-md-table-cell align-middle'}>{elem.address1}</td>
                  <td className={'d-none d-md-table-cell align-middle text-center'}>{elem.tel}</td>
                  <td className={'d-none d-md-table-cell align-middle'}>
                    {`${elem.charge_dept ?? ''} ${elem.charge_name ?? ''}`.trim()}
                  </td>
                  <td className={'d-none d-md-table-cell align-middle'}>
                    <Link to={`./${elem.id}`} className={'btn btn-secondary btn-sm'}>
                      <FontAwesomeIcon icon={faMagnifyingGlass}/>
                    </Link>
                  </td>
                </tr>
            ))}
            </tbody>
          </Table>

          <div className={'d-flex justify-content-center'}>
            <Pager activePage={Number(query.get('page')) || 1}
                   totalCount={total}
                   pageSize={pageSize}
                   displaySize={3}
                   onChange={({page}) => handlePage(page)}/>
          </div>
        </div>
      </>
  );
}


