import React, {FC,} from "react";
import {Navigate, Routes} from "react-router";
import {Route} from "react-router-dom";
import {DocumentDetailPage} from "./Detail";
import {DocumentListPage} from "./List";


export const DocumentPage: FC = () => {
  return (
      <Routes>
        <Route index element={<DocumentListPage/>}/>
        <Route path=":id" element={<DocumentDetailPage/>}/>
        <Route path="*" element={<Navigate to=".." replace/>}/>
      </Routes>
  );
}
