import {ItemType} from "core/RDS/Models/Trade/Item";
import {ItemGroupType} from "core/RDS/Models/Trade/ItemGroup";
import deepFreeze, {DeepReadonly} from "deep-freeze";

interface TreeElement extends ItemGroupType {
  children: TreeElement[]
  items: ItemType[]
}

export class ItemTree {

  private itemIndex: DeepReadonly<Record<number, ItemType>>;
  private groupIndex: DeepReadonly<Record<number, TreeElement>>;

  roots: DeepReadonly<TreeElement[]>;

  constructor(items: ItemType[], groups: ItemGroupType[],) {
    this.itemIndex = deepFreeze(items.index('id'));

    // ソート
    groups.sort((a, b) => {
      if (a.parent === b.parent) {
        return a.id - b.id;
      }
      return a.parent - b.parent;
    });


    let _roots      = [],
        _groupIndex = {} as Record<number, TreeElement>;

    for (const group of groups) {
      const element = {
        ...group,
        children: [],
        items   : []
      };

      _groupIndex[element.id] = element;

      if (element.parent in _groupIndex) {
        _groupIndex[element.parent].children.push(element);
      } else {
        _roots.push(element);
      }
    }

    for (const item of items) {
      _groupIndex[item.group]?.items.push(item);
    }

    this.groupIndex = deepFreeze(_groupIndex);
    this.roots      = deepFreeze(_roots);
  }

  /**
   * グループ要素を取得
   *
   * @param groupId
   */
  group(groupId: number) {
    return this.groupIndex[groupId] ?? null;
  }

  /**
   * 末端要素を取得
   *
   * @param groupId
   */
  ends(groupId: number) {
    const result = [] as DeepReadonly<TreeElement>[];

    const func = (group: DeepReadonly<TreeElement>) => {
      if (group === null) {
        return result;
      }

      if (group.children.length !== 0) {
        for (const child of group.children) {
          func(child);
        }
        return result;
      }

      result.push(group);
      return result;
    }
    return func(this.group(groupId));
  }

  /**
   * グループ配下の商品を取得
   * @param groupId
   */
  endsItem(groupId: number) {
    const result = [] as DeepReadonly<ItemType>[];

    const func = (group: DeepReadonly<TreeElement>) => {
      if (group === null) {
        return result;
      }

      if (group.items.length !== 0) {
        result.push(...group.items);
      }

      if (group.children.length !== 0) {
        for (const child of group.children) {
          func(child);
        }
      }
      return result;
    }
    return func(this.group(groupId));
  }
}
