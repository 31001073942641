import React, {FC,} from "react";
import {Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";
import {TradeListPage} from "./List";
import {WeighingPage} from "./Weighing";
import {FormPage} from "./Form";


export const TradePage: FC = () => {
  return (
      <Routes>
        <Route index element={<TradeListPage/>}/>
        <Route path=":type/:slipId" element={<FormPage />}/>
        <Route path="weighing" element={<WeighingPage/>}/>
        <Route path="*" element={<Navigate to=".." replace/>}/>
      </Routes>
  );
}

