import React, {ChangeEventHandler, FC, FormEventHandler, useCallback, useEffect, useState} from "react";
import {Breadcrumb, Button, Form, Row, Col} from "react-bootstrap";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import {useLoader, useMessage, FormText, Nl2Br} from "core/UI/Components";
import {useTRPC} from "Backoffice/UI/App";
import {useImmer} from "use-immer";
import {CreateUserType} from "Backoffice/API/AppRouter/User";
import {ValidationError} from "Backoffice/API/ValidationError";
import {StringPropsOf} from "core/Utils/types";


export const UserCreatePage: FC = () => {

  useEffect(() => {
    document.title = '新規作成';
  }, []);

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  // ステート
  const [model, setModel] = useImmer<CreateUserType>({email: '', name: ''}),
        [error, setError] = useState<ValidationError | null>(null);


  // 変更
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(async ({currentTarget: {name, value}}) => {
    setModel(draft => void (draft[name as StringPropsOf<CreateUserType>] = value))
  }, []);

  // submit
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {

    // デフォルトの挙動を止める
    event.preventDefault();
    event.stopPropagation();

    Loader.task(async () => {
      setError(null);

      try {
        const response = await trpc.user.create.mutate(model);
        console.log(response);

        Message.show('登録が完了しました', () => {
          Navigate('..');
        });

      } catch (err) {
        const [converted, error] = ValidationError.convert(err);
        if (converted) {
          Message.error('入力内容を確認してください');
          setError(error)
          return;
        }

        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  };

  return (
      <div className={'container'}>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: ".."}}>ユーザー管理</Breadcrumb.Item>
          <Breadcrumb.Item active>新規作成</Breadcrumb.Item>
        </Breadcrumb>

        <Form onSubmit={handleSubmit} style={{marginTop: 50}}>
          <input type='submit' className={'d-none'} disabled/>

          <Row>
            <Col md={{span: 8, offset: 2}} style={{padding: 5}}>
              <Form.Group controlId="email">
                <Form.Label>メールアドレス</Form.Label>
                <FormText name="email" type="email"
                          placeholder="xxxx@izumikinzoku.com"
                          onChange={handleChange}
                          required/>
                <Form.Text className="text-muted">
                  <small>
                    このアドレスにログインパスワードが送信されます。
                  </small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('email')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={{span: 8, offset: 2}} style={{padding: 5}}>
              <Form.Group controlId="username">
                <Form.Label>お名前</Form.Label>
                <FormText name="name"
                          placeholder="泉金属 太郎"
                          onChange={handleChange}
                          required/>
                <Form.Text className="text-muted">
                  <small></small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('name')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row style={{marginTop: 50}}>
            <Col md={{span: 8, offset: 2}} style={{padding: 10}}>
              <Button variant="outline-primary" type={'submit'} className={'w-100'}>
                作成する
              </Button>
            </Col>
          </Row>

          <Row style={{marginTop: 100}}>
            <Col md={{span: 4, offset: 4}} style={{padding: 10}}>
              <Link to={'..'} className={'btn btn-secondary w-100'}>
                もどる
              </Link>
            </Col>
          </Row>
        </Form>
      </div>
  );
}
