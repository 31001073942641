// 環境
import {envType} from "core/config";
import {merge} from "lodash";
import {AwsExports} from './AwsExports';

const env: envType = (() => {
  try {
    // @ts-ignore
    return import.meta.env.MODE;

  } catch (err) {
    return process.env.NODE_ENV || 'development';
  }
})();

// タイプ
export type ConfigType = typeof development;

// 開発環境
const development = {
  env : 'development',
  Api : {
    url : (() => {
      if (typeof location !== 'undefined') {
        const {protocol, hostname} = location;
        return `${protocol}//${hostname}`;
      }
      return 'http://localhost';
    })(),
    port: 3001,
  },
  Auth: AwsExports.Auth
}


// 本番環境
const production = merge({}, development, {
  env: 'production',
  Api: {
    // API Gateway作成後にURLを設定する必要がある
    url : 'https://f4jjzo59h5.execute-api.ap-northeast-1.amazonaws.com',
    port: ''
  }
})


// 動的拡張タイプ
interface proxyType {
  apiUrl: string,
}

// 動的拡張
export const config = new Proxy(env === 'production' ? production : development, {
  get(config, prop, receiver) {
    if (prop in config) {
      return Reflect.get(config, prop, receiver);
    }

    switch (prop) {
      case 'apiUrl': {
        if (config.Api.port) {
          return `${config.Api.url}:${config.Api.port}`;
        }
        return config.Api.url;
      }
    }
    throw new Error(`${String(prop)} is not defined.`);
  },

  set(_target: ConfigType, _p: string | symbol, _value: any, _receiver: any): boolean {
    return false;
  }
}) as ConfigType & proxyType;




