import React, {FC} from "react";
import {BrowserRouter, Route} from "react-router-dom";
import {RemoveTrailingSlash, ScrollManager} from "core/UI/Components";
import {Layout} from "Backoffice/UI/Layouts";
import {Navigate, Routes} from "react-router";
import {HomePage} from "Backoffice/UI/Pages/Home";
import {LogoutPage} from "Backoffice/UI/Pages/Logout";
import {UsersPage} from "Backoffice/UI/Pages/Users";
import {WebsitePage} from "Backoffice/UI/Pages/Website";
import {CustomerPage} from "Backoffice/UI/Pages/Customer";
import {SchedulePage} from "Backoffice/UI/Pages/Schedule";
import {TradePage} from "Backoffice/UI/Pages/Trade";
import {PriceListPage} from "Backoffice/UI/Pages/PriceList";
import {StockPage} from "./Stock";
import {NSPPage} from "Backoffice/UI/Pages/NSP";
import {DocumentPage} from "Backoffice/UI/Pages/Document";
import {DailyReportPage} from "Backoffice/UI/Pages/DailyReport";
import {DebugPage} from "Backoffice/UI/Pages/Debug";
import {MaintenanceRoot} from "Backoffice/UI/Pages/Maintenance";

export const Backoffice: FC = () => {
  return (
      <BrowserRouter>
        <RemoveTrailingSlash/>
        <ScrollManager/>

        <Routes>
          <Route path="/logout" element={<LogoutPage/>}/>

          {/* デフォルトレイアウト */}
          <Route element={<Layout/>}>
            <Route index element={<HomePage/>}/>
            <Route path="/users/*" element={<UsersPage/>}/>
            <Route path="/website/*" element={<WebsitePage/>}/>
            <Route path="/customer/*" element={<CustomerPage/>}/>
            <Route path="/nsp/*" element={<NSPPage/>}/>
            <Route path="/price-list/*" element={<PriceListPage/>}/>
            <Route path="/schedule/*" element={<SchedulePage/>}/>
            <Route path="/stock/*" element={<StockPage/>}/>
            <Route path="/trade/*" element={<TradePage/>}/>
            <Route path="/daily-report/*" element={<DailyReportPage/>}/>
            <Route path="/maintenance/*" element={<MaintenanceRoot/>}/>
            <Route path="/document/*" element={<DocumentPage/>}/>
            <Route path="/debug" element={<DebugPage/>}/>
            <Route path="*" element={<Navigate to="/" replace/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
  );
}
